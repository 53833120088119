// modules
import React, { Component } from 'react';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { bold, header } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Container, Input, Message } from 'semantic-ui-react';
// components
// redux

const redirect = (context, path) => {
  context.props.history.push(path);
};

class NewTeacher extends Component {
  state = {
    teacher: {
      email: '',
      password: '',
      lastName: '',
      firstName: ''
    },
    error: {
      status: false,
      msg: ''
    }
  };

  handleChange = (e, data) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      teacher: {
        ...prevState.teacher,
        [name]: value
      }
    }));
  };

  handleAddTeacher = () => {
    // show alert if fields are empty
    if (
      !this.state.teacher.email ||
      !this.state.teacher.password ||
      !this.state.teacher.lastName ||
      !this.state.teacher.firstName
    ) {
      alert('Не все поля заполнены');
      return;
    }

    // ask confirmation
    const r = window.confirm('Создать нового учителя?');
    if (r) {
      // add teacher
      axios
        .post(adminRoutes.teachers.createTeacher(), { teacher: { ...this.state.teacher } })
        .then(() => {
          redirect(this, '/admin/teachers');
        })
        .catch(() => {
          this.setState({
            error: {
              status: true,
              msg: 'Данный логин уже занят'
            }
          });
        });
    }
  };

  render() {
    const { email, password, firstName, lastName } = this.state.teacher;

    return (
      <DocumentTitle title="Добавить нового учителя">
        <Container>
          {header('Добавить нового учителя', '24px', 600)}
          {bold('Логин')}
          <Input
            fluid
            required
            name="email"
            placeholder="Логин"
            value={email}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Пароль')}
          <Input
            fluid
            required
            name="password"
            placeholder="Пароль"
            value={password}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Фамилия')}
          <Input
            fluid
            required
            name="lastName"
            placeholder="Фамилия"
            value={lastName}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Имя')}
          <Input
            fluid
            required
            name="firstName"
            placeholder="Имя"
            value={firstName}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {this.state.error.status && (
            <Message negative>
              <Message.Header>{this.state.error.msg}</Message.Header>
            </Message>
          )}

          <Button
            type="button"
            onClick={this.handleAddTeacher}
            style={{
              backgroundColor: '#0e79b2',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            Добавить учителя
          </Button>
        </Container>
      </DocumentTitle>
    );
  }
}

export default NewTeacher;
