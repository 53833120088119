// modules
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
// assets
import { redirect } from 'utils';
// styles
import { Modal, Search } from 'semantic-ui-react';

const StyledSearchContainer = styled.div`
  display: flex;
  justify-content: center;

  .search,
  .input {
    width: 100%;
  }
`;

class SearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      results: [],
      value: ''
    };

    this.searchTimeout = null;
  }

  handleResultSelect = (e, data) => {
    if (data.result.id > this.props.lastLesson.id) {
      alert(this.props.t('lessonNotReached'));
      return;
    }

    const { location } = this.props;
    const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');

    // redirect
    redirect(this, `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${this.props.courseId}/lesson/${data.result._id}`);
    // close modal
    this.props.onClose();
  };

  handleSearchChange = (e, data) => {
    this.setState({ loading: true, value: data.value });

    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
      const isMatch = result => re.test(JSON.stringify(result.title) + JSON.stringify(result.keywords));

      this.setState({
        loading: false,
        results: _.filter(this.props.lessonKeywords, isMatch)
      });
    }, 500);
  };

  render() {
    const { loading, results, value } = this.state;
    const { onClose, open } = this.props;

    return (
      <Modal open={open} onClose={onClose}>
        <Modal.Content>
          <StyledSearchContainer>
            <Search
              fluid
              loading={loading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={this.handleSearchChange}
              results={results}
              value={value}
            />
          </StyledSearchContainer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(SearchModal);
