const TYPE = type => 'ekitap/UI/' + type;

// action types
const SET_CURRENT_COURSE = TYPE('SET_CURRENT_COURSE');
const SET_CURRENT_LESSON_PROGRESS = TYPE('SET_CURRENT_LESSON_PROGRESS');
const TOGGLE_FORMULAS_MODAL = TYPE('TOGGLE_FORMULAS_MODAL');
const TOGGLE_EXTRAMATERIAL_MODAL = TYPE('TOGGLE_EXTRAMATERIAL_MODAL');
const TOGGLE_QUESTION_TIMER = TYPE('TOGGLE_QUESTION_TIMER');

// initial state
const initialState = {
  currentCourse: null,
  currentLessonProgress: 0,
  isFormulasModalShown: false,
  isExtraMaterialModalShown: false,
  isQuestionTimerShown: false,
};

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_COURSE:
      return { ...state, currentCourse: action.payload };
    case SET_CURRENT_LESSON_PROGRESS:
      return { ...state, currentLessonProgress: action.payload };
    case TOGGLE_FORMULAS_MODAL:
      return { ...state, isFormulasModalShown: action.payload };
    case TOGGLE_EXTRAMATERIAL_MODAL:
      return { ...state, isExtraMaterialModalShown: action.payload };
    case TOGGLE_QUESTION_TIMER:
      return { ...state, isQuestionTimerShown: action.payload };
    default:
      return state;
  }
}

// action creators
export const setCurrentCourse = course => dispatch => {
  dispatch({
    type: SET_CURRENT_COURSE,
    payload: course
  });
};

export const setCurrentLessonProgress = progress => dispatch => {
  dispatch({
    type: SET_CURRENT_LESSON_PROGRESS,
    payload: progress
  });
};

export const toggleFormulasModal = value => dispatch => {
  dispatch({
    type: TOGGLE_FORMULAS_MODAL,
    payload: value
  });
};

export const toggleExtraMaterialModal = value => dispatch => {
  dispatch({
    type: TOGGLE_EXTRAMATERIAL_MODAL,
    payload: value
  });
};

export const toggleQuestionTimer = value => dispatch => {
  dispatch({
    type: TOGGLE_QUESTION_TIMER,
    payload: value
  });
};

