// modules
import React, { Component } from 'react';
import styled from 'styled-components';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox, Container, Input, Message, TextArea } from 'semantic-ui-react';
// components
import ReactAudioPlayer from 'react-audio-player';
import AlternativeEditor from 'components/AlternativeEditor';
import SinglePair from './SinglePair';
import { AddSlotButton, InteractiveSingleSlot } from '../InteractiveQuestionsCommon';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

const PairsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

class InteractiveFindPairs extends Component {
  constructor(props) {
    super(props);

    const { new: isNew, block } = this.props;
    const isExistingBlock = !isNew && block;
    const interactive = isExistingBlock ? JSON.parse(block.interactive || '""') : {};

    this.state = {
      block: isExistingBlock ? block : {
        text: '',
        audio: '',
        hint: '',
        recTime: '',
        correctText: '',
        wrongText: '',
        hintingText: '',
        testQuestion: false,
        highlighted: false,
      },
      interactive: {
        pairs: interactive.pairs || [
          { first: '', second: '' },
        ],
      },
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.block && this.props.block.audio && prevProps.block && this.props.block.audio !== prevProps.block.audio) {
      this.setState(prevState => ({
        block: {
          ...prevState.block,
          audio: this.props.block.audio,
        },
      }));
    }
  }

  handleDeleteAudio = () => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'highlighted' || data.name === 'testQuestion') {
      result = data.checked;
    }

    this.setState(prevState => ({
      block: {
        ...prevState.block,
        [data.name]: result,
      }
    }));
  };

  handleChangePair = (e, data) => {
    console.log('[change] data:', data);

    const updatedPairs = [...this.state.interactive.pairs];
    updatedPairs[data.index][data.order] = data.value;

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        pairs: updatedPairs,
      },
    }), () => {
      console.log('this.state:', this.state);
    });
  }

  handleAddPair = () => {
    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        pairs: [...prevState.interactive.pairs, {
          first: '', second: '',
        }],
      },
    }), () => {
      console.log('this.state:', this.state);
    });
  }

  handleDeletePair = (index) => {
    console.log('[delete] index:', index);

    const updatedPairs = [...this.state.interactive.pairs];
    updatedPairs.splice(index, 1);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        pairs: updatedPairs,
      },
    }), () => {
      console.log('this.state:', this.state);
    });
  }

  handleAddBlock = () => {
    this.props.handleAdd(this.state.block, this.state.interactive);
  }

  handleEditBlock = () => {
    this.props.handleEdit(this.state.block, this.state.interactive);
  }

  handleChoseImage = (e, data) => {
    var input = e.target.closest('.ui.input')
    input.querySelector('input').type = 'file';
    input.querySelector('input').onchange = e => { 
      var file = e.target.files[0]; 
      var reader = new FileReader();
      reader.onload = () => {
        var content = `<img src="${reader.result}">`
        input.querySelector('input').type = 'text'
        input.querySelector('input').value = content

        const updatedPairs = [...this.state.interactive.pairs];
        updatedPairs[input.getAttribute('index')][input.getAttribute('order')] = content

        this.setState(prevState => ({
          interactive: {
            ...prevState.interactive,
            pairs: updatedPairs,
          },
        }), () => {
          console.log('this.state:', this.state);
        });

      }
      reader.readAsDataURL(file);
    }
    input.querySelector('input').click();
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold('Здесь можете вписать текст для отображения перед самим заданием')}
          <AlternativeEditor editorType="full" data={this.state.block.text} onChange={this.handleTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Аудио-вопрос')}
          {this.state.block.audio ? (
            <div style={{ marginBottom: '2em' }}>
              <ReactAudioPlayer src={this.state.block.audio} controls />
              <br />
              <br />
              <Button negative onClick={this.handleDeleteAudio}>
                Удалить аудио вопрос
              </Button>
            </div>
          ) : null}
          <input type="file" onChange={this.props.handleAudioSelect} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Задайте пары и соответствия')}
          <PairsContainer>
            {this.state.interactive.pairs.map((pair, index) => (
              <SinglePair
                key={`interactive-find-pairs-${index}`}
                pair={pair}
                index={index}
                handleChange={this.handleChangePair}
                handleDelete={this.handleDeletePair}
                handleChoseImage={this.handleChoseImage}
              />
            ))}
            <AddSlotButton onClick={this.handleAddPair}>
              Добавить пару
            </AddSlotButton>
          </PairsContainer>
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}

          <Container>
            <Checkbox
              label="Подсветить блок?"
              toggle
              name="highlighted"
              checked={this.state.block.highlighted}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          <Container>
            <Checkbox
              label="Тестовый вопрос?"
              toggle
              name="testQuestion"
              checked={this.state.block.testQuestion}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
        </div>

        {bold('Рекоммендуемое время:')}
        <Input fluid name="recTime" type="number" value={this.state.block.recTime || ''} onChange={this.handleChange} />
        <Message
          info
          icon="stopwatch"
          header="Время решения вопроса"
          content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя"
        />

        <div>
          {bold('Подсказка:')}
          <TextArea
            autoHeight
            name="hint"
            value={this.state.block.hint}
            onChange={this.handleChange}
            style={{ width: '100%', minHeight: 100 }}
          />

          {bold('Текст при правильном ответе:')}
          <TextArea
            autoHeight
            name="correctText"
            value={this.state.block.correctText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />

          {this.state.block.testQuestion ? null : (
            <div>
              {bold('Текст-подсказка при первом неправильном ответе:')}
              <TextArea
                autoHeight
                name="hintingText"
                value={this.state.block.hintingText}
                onChange={this.handleChange}
                style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
              />
            </div>
          )}

          {bold('Текст при неправильном ответе:')}
          <TextArea
            autoHeight
            name="wrongText"
            value={this.state.block.wrongText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>

        {this.props.new ? (
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={this.handleAddBlock}
            style={addStyle}
          >
            Добавить блок
          </Button>
        ) : (
          <>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.props.editing}
              onClick={this.handleEditBlock}
              style={editStyle}
            >
              Редактировать блок
            </Button>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.props.deleting}
              onClick={this.props.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default InteractiveFindPairs;
