// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
// assets
import { bold } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Checkbox, Container, Dropdown, Header, Image, Input, Loader, Message } from 'semantic-ui-react';
// components
import AlternativeEditor from 'components/AlternativeEditor';
import CourseStyle from '../CourseStyle';
// redux
import { logout } from 'store/User';

const redirect = (context, path) => {
  context.props.history.push(path);
};

const StyledCourseContainer = styled(Container)`
  strong {
    font-size: 16px;
    font-weight: 600;
  }
`;

const StyledCourseSegmentContainer = styled(Container)`
  margin-bottom: 16px;
`;

const StyledCourseButton = styled(Button)`
  background-color: ${props => props.buttoncolor} !important;
  color: #fff !important;
  border-radius: 0px !important;
`;

class Course extends Component {
  state = {
    editing: false,
    loading: false,
    uploading: false,
    showCoverUploadMsg: false,
    showStyleModal: false,
    courseId: '',
    course: {
      name: '',
      description: '',
      notes: '',
      classroom: 0,
      subject: '',
      language: '',
      hidden: false,
      cover: '',
      formulas: '',
      extraMaterial: '',
      courseStyle: '',
      lessons: []
    }
  };

  componentDidMount() {
    const courseId = this.props.match.params.id;
    this.setState({
      courseId: courseId
    });
    this.fetchCourse(courseId);
  }

  fetchCourse = courseId => {
    this.setState({ loading: true });
    axios.get(adminRoutes.courses.getCourse(courseId)).then(
      response => {
        this.setState({ loading: false, course: response.data.message });
      },
      error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      }
    );
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'hidden') {
      result = data.checked;
    }

    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        [data.name]: result
      }
    }));
  };

  handleFormulasChange = value => {
    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        formulas: value
      }
    }));
  };

  handleExtraMaterialChange = value => {
    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        extraMaterial: value
      }
    }));
  };

  handleNotesChange = value => {
    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        notes: value
      }
    }));
  };

  handleStyleModal = value => {
    this.setState({ showStyleModal: value });
  };

  handleEditCourse = () => {
    // show alert if course ID was not loaded
    if (!this.state.courseId) {
      alert('Произошла ошибка');
      return;
    }

    // show alert if name was not entered
    if (!this.state.course.name) {
      alert('Название учебника не было введено');
      return;
    }

    const r = window.confirm('Редактировать описание этого учебника?');
    if (r) {
      this.setState({ editing: true });
      // edit course
      axios.post(adminRoutes.courses.editCourse(this.state.courseId), { course: { ...this.state.course } }).then(
        () => {
          this.setState({ editing: false });
          this.fetchCourse(this.state.courseId);
        },
        () => {
          this.setState({ editing: false });
          alert('Ошибка редактирования учебника');
        }
      );
    }
  };

  handleDeleteCourse = () => {
    // show alert if course ID was not loaded
    if (!this.state.courseId) {
      alert('Произошла ошибка');
      return;
    }

    const r = window.confirm('Удалить этот учебник?');
    if (r) {
      // delete course
      axios.delete(adminRoutes.courses.deleteCourse(this.state.courseId)).then(
        () => {
          // force redirect
          window.location = '/admin/courses';
        },
        () => {
          alert('Ошибка удаления учебника');
        }
      );
    }
  };

  handleCoverUpload = async e => {
    // create a new form data
    const data = new FormData();
    // append file
    data.append('file', e.target.files[0]);
    // get file extension
    let extension = e.target.files[0].name.split('.');
    extension = extension[extension.length - 1];
    // append filename
    data.append('filename', `${this.state.courseId}-course-cover.${extension}`);
    // set uploading to true
    this.setState({ uploading: true });
    // axios call
    const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch(error => {
      alert('Произошла ошибка при загрузке обложки, попробуйте еще раз');
      this.setState({ uploading: false });
      return;
    });
    const shouldRefreshPage = this.state.course.cover;
    // set course cover
    this.setState(
      prevState => ({
        ...prevState,
        course: {
          ...prevState.course,
          cover: response.data.message.link
        },
        uploading: false,
        showCoverUploadMsg: true
      }),
      async () => {
        // update course
        await axios.post(adminRoutes.courses.editCourse(this.state.courseId), { course: { ...this.state.course } });
        if (shouldRefreshPage) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setTimeout(() => {
            this.handleDismissCoverUploadMsg();
          }, 3000);
        }
      }
    );
  };

  handleDismissCoverUploadMsg = () => {
    this.setState({ showCoverUploadMsg: false });
  };

  onLessonsOrderChange = newLessons => {
    this.setState(prevState => ({
      course: {
        ...prevState.course,
        lessons: newLessons
      }
    }));
  };

  onLessonsOrderSave = () => {
    const r = window.confirm('Изменить порядок глав учебника?');
    if (r) {
      this.setState({ editing: true });

      const { courseId, course = {} } = this.state;
      const lessons = (course.lessons || []).map((lesson, index) => ({
        _id: lesson._id,
        id: lesson.id,
        name: lesson.name,
        index: index
      }));

      // edit lessons order
      axios.post(adminRoutes.lessons.changeLessonsOrder(courseId), { lessons }).then(
        () => {
          this.setState({ editing: false });
          this.fetchCourse(this.state.courseId);
        },
        () => {
          this.setState({ editing: false });
          alert('Ошибка редактирования учебника');
        }
      );
    }
  };

  render() {
    const { loading, uploading, showCoverUploadMsg, showStyleModal, courseId, course = {} } = this.state;
    const { t } = this.props;
    const languageOptions = [
      {
        key: 'ru',
        text: t('ru'),
        value: 'ru'
      },
      {
        key: 'kk',
        text: t('kk'),
        value: 'kk'
      },
      {
        key: 'en',
        text: t('en'),
        value: 'en'
      },
      {
        key: 'ug',
        text: t('ug'),
        value: 'ug'
      }
    ];

    const classOptions = [
      {
        key: '0',
        text: 'Предшкола',
        value: '0'
      },
      {
        key: '1',
        text: '1 класс',
        value: '1'
      },
      {
        key: '2',
        text: '2 класс',
        value: '2'
      },
      {
        key: '3',
        text: '3 класс',
        value: '3'
      },
      {
        key: '4',
        text: '4 класс',
        value: '4'
      },
      {
        key: '5',
        text: '5 класс',
        value: '5'
      },
      {
        key: '6',
        text: '6 класс',
        value: '6'
      },
      {
        key: '7',
        text: '7 класс',
        value: '7'
      },
      {
        key: '8',
        text: '8 класс',
        value: '8'
      },
      {
        key: '9',
        text: '9 класс',
        value: '9'
      },
      {
        key: '10',
        text: '10 класс',
        value: '10'
      },
      {
        key: '11',
        text: '11 класс',
        value: '11'
      }
    ];

    const subjectOptions = [
      {
        key: 'Предшкола',
        text: 'Предшкола',
        value: 'Предшкола'
      },
      {
        key: 'История',
        text: 'История',
        value: 'История'
      },
      {
        key: 'Казахский язык',
        text: 'Казахский язык',
        value: 'Казахский язык'
      },
      {
        key: 'Русский язык',
        text: 'Русский язык',
        value: 'Русский язык'
      },
      {
        key: 'Литература',
        text: 'Литература',
        value: 'Литература'
      },
      {
        key: 'Математика',
        text: 'Математика',
        value: 'Математика'
      },
      {
        key: 'География',
        text: 'География',
        value: 'География'
      },
      {
        key: 'Информатика',
        text: 'Информатика',
        value: 'Информатика'
      },
      {
        key: 'Физика',
        text: 'Физика',
        value: 'Физика'
      },
      {
        key: 'Биология',
        text: 'Биология',
        value: 'Биология'
      },
      {
        key: 'Иностранный язык',
        text: 'Иностранный язык',
        value: 'Иностранный язык'
      },
      {
        key: 'Музыка',
        text: 'Музыка',
        value: 'Музыка'
      },
      {
        key: 'Естествознание',
        text: 'Естествознание',
        value: 'Естествознание'
      },
      {
        key: 'Основы предпринимательства и бизнеса',
        text: 'Основы предпринимательства и бизнеса',
        value: 'Основы предпринимательства и бизнеса'
      },
      {
        key: 'Хрестоматияа',
        text: 'Хрестоматия',
        value: 'Хрестоматия'
      },
      {
        key: 'Факультативы',
        text: 'Факультативы',
        value: 'Факультативы'
      },
      {
        key: 'Рабочая тетрадь',
        text: 'Рабочая тетрадь',
        value: 'Рабочая тетрадь'
      },
      {
        key: 'Методическое руководство',
        text: 'Методическое руководство',
        value: 'Методическое руководство'
      }
    ];

    const loader = (
      <Loader active size="large">
        Загрузка учебника
      </Loader>
    );
    const content = (
      <StyledCourseContainer>
        {showStyleModal && (
          <CourseStyle 
            courseId={courseId}
            courseStyle={course.courseStyle}
            fetchCourse={this.fetchCourse}
            onClose={() => this.handleStyleModal(false)}
          />
        )}

        <Header size="large" textAlign="center">
          {course.name}
        </Header>

        <StyledCourseSegmentContainer>
          {bold('Название учебника')}
          <Input fluid required name="name" placeholder="Название учебника" value={course.name} onChange={this.handleChange} />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Описание учебника')}
          <Input
            fluid
            required
            name="description"
            placeholder="Описание учебника"
            value={course.description}
            onChange={this.handleChange}
          />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Заметки для редакторов')}
          <AlternativeEditor data={course.notes || ''} onChange={this.handleNotesChange} />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Язык учебника')}
          <Dropdown
            fluid
            selection
            required
            name="language"
            placeholder="Язык учебника"
            value={course.language}
            onChange={this.handleChange}
            options={languageOptions}
          />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Класс')}
          <Dropdown
            fluid
            selection
            required
            name="classroom"
            placeholder="Укажите класс"
            value={course.classroom}
            onChange={this.handleChange}
            options={classOptions}
          />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Предмет')}
          <Dropdown
            fluid
            selection
            required
            name="subject"
            placeholder="Выберите предмет"
            value={course.subject}
            onChange={this.handleChange}
            options={subjectOptions}
          />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Скрыть учебник?')}
          <Checkbox checked={course.hidden} name="hidden" toggle onChange={this.handleChange} />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Глоссарий')}
          <AlternativeEditor data={course.formulas || ''} onChange={this.handleFormulasChange} />
        </StyledCourseSegmentContainer>
        
        <StyledCourseSegmentContainer>
          {bold('Дополнительный материал')}
          <AlternativeEditor data={course.extraMaterial || ''} onChange={this.handleExtraMaterialChange} />
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          {bold('Обложка учебника')}
          <Image size="medium" src={course.cover} alt="Course cover" />
          {showCoverUploadMsg && (
            <Message
              positive
              onDismiss={this.handleDismissCoverUploadMsg}
              header="Загрузка обложки учебника"
              content="Обложка учебника обновлена"
              style={{ marginTop: '1em' }}
            />
          )}
          <div style={{ marginTop: '1em' }}>
            <input type="file" onChange={this.handleCoverUpload} />
          </div>
          <div style={{ marginTop: '1em' }}>
            <b>Размер файла не должен превышать 10 МБ</b>
          </div>
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          <StyledCourseButton buttoncolor="#4059a1" onClick={this.handleStyleModal}>
            Изменить стиль учебника
          </StyledCourseButton>
          <StyledCourseButton buttoncolor="#254441" onClick={this.handleEditCourse}>
            Редактировать учебник
          </StyledCourseButton>
          <StyledCourseButton buttoncolor="#cc515d" onClick={this.handleDeleteCourse}>
            Удалить учебник
          </StyledCourseButton>
        </StyledCourseSegmentContainer>

        <StyledCourseSegmentContainer>
          <Header size="large" textAlign="center">
            Уроки учебника
          </Header>
          <ReactSortable
            list={course.lessons || []}
            setList={this.onLessonsOrderChange}
            animation={200}
            style={{
              marginBottom: '1em'
            }}
          >
            {(course.lessons || []).map(item => {
              const name = item.section ? `${item.section}. ${item.name}` : item.name;
              return (
                <div
                  key={`lesson-${item._id}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    margin: '8px 0',
                    padding: '8px 4px',
                    border: '1px solid rgba(0, 0, 0, 0.25)',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.5)'
                  }}
                >
                  <div style={{ marginRight: '8px', fontWeight: 'bold', cursor: 'pointer' }}>::</div>
                  <div>
                    <h4
                      style={{
                        color: '#3697dd',
                        cursor: 'pointer',
                        fontSize: '16px',
                        textDecoration: item.hidden ? 'line-through' : ''
                      }}
                      onClick={() => {
                        window.location = `/admin/courses/${courseId}/lessons/${item._id}`;
                      }}
                    >
                      {ReactHtmlParser(name)}
                      {item.hidden && <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>(урок скрыт)</span>}
                    </h4>
                  </div>
                </div>
              );
            })}
          </ReactSortable>
          <StyledCourseButton buttoncolor="#254441" onClick={this.onLessonsOrderSave}>
            Сохранить порядок уроков
          </StyledCourseButton>
          <StyledCourseButton buttoncolor="#009b72" onClick={() => redirect(this, `/admin/courses/${courseId}/lessons/new`)}>
            Добавить новый урок
          </StyledCourseButton>
        </StyledCourseSegmentContainer>
      </StyledCourseContainer>
    );

    return loading || uploading ? loader : content;
  }
}

export default connect(store => ({}), {
  logout
})(withTranslation()(Course));
