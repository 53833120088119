// modules
import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
// assets
import popupArrow from "assets/images/popup-arrow.svg";
// styles
// components
import MathjaxWrapper from "components/MathjaxWrapper";
// redux

const StyledInteractiveBlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: row;

    img {
        max-width: 100%;
    }
`;

const StyledArrowContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
`;

const StyledArrow = styled.div`
    position: relative;
    max-width: 64px;
    cursor: pointer;
`;

const StyledPopupContainer = styled.div`
    position: relative;
    height: 100%;
    background: #ffffff;
    border: #2b7bc6 solid 2px;
    font-weight: 400;
    text-align: center;

    visibility: ${(props) => (props.visible ? "visible" : "hidden")};
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: visibility 0s, opacity 1s linear;

    ${media.lessThan("small")`
        padding: 8px;
        max-width: 120px;
        border-radius: 16px;
        font-size: 8px;
        line-height: 1;
    `}

    ${media.between("small", "medium")`
        padding: 8px;
        max-width: 120px;
        border-radius: 16px;
        font-size: 8px;
        line-height: 1;
    `}

    ${media.between("medium", "large")`
        padding: 12px;
        max-width: 160px;
        border-radius: 16px;
        font-size: 12px;
        line-height: 1.1;
    `}

    ${media.greaterThan("large")`
        padding: 16px;
        max-width: 240px;
        border-radius: 24px;
        font-size: 14px;
        line-height: 1.2;
    `}
`;

class InteractiveBlock extends Component {
    state = {
        isPopupVisible: false,
        currentPopupText: "",
    };

    togglePopupVisibility = (value) => {
        this.setState({ isPopupVisible: value });
    };

    onArrowClick = (i) => {
        this.togglePopupVisibility(false);

        this.setState({ currentPopupText: this.props.popupTextList[i] }, () => {
            this.togglePopupVisibility(true);
        });
    };

    render() {
        return (
            <StyledInteractiveBlockContainer className={this.props.highlighted ? "highlighted" : ""}>
                <StyledContentContainer>
                    <div className="ck-content" style={{ width: '100%',}}>
                        <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
                    </div>
                    <StyledArrowContainer>
                        {_.times(5, (i) => (
                            <StyledArrow key={`interactive-block-arrow-${i}`} onClick={() => this.onArrowClick(i)}>
                                <img src={popupArrow} alt="" />
                            </StyledArrow>
                        ))}
                    </StyledArrowContainer>
                </StyledContentContainer>
                <StyledPopupContainer visible={this.state.isPopupVisible}>
                    {this.state.currentPopupText}
                </StyledPopupContainer>
            </StyledInteractiveBlockContainer>
        );
    }
}

export default InteractiveBlock;
