// modules
import React, { Component } from 'react';
import styled from 'styled-components';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox, Container, Input, Message, TextArea } from 'semantic-ui-react';
// components
import ReactAudioPlayer from 'react-audio-player';
import AlternativeEditor from 'components/AlternativeEditor';
import SingleGroup from './SingleGroup';
import { AddSlotButton  } from '../InteractiveQuestionsCommon';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddGroupButton = styled(AddSlotButton)`
  width: fit-content;
  border: 1px solid #1075cc;
  color: #1075cc;
`;

class InteractiveFillSlotsBlock extends Component {
  constructor(props) {
    super(props);

    const { new: isNew, block } = this.props;
    const isExistingBlock = !isNew && block;
    const interactive = isExistingBlock ? JSON.parse(block.interactive || '""') : {};

    this.state = {
      block: isExistingBlock ? block : {
        text: '',
        audio: '',
        hint: '',
        recTime: '',
        correctText: '',
        wrongText: '',
        hintingText: '',
        testQuestion: false,
        highlighted: false,
      },
      interactive: {
        groups: interactive.groups || [
          { name: '', slots: [''] }
        ],
      },
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.block && this.props.block.audio && prevProps.block && this.props.block.audio !== prevProps.block.audio) {
      this.setState(prevState => ({
        block: {
          ...prevState.block,
          audio: this.props.block.audio,
        },
      }));
    }
  }

  handleDeleteAudio = () => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'highlighted' || data.name === 'testQuestion') {
      result = data.checked;
    }

    this.setState(prevState => ({
      block: {
        ...prevState.block,
        [data.name]: result,
      }
    }));
  };

  handleChangeGroup = (e, data) => {
    const updatedGroups = [...this.state.interactive.groups];
    const updatedGroup = updatedGroups[data.index];
    updatedGroup.name = data.value;

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: updatedGroups,
      },
    }));
  }

  handleChangeSlot = (e, data) => {
    const updatedGroups = [...this.state.interactive.groups];
    const updatedGroup = updatedGroups[data.groupindex];
    updatedGroup.slots.splice(data.index, 1, data.value);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: updatedGroups,
      },
    }));
  }

  handleAddGroup = () => {
    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: [
          ...prevState.interactive.groups,
          { name: '', slots: [''] },
        ],
      },
    }));
  }

  handleAddSlot = (index) => {
    const updatedGroups = [...this.state.interactive.groups];
    const updatedGroup = updatedGroups[index];
    updatedGroup.slots = [...updatedGroup.slots, ''];

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: updatedGroups,
      },
    }));
  }

  handleDeleteGroup = (index) => {
    const updatedGroups = [...this.state.interactive.groups];
    updatedGroups.splice(index, 1);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: updatedGroups,
      },
    }));
  }

  handleDeleteSlot = (index, groupIndex) => {
    const updatedGroups = [...this.state.interactive.groups];
    const updatedGroup = updatedGroups[groupIndex];
    updatedGroup.slots.splice(index, 1);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        groups: updatedGroups,
      },
    }));
  }

  handleChoseImage = (e, data) => {
    var input = e.target.closest('.ui.input')
    input.querySelector('input').type = 'file';
    input.querySelector('input').onchange = e => { 
      var file = e.target.files[0]; 
      var reader = new FileReader();
      reader.onload = () => {
        var content = `<img src="${reader.result}">`
        input.querySelector('input').type = 'text'
        input.querySelector('input').value = content

        const updatedGroups = [...this.state.interactive.groups];
        const updatedGroup = updatedGroups[input.getAttribute('groupindex')];
        updatedGroup.slots.splice(input.getAttribute('index'), 1, content);

        this.setState(prevState => ({
          interactive: {
            ...prevState.interactive,
            groups: updatedGroups,
          },
        }));

      }
      reader.readAsDataURL(file);
    }
    input.querySelector('input').click();
  }

  handleAddBlock = () => {
    this.props.handleAdd(this.state.block, this.state.interactive);
  }

  handleEditBlock = () => {
    this.props.handleEdit(this.state.block, this.state.interactive);
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold('Здесь можете вписать текст для отображения перед самим заданием')}
          <AlternativeEditor editorType="full" data={this.state.block.text} onChange={this.handleTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Аудио-вопрос')}
          {this.state.block.audio ? (
            <div style={{ marginBottom: '2em' }}>
              <ReactAudioPlayer src={this.state.block.audio} controls />
              <br />
              <br />
              <Button negative onClick={this.handleDeleteAudio}>
                Удалить аудио вопрос
              </Button>
            </div>
          ) : null}
          <input type="file" onChange={this.props.handleAudioSelect} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Группы')}
          <GroupsContainer>
            {this.state.interactive.groups.map((group, index) => (
              <SingleGroup
                key={`interactive-classification-group-${index}`}
                group={group}
                index={index}
                handleChange={this.handleChangeGroup}
                handleDelete={this.handleDeleteGroup}
                handleChangeSlot={this.handleChangeSlot}
                handleAddSlot={this.handleAddSlot}
                handleDeleteSlot={this.handleDeleteSlot}
                handleChoseImage={this.handleChoseImage}
              />
            ))}
            <AddGroupButton onClick={this.handleAddGroup}>
              Добавить группу
            </AddGroupButton>
          </GroupsContainer>
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}

          <Container>
            <Checkbox
              label="Подсветить блок?"
              toggle
              name="highlighted"
              checked={this.state.block.highlighted}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          <Container>
            <Checkbox
              label="Тестовый вопрос?"
              toggle
              name="testQuestion"
              checked={this.state.block.testQuestion}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
        </div>

        {bold('Рекоммендуемое время:')}
        <Input fluid name="recTime" type="number" value={this.state.block.recTime || ''} onChange={this.handleChange} />
        <Message
          info
          icon="stopwatch"
          header="Время решения вопроса"
          content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя"
        />

        <div>
          {bold('Подсказка:')}
          <TextArea
            autoHeight
            name="hint"
            value={this.state.block.hint}
            onChange={this.handleChange}
            style={{ width: '100%', minHeight: 100 }}
          />

          {bold('Текст при правильном ответе:')}
          <TextArea
            autoHeight
            name="correctText"
            value={this.state.block.correctText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />

          {this.state.block.testQuestion ? null : (
            <div>
              {bold('Текст-подсказка при первом неправильном ответе:')}
              <TextArea
                autoHeight
                name="hintingText"
                value={this.state.block.hintingText}
                onChange={this.handleChange}
                style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
              />
            </div>
          )}

          {bold('Текст при неправильном ответе:')}
          <TextArea
            autoHeight
            name="wrongText"
            value={this.state.block.wrongText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>

        {this.props.new ? (
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={this.handleAddBlock}
            style={addStyle}
          >
            Добавить блок
          </Button>
        ) : (
          <>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.props.editing}
              onClick={this.handleEditBlock}
              style={editStyle}
            >
              Редактировать блок
            </Button>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.props.deleting}
              onClick={this.props.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default InteractiveFillSlotsBlock;
