// modules
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import media from "styled-media-query";
import ReactHtmlParser from "react-html-parser";
// redux
import { setLocale } from "store/Config";
// assets
import background from "assets/images/sidebar_bg.png";
import student from "assets/images/student.svg";
import { logoutAndScrollToTop } from "utils";
// styles
import { Divider, Dropdown, Menu, Progress, Sidebar } from "semantic-ui-react";
import dayjs from "dayjs";

const StyledSidebarMenuWrapper = styled.div`
	.ui.sidebar {
		background-image: ${(props) => `url(${props.background})`};
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
	}

	${media.lessThan("400px")`
		.ui.sidebar {
			width: 320px;
			padding: 16px;
		}
	`}

	${media.between("400px", "600px")`
		.ui.sidebar {
			width: 400px;
			padding: 20px;
		}
	`}

	${media.between("600px", "800px")`
		.ui.sidebar {
			width: 520px;
			padding: 24px;
		}
	`}

	${media.greaterThan("800px")`
		.ui.sidebar {
			width: 600px;
			padding: 32px;
		}
	`}
`;

const StyledCrossButton = styled.svg`
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  color: #ffffff;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledUserWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	.name {
		color: #ffffff;
		font-weight: 600;
		text-transform: uppercase;
	}

	${media.lessThan("400px")`
		margin-bottom: 8px;

		img {
			margin-right: 8px;
			width: 64px;
		}

		.name {
			font-size: 16px;
		}
	`}

	${media.between("400px", "600px")`
		margin-bottom: 16px;

		img {
			margin-right: 12px;
			width: 72px;
		}

		.name {
			font-size: 18px;
		}
	`}

	${media.greaterThan("600px")`
		margin-bottom: 24px;

		img {
			margin-right: 16px;
			width: 80px;
		}

		.name {
			font-size: 20px;
		}
	`}
`;

const StyledLinksWrapper = styled.div`
	display: flex;
	flex-direction: column;

	a,
	span {
		width: fit-content;
		cursor: pointer;
		color: #ffffff;
		font-weight: 400;

		&:last-child {
			margin-bottom: 0px;
		}

		${media.lessThan("400px")`
			margin-bottom: 8px;
			font-size: 14px;
		`}

		${media.between("400px", "600px")`
			margin-bottom: 12px;
			font-size: 16px;
		`}

		${media.greaterThan("600px")`
			margin-bottom: 16px;
			font-size: 18px;
		`}
	}
`;

const StyledPaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #ffffff;

  & > div:first-child {
    & > span {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  & > div:last-child {
    & > div.progress {
      margin-bottom: 0 !important;
    }
  }

  & > div.no-payment {
    text-decoration: underline;
  }

  ${media.lessThan("400px")`
    font-size: 14px;
  `}

  ${media.between("400px", "600px")`
    font-size: 16px;
  `}

  ${media.greaterThan("600px")`
    font-size: 18px;
  `}
`;

const StyledCourseName = styled.div`
	${media.lessThan("400px")`
		margin-bottom: 8px;
	`}

	${media.between("400px", "600px")`
		margin-bottom: 12px;
	`}

	${media.greaterThan("600px")`
		margin-bottom: 16px;
	`}

	a {
		cursor: pointer;
		color: #ffffff;
		font-weight: 500;

		${media.lessThan("400px")`
			font-size: 14px;
		`}

		${media.between("400px", "600px")`
			font-size: 16px;
		`}

		${media.greaterThan("600px")`
			font-size: 18px;
		`}
	}
`;

const StyledCourseLanguageSelect = styled.div`
	color: #fff;

	${media.lessThan("400px")`
		margin-bottom: 8px;
	`}

	${media.between("400px", "600px")`
		margin-bottom: 12px;
	`}

	${media.greaterThan("600px")`
		margin-bottom: 16px;
	`}
`;

const StyledItemsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledItem = styled.div`
	line-height: 1.4;

	a {
		pointer-events: ${(props) => (props.isAvailable ? "auto" : "none")};
		color: ${(props) => (props.isAvailable ? "#dbdbdb" : "#838383")};
	}

	a.active {
		font-weight: 600;
		color: #ffffff;
	}

	a:hover {
		color: #ffffff;
	}

	${media.lessThan("400px")`
		margin-bottom: 4px;
		font-size: 12px;
	`}

	${media.between("400px", "600px")`
		margin-bottom: 8px;
		font-size: 14px;
	`}

	${media.greaterThan("600px")`
		margin-bottom: 12px;
		font-size: 16px;
	`}
`;

const getPaymentDueDate = (payment) => {
  if (!payment || !payment.dueDate) {
    return "";
  }

  return dayjs(payment.dueDate).format("DD/MM/YYYY");
};

const getPaymentDueProgress = (payment) => {
  if (!payment || !payment.date || !payment.dueDate) {
    return null;
  }

  const paymentDate = dayjs(payment.date);
  const today = dayjs();
  const dueDate = dayjs(payment.dueDate);

  return Math.round(
    (paymentDate.diff(today) / paymentDate.diff(dueDate)) * 100
  );
};

const SidebarMenu = ({
  changeLocale,
  currentCourse,
  firstName,
  lastName,
  locale,
  location,
  open,
  payment,
  t,
  toggleSidebarMenu,
}) => {
  const logout = () => {
    // hide sidebar menu
    toggleSidebarMenu(false);
    // logout
    logoutAndScrollToTop();
  };

  const courseName =
    currentCourse && currentCourse.name ? currentCourse.name : "";
  const courseItems =
    currentCourse &&
    currentCourse.lessons &&
    Array.isArray(currentCourse.lessons)
      ? currentCourse.lessons.map((item, index) => (
          <StyledItem
            key={`sidebar-menu-item-${index}`}
            isAvailable={item.isAvailable}
          >
            <NavLink
              activeClassName="active"
              onClick={() => toggleSidebarMenu(false)}
              to={item.to}
            >
              <span className="section">
                {item.section ? `${item.section}. ` : item.section}
              </span>
              <span>{ReactHtmlParser(item.displayName || item.name)}</span>
            </NavLink>
          </StyledItem>
        ))
      : [];
  const sidebarBackground =
    (currentCourse &&
      currentCourse.courseStyle &&
      currentCourse.courseStyle.sidebarBackground) ||
    background;

  const paymentCompleted = payment && payment.completed;
  const paymentDueDate = getPaymentDueDate(payment);
  const paymentDueProgress = getPaymentDueProgress(payment);

  const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');

  return (
    <StyledSidebarMenuWrapper background={sidebarBackground}>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="right"
        inverted
        vertical
        onHide={() => toggleSidebarMenu(false)}
        visible={open}
      >
        <StyledCrossButton
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => toggleSidebarMenu(false)}
        >
          <line
            x1="0"
            y1="0"
            x2="24"
            y2="24"
            stroke="#ffffff"
            strokeWidth="2"
          />
          <line
            x1="0"
            y1="24"
            x2="24"
            y2="0"
            stroke="#ffffff"
            strokeWidth="2"
          />
        </StyledCrossButton>

        <StyledUserWrapper>
          <img src={student} alt="User icon"></img>
          <span className="name">{`${lastName} ${firstName}`}</span>
        </StyledUserWrapper>

        <StyledLinksWrapper>
          <Link
            to={`${isInsideAdmin ? '/admin' : ''}/dashboard/profile`}
            onClick={() => toggleSidebarMenu(false)}
          >
            {t("dashboard.profile.title")}
          </Link>
          <Link
            to={`${isInsideAdmin ? '/admin' : ''}/dashboard/courses`}
            onClick={() => toggleSidebarMenu(false)}
          >
            {t("landing.courseList")}
          </Link>
          <Link to="/" onClick={() => toggleSidebarMenu(false)}>
            {t("landing.title")}
          </Link>
          <span onClick={logout}>{t("logout")}</span>
        </StyledLinksWrapper>

        <Divider />

        <StyledPaymentInfoWrapper>
          {paymentCompleted ? (
            <>
              <div>
                {t("dashboard.payment.sidebar.text")}: <span>{paymentDueDate}</span>
              </div>
              <div>
                {paymentDueProgress !== null && (
                  <Progress color="blue" percent={paymentDueProgress} />
                )}
              </div>
            </>
          ) : (
            <div className="no-payment">{t("dashboard.payment.sidebar.no_payment")}</div>
          )}
        </StyledPaymentInfoWrapper>

        <Divider />

        <StyledCourseLanguageSelect>
          <Dropdown
            inline
            selectOnBlur={false}
            text={t("interfaceLanguage")}
            value={locale}
            options={[
              { key: "kk", text: t("kk"), value: "kk" },
              { key: "ru", text: t("ru"), value: "ru" },
            ]}
            onChange={(e, { value }) => changeLocale(value)}
          />
        </StyledCourseLanguageSelect>

        <Divider />

        {currentCourse && (
          <>
            <StyledCourseName>
              <a href={`${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${currentCourse._id}`}>
                {courseName}
              </a>
            </StyledCourseName>
            
            <StyledItemsList>{courseItems}</StyledItemsList>
          </>
        )}
      </Sidebar>
    </StyledSidebarMenuWrapper>
  );
};

const mapStateToProps = (state) => ({
  lastName: state.user.user.lastName,
  firstName: state.user.user.firstName,
  currentCourse: state.ui.currentCourse,
  locale: state.config.locale,
  payment: state.user.user.payment,
});

const mapDispatchToProps = (dispatch) => ({
  changeLocale: (locale) => {
    dispatch(setLocale(locale));
    window.location.reload();
  },
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
  )(SidebarMenu)
);
