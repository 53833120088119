// modules
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
// assets
import { dashboardRoutes, practicesRoutes } from 'assets/routes';
import { compareById } from 'assets/utils';
// styles
import { Loader } from 'semantic-ui-react';
// components
import Lesson from './Lesson';
import Materials from './Materials';
import Practice from './Practice';
import PracticesList from './Practice/PracticesList';
import Visualization from './Visualization';
import Settings from './Settings';
// redux
import { setCurrentCourse, setCurrentLessonProgress } from 'store/UI';
import { getUser, logout } from 'store/User';

class Course extends Component {
  state = {
    loading: false,
    courseId: this.props.match.params.courseId,
    course: {},
    lessons: [],
    practices: [],
    progress: 0,
    innerWidth: window.innerWidth
  };

  componentDidMount() {
    this.fetchCourse(this.state.courseId);
  }

  handleProgress = (currentBlock, numBlocks) => {
    this.setState({ currentBlock: currentBlock, numBlocks: numBlocks }, () => {
      // update global UI state with current lesson progress
      const progress = Math.floor(((currentBlock + 1) / numBlocks) * 100);
      this.props.setCurrentLessonProgress(progress);
    });
  };

  fetchCourse = async courseId => {
    this.setState({ loading: true });

    // fetch course information
    let course = await axios.get(dashboardRoutes.fetchCourseInfo(courseId)).catch(error => {
      console.error('Error fetching course information' + error);
      if (error.response.status === 401) {
        this.props.logout();
      }
    });

    // fetch lessons
    let lessons = await axios.get(dashboardRoutes.fetchLessons(courseId)).catch(error => {
      console.error('Error fetching lessons ' + error);
      if (error.response.status === 401) {
        this.props.logout();
      }
    });

    // fetch practices
    let practices = await axios.get(practicesRoutes.getPractices(courseId)).catch(error => {
      console.error('Error fetching practices' + error);
      if (error.response.status === 401) {
        this.props.logout();
      }
    });

    if (course) {
      course = course.data.message;
    }
    if (lessons) {
      lessons = lessons.data.message;
    }
    if (practices) {
      practices = practices.data.message;
    }

    // fetch user
    await this.props.getUser();

    // create list
    let items = [];

    const user = this.props.user;
    // find user course
    const userCourse = user.courses.find(x => x._id === this.state.courseId);
    // find last lesson
    const lastLesson = userCourse ? userCourse.lastLesson : { _id: '', id: 1 };

    const { location } = this.props;
    const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');

    // add items
    items = lessons.map((lesson, index) => ({
      isAvailable: true, // all lessons are available in side menu
      // isAvailable: user.scope === 'expert' ? true : (lesson.id <= lastLesson.id),
      id: lesson.id,
      section: lesson.section,
      name: lesson.name,
      displayName: lesson.displayName,
      to: `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${this.state.courseId}/lesson/${lesson._id}`
    }));
    // sort items by sections
    items = items.sort(compareById);

    // build an array of lesson keywords
    const lessonKeywords = lessons.map(item => ({
      _id: item._id,
      id: item.id,
      title: `${item.section}. ${item.name}`,
      keywords: item.keywords,
    }));

    // set current course
    const currentCourse = { ...course, lastLesson, lessonKeywords, lessons: items };
    this.props.setCurrentCourse(currentCourse);

    // set data to state
    this.setState({
      loading: false,
      course: course,
      lessons: lessons,
      practices: practices,
      lastLesson: lastLesson
    });
  };

  render() {
    const { user } = this.props;

    return (
      <DocumentTitle title={'dashboard.course.visualization.header'}>
        <div>
          {this.state.loading ? (
            <Loader active size="large" />
          ) : (
            <Switch>
              <Route
                exact
                path="*/dashboard/courses/:courseId"
                render={props => <Visualization courseId={this.state.courseId} lessons={this.state.lessons} />}
              />
              {(user || {}).scope === 'expert' && (
                <Route
                  exact
                  path="*/dashboard/courses/:courseId/settings"
                  render={props => <Settings courseId={this.state.courseId} course={this.state.course} />}
                />
              )}
              <Route exact path="*/dashboard/courses/:courseId/materials" render={props => <Materials />} />
              <Route path="*/dashboard/courses/:courseId/practices/all" render={props => <PracticesList />} />
              <Route
                path="*/dashboard/courses/:courseId/practice/:practiceId"
                render={props => (
                  <Practice handleProgress={this.handleProgress} innerWidth={this.state.innerWidth} {...props} />
                )}
              />
              <Route
                path="*/dashboard/courses/:courseId/lesson/:lessonId"
                render={props => <Lesson handleProgress={this.handleProgress} innerWidth={this.state.innerWidth} {...props} />}
              />
              <Redirect to="/dashboard" />
            </Switch>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUser()),
  logout: () => dispatch(logout()),
  setCurrentCourse: currentCourse => dispatch(setCurrentCourse(currentCourse)),
  setCurrentLessonProgress: progress => dispatch(setCurrentLessonProgress(progress))
});

export default withRouter(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withTranslation()
  )(Course)
);
