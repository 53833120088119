// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import axios from "axios";
import { Element, scroller } from "react-scroll";
// assets
import notFound from "assets/images/404.png";
import star from "assets/images/star_yellow.svg";
import stop from "assets/images/stop.svg";
import { dashboardRoutes, practicesRoutes } from "assets/routes";
// styles
import { Button, Container, Image } from "semantic-ui-react";
import "./index.css";
// components
import FooterStats from "./FooterStats";
import HeaderStats from "./HeaderStats";
import Question from "./Question";
// redux
import { getUser } from "store/User";

class Practice extends Component {
    state = {
        answering: false,
        loading: false,
        forbidden: false,
        notFound: false,
    };

    componentDidMount() {
        // fetch practice
        let id = this.props.match.params.id;
        this.fetchPractice(id);
    }

    componentDidUpdate(prevProps) {
        // fetch practice on router location changes
        let id = this.props.match.params.id;
        if (prevProps.match.params.id !== id) {
            // scroll to top
            window.scrollTo(0, 0);
            // fetch practice
            this.fetchPractice(id);
        }
    }

    fetchPractice = async (id) => {
        this.setState({ id: id, loading: true, forbidden: false, notFound: false });
        let response = await axios.get(practicesRoutes.getPractice(id)).catch((error) => {
            switch (error.response.status) {
                case 403:
                    this.setState({ loading: false, forbidden: true });
                    break;
                case 404:
                    this.setState({ loading: false, notFound: true });
                    break;
                default:
                    return;
            }
        });

        if (response) {
            response = response.data.message;
            // pick out practice and user practice
            let practice = response.practice;
            let userPractice = response.userPractice;
            // populate practice with user answers
            practice.completed = userPractice.completed;
            practice.correct = userPractice.correct;
            practice.currentQuestion = userPractice.currentQuestion;
            // iterate over questions
            let questions = [];
            for (let i = 0; i < practice.questions.length; i++) {
                let q = JSON.parse(practice.questions[i].question);
                q = { ...q, _id: practice.questions[i]._id };
                questions.push(q);
            }
            practice.questions = questions;
            // iterate over user answered questions
            for (let i = 0; i < userPractice.answers.length; i++) {
                let index = practice.questions.findIndex((x) => x._id === userPractice.answers[i]._id);
                practice.questions[index].answer = userPractice.answers[i].answer;
                practice.questions[index].correct = userPractice.answers[i].correct;
                practice.questions[index].time = userPractice.answers[i].time;
            }

            // set practice
            this.setState({ answering: false, loading: false, practice: practice }, () => {
                // handle progress
                let progress = ((this.state.practice.currentQuestion + 1) / this.state.practice.questions.length) * 100;
                progress = Math.floor(progress);
                this.props.handleProgress(progress);
            });
        }
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    handleAnswering = (value) => {
        this.setState({ answering: value });
    };

    handleCorrect = () => {
        this.setState({
            practice: {
                ...this.state.practice,
                correct: this.state.practice.correct + 1,
            },
        });
    };

    handleMarkTime = (index, time) => {
        if (this.state.practice) {
            const questions = [...this.state.practice.questions];
            questions[index]["time"] = time;
            this.setState((prevState) => ({
                practice: {
                    ...prevState.practice,
                    questions: questions,
                },
            }));
        }
    };

    handleUpdateLastQuestion = () => {
        this.setState(
            {
                practice: {
                    ...this.state.practice,
                    currentQuestion: this.state.practice.currentQuestion + 1,
                },
            },
            () => {
                // scroll
                scroller.scrollTo("last-question", {
                    duration: 750,
                    delay: 100,
                    smooth: "easeInOutCubic",
                    offset: -32,
                });
                // handle progress
                let progress = ((this.state.practice.currentQuestion + 1) / this.state.practice.length) * 100;
                progress = Math.floor(progress);
                this.props.handleProgress(progress);
                // payload
                const payload = {
                    completed: this.state.practice.completed,
                    currentQuestion: this.state.practice.currentQuestion,
                };
                // axios call
                axios.post(practicesRoutes.updateLastQuestion(this.state.id), payload);
            }
        );
    };

    handleCompleted = () => {
        this.setState({ practice: { ...this.state.practice, completed: true } }, () => {
            // scroll
            scroller.scrollTo("dashboard-practice-stars", {
                duration: 750,
                delay: 100,
                smooth: "easeInOutCubic",
                offset: -40,
            });
            // payload
            const payload = {
                completed: this.state.practice.completed,
                currentQuestion: this.state.practice.currentQuestion,
                results: this.state.practice.correct,
            };
            // axios call
            axios.post(practicesRoutes.updateLastQuestion(this.state.id), payload);
        });
    };

    handleUpdateLastLesson = async () => {
        if (this.state.practice && this.state.practice.lesson) {
            if (this.state.practice.lesson.nextLesson && this.state.practice.lesson.nextLesson._id) {
                const { location } = this.props;
                const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
                const redirectLocation = `${isInsideAdmin ? '/admin' : ''}/dashboard/lesson/${this.state.practice.lesson.nextLesson._id}`;

                // update last lesson only if you are now on the last lesson
                if (this.state.practice.lesson._id === this.props.lastLesson._id) {
                    // payload
                    const payload = { lastLesson: this.state.practice.lesson.nextLesson };
                    // axios call
                    await axios.post(dashboardRoutes.updateLesson(), payload).catch((error) => {
                        alert(`Ошибка: ${error.toString()}`);
                    });
                    // redirect
                    setTimeout(() => {
                        // redirect
                        window.location = redirectLocation;
                    }, 1000);
                } else {
                    // redirect
                    setTimeout(() => {
                        // redirect
                        window.location = redirectLocation;
                    }, 1000);
                }
            }
        }
    };

    handleNextClick = () => {
        if (this.state.practice) {
            const { currentQuestion } = this.state.practice;
            const length = this.state.practice.questions.length;
            if (currentQuestion === length - 1) {
                this.handleCompleted();
            } else {
                this.handleUpdateLastQuestion();
            }
        }
    };

    render() {
        let forbiddenContainer;
        let notFoundContainer;
        let practiceName;
        let practice = [];
        if (this.state.forbidden) {
            forbiddenContainer = (
                <Container className="dashboard-practice-forbidden" textAlign="center">
                    <p>Вы не допущены к этому уроку</p>
                    <Image className="dashboard-practice-forbidden-image" centered src={stop} alt="" />
                </Container>
            );
        }
        if (this.state.notFound) {
            notFoundContainer = (
                <Container className="dashboard-practice-not-found" textAlign="center">
                    <p>Данного урока не существует, вы обратились по неправильной странице</p>
                    <Image className="dashboard-practice-not-found-image" centered src={notFound} alt="" />
                </Container>
            );
        }
        if (this.state.practice && !this.state.forbidden && !this.state.notFound) {
            // practice name
            practiceName = (
                <div className="dashboard-practice-name">
                    {this.state.practice.section}. {this.state.practice.name}
                </div>
            );
            // header stats
            if (this.state.practice.completed) {
                practice.push(
                    <HeaderStats
                        key="dashboard-practice-header-stats"
                        correct={this.state.practice.correct}
                        questions={this.state.practice.questions.length}
                    />
                );
            }
            if (this.state.practice.questions && this.state.practice.questions.length !== 0) {
                // practice questions
                for (let i = 0; i <= this.state.practice.currentQuestion; i++) {
                    // stringify question
                    let question = this.state.practice.questions[i];
                    // replace nickname in text
                    let text = question.text;
                    if (!!text) {
                        text = text.replace("$$placeholder$$", this.props.nickname);
                    }
                    // select component based on block type
                    let item = (
                        <Question
                            handleAnswering={this.handleAnswering}
                            handleCorrect={this.handleCorrect}
                            handleMarkTime={this.handleMarkTime}
                            id={this.state.id}
                            index={i}
                            showTimer={this.props.isTimerVisible}
                            nickname={this.props.nickname}
                            question={question}
                            text={text}
                        />
                    );

                    // push item
                    practice.push(
                        <Element key={`dashboard-practice-question-${i}`} name="last-question">
                            {item}
                        </Element>
                    );
                }
                // push next question button
                if (!this.state.practice.completed && !this.state.answering) {
                    practice.push(
                        <div
                            key="dashboard-practice-next-question-button"
                            className="dashboard-practice-next-question-button"
                        >
                            <Button size="huge" onClick={this.handleNextClick}>
                                Дальше
                            </Button>
                        </div>
                    );
                }
            }
            // footer stats and next practice link
            if (this.state.practice.completed) {
                practice.push(
                    <Element
                        id="dashboard-practice-stars"
                        key="dashboard-practice-stars"
                        className="dashboard-practice-stars"
                        name="dashboard-practice-stars"
                    >
                        <Image alt="" className="dashboard-practice-star" src={star} />
                        <Image alt="" className="dashboard-practice-star" src={star} />
                        <Image alt="" className="dashboard-practice-star" src={star} />
                    </Element>
                );
                // footer stats
                if (this.state.practice.questions) {
                    practice.push(
                        <FooterStats
                            key="dashboard-practice-footer-stats"
                            correct={this.state.practice.correct}
                            id={this.state.id}
                            innerWidth={this.props.innerWidth}
                            section={this.state.practice.section}
                            practiceId={this.state.practice.id}
                            questions={this.state.practice.questions.length}
                        />
                    );
                }
                // next lesson link
                practice.push(
                    <div key="dashboard-practice-next-lesson" className="dashboard-practice-next-lesson">
                        {this.props.lastLesson._id ? (
                            <a
                                className="dashboard-practice-next-lesson-link"
                                href={`/dashboard/lesson/${this.props.lastLesson._id}`}
                            >
                                Продолжить прохождение курса
                            </a>
                        ) : null}
                        <a className="dashboard-practice-next-lesson-link" href="/dashboard/practice/all">
                            Вернуться к выбору практического урока
                        </a>
                    </div>
                );
            }
        }

        return (
            <DocumentTitle title={this.state.practice ? `${this.state.practice.name}` : `Практика`}>
                <div>
                    {forbiddenContainer}
                    {notFoundContainer}
                    {practiceName}
                    <div className="dashboard-practice-container">{practice}</div>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    lastLesson: state.user.user.lastLesson,
    nickname: state.user.user.nickname,
    isTimerVisible: state.ui.isTimerVisible,
});

const mapDispatchToProps = (dispatch) => ({
    getUser: () => dispatch(getUser()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Practice)
);
