import React from "react";

export const capitalize = text => {
    if (text && text.length === 0) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const bold = text => (
    <p>
        <strong>{text}</strong>
    </p>
);

export const italics = text => (
    <p>
        <i>{text}</i>
    </p>
);

// centered bold large text
export const header = (text, fontSize, fontWeight) => (
    <div style={{ textAlign: "center", fontSize: fontSize, fontWeight: fontWeight, marginBottom: "2em" }}>{text}</div>
);

// bold name and standard value
export const property = (name, value) => (
    <p>
        <b>{name}: </b>
        {value}
    </p>
);

// format seconds into minutes and seconds
export const formatSeconds = time => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    let result = "";
    if (minutes === 0) {
        result = `${seconds} сек`;
    } else {
        result = `${minutes} мин ${seconds} сек`;
    }
    return result;
};
