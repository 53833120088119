// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
import { GROUP_ELEMENT_CLASSNAME, GROUP_SLOT_CLASSNAME } from "assets/interactiveQuestions";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

const StyledInteractiveContainter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
`;

const StyledGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const StyledGroup = styled.div`
  flex: 1 0 30%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: rgb(249, 251, 252);
  border: 1px solid #eaeaea;
  border-radius: 4px;

  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    min-height: 160px;
    background-color: #ffffff;
    border: 1px dashed #eaeaea;
    border-radius: 4px;
    cursor: pointer;

    &.active {
      border-color: #0073e6;
    }

    & > div:first-child {
      text-align: center;
      color: #888888;
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
    }
  }
`;

const StyledElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border: 1px solid #eaeaea;
  border-radius: 4px;

  & > div:first-child {
    text-align: center;
    color: #888888;
  }

  & > div:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
`;

const StyledElement = styled.div`
  padding: 8px 12px;
  background-color: ${props => props.isSelected ? '#0073e6' : 'rgb(249, 251, 252)'};
  border: 1px solid #eaeaea;
  border-radius: 4px;
  color: ${props => props.isSelected ? '#ffffff' : '#000000'};
  cursor: pointer;

  &.correct {
    background-color: #338e6b;
    color: #fff;
  }

  &.incorrect {
    background-color: #ea4643;
    color: #fff;
  }
`;

const Element = ({ blockId, wasAnswered, isSelected, element, index, groupIndex, onClick }) => {
  return (
    <StyledElement
      className={GROUP_ELEMENT_CLASSNAME}
      data-blockid={blockId}
      data-index={index}
      data-groupindex={groupIndex}
      wasAnswered={wasAnswered}
      isSelected={isSelected}
      onClick={onClick}
    >
      {ReactHtmlParser(element)}
    </StyledElement>
  )
};

const Group = ({ blockId, wasAnswered, elements, name, index: groupIndex, onElementClick, onGroupClick }) => {
  return (
    <StyledGroup >
      <div>{name}</div>
      <div className={GROUP_SLOT_CLASSNAME} data-blockid={blockId} data-groupindex={groupIndex} onClick={() => onGroupClick(groupIndex)}>
        {/* <div>нажмите сюда, чтобы перенести элемент</div> */}
        <div>
          {elements.map((element, index) => (
            <Element
              blockId={blockId}
              wasAnswered={wasAnswered}
              isSelected={false}
              element={element}
              index={index}
              groupIndex={groupIndex}
              onClick={onElementClick}
            />
          ))}
        </div>
      </div>
    </StyledGroup>
  );
};

const InteractiveContainer = React.memo(({ blockId, wasAnswered, selectedElementIndex, groups = [], elements = [], userAnswers = [], onElementClick, onGroupClick, props }) => {
  const displayedElements = wasAnswered ? [] : elements;
  const { t } = props;
  return (
    <StyledInteractiveContainter>
      <StyledGroupsContainer>
        {groups.map((group, index) => (
          <Group
            blockId={blockId}
            wasAnswered={wasAnswered}
            elements={userAnswers[index]}
            name={group.name}
            index={index}
            onElementClick={onElementClick}
            onGroupClick={onGroupClick}
          />
        ))}
      </StyledGroupsContainer>

      <StyledElementsContainer>
        <div>{t("dashboard.interactive.moveYourAnswer")}</div>
        <div>{displayedElements.map((element, index) => (
          <Element
            blockId={blockId}
            wasAnswered={wasAnswered}
            isSelected={selectedElementIndex === index}
            element={element}
            index={index}
            groupIndex={-1}
            onClick={onElementClick}
          />
        ))}</div>
      </StyledElementsContainer>
    </StyledInteractiveContainter>
  );
});

class InteractiveQuestion3 extends Component {
  constructor(props) {
    super(props);
    const { block } = props;

    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const groups = interactive.groups || [];
    const elements = groups.reduce((accumulator, group) => [...accumulator, ...group.slots], []).sort(() => Math.random() - 0.5);
    const userAnswers = block.answer ? JSON.parse(block.answer) : Array(groups.length).fill([]);

    this.state = {
      groups,
      elements,
      userAnswers,
      answered: !!block.answer,
      selectedElementIndex: -1,
      correct: block.correct,
      elapsed: block.time || 0,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    } else {
      // color user answers
      const { groups } = this.state;
      const { block } = this.props;

      const htmlGroupElements = document.querySelectorAll(`div.${GROUP_ELEMENT_CLASSNAME}[data-blockid='${block._id}']`) || [];
      [...htmlGroupElements].forEach((element) => {
        const groupIndex = parseInt(element.dataset.groupindex, 10);
        const elementName = element.innerHTML;
        const isElementCorrect = Boolean(groups[groupIndex].slots.includes(elementName));

        element.classList.add(isElementCorrect ? "correct" : "incorrect");
      });
    }

    // add reset click listener
    document.addEventListener('click', this.handleEmptyPlaceClick);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);

    // remove reset click listener
    document.removeEventListener('click', this.handleEmptyPlaceClick);
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { elements, groups, userAnswers, elapsed } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;
    const { t } = this.props;

    if (elements.length !== 0) {
      alert(t("dashboard.course.lesson.question.dragAllAnswers"));
      return;
    }

    // convert user answers array into a string
    const stringifiedAnswer = JSON.stringify(userAnswers);

    // stop timer
    if (this.timer) clearInterval(this.timer);

    let isCorrect = true;
    let areGroupsBalanced = true;
    // compare group balancing, i.e. if each user answer group has the same number of elements as correct group
    for (let i = 0; i < userAnswers.length; i++) {
      if (userAnswers[i].length !== groups[i].slots.length) {
        isCorrect = false;
        areGroupsBalanced = false;
        break;
      }
    }

    // handle coloring of user answers
    const htmlGroupElements = document.querySelectorAll(`div.${GROUP_ELEMENT_CLASSNAME}[data-blockid='${blockId}']`) || [];
    [...htmlGroupElements].forEach((element) => {
      const groupIndex = parseInt(element.dataset.groupindex, 10);
      const elementName = element.innerHTML;
      const isElementCorrect = Boolean(groups[groupIndex].slots.includes(elementName));

      const correspondingClassName = areGroupsBalanced ? (isElementCorrect ? "correct" : "incorrect") : "incorrect";
      element.classList.add(correspondingClassName);

      if (!isElementCorrect) isCorrect = false;
    });
    
    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    //   userAnswers,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
        userAnswers,
      });
    });
  };

  onReanswerClick = () => {
    // handle de-coloring of user answers
    const htmlGroupElements = document.querySelectorAll(`div.${GROUP_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    const groupElements = [...htmlGroupElements];

    groupElements.forEach((element) => {
        element.classList.remove("correct");
        element.classList.remove("incorrect");
    });

    this.setState(prevState => ({
      elements: prevState.groups.reduce((accumulator, group) => [...accumulator, ...group.slots], []).sort(() => Math.random() - 0.5),
      userAnswers: Array(prevState.groups.length).fill([]),
      answered: false,
      correct: undefined,
      elapsed: 0,
      hasReanswered: true
    }), () => {
      // start the timer again
      this.startTimer();
    });
  }

  handleAddToGroup = (groupIndex, index) => {
    const updatedUserAnswers = [...this.state.userAnswers];
    updatedUserAnswers[groupIndex] = [...updatedUserAnswers[groupIndex], this.state.elements[index]];

    this.setState({ userAnswers: updatedUserAnswers });
  }

  handleRemoveFromGroup = (groupIndex, index) => {
    const element = this.state.userAnswers[groupIndex][index];
    const updatedElements = [...this.state.elements, element];

    const updatedUserAnswers = [...this.state.userAnswers];
    updatedUserAnswers[groupIndex].splice(index, 1);

    this.setState({ elements: updatedElements, userAnswers: updatedUserAnswers });
  }

  handleRemoveElement = (index) => {
    const updatedElements = [...this.state.elements];
    updatedElements.splice(index, 1);

    this.setState({ elements: updatedElements });
  }

  handleEmptyPlaceClick = (event) => {
    if (!event.target.classList.contains(GROUP_ELEMENT_CLASSNAME) && !event.target.classList.contains(GROUP_SLOT_CLASSNAME) && this.state.selectedElementIndex !== -1) {
      const groupHtmlElements = document.querySelectorAll(`div.${GROUP_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...groupHtmlElements].forEach((element) => {
        element.classList.remove('active');
      });

      this.setState({ selectedElementIndex: -1 });
    }
  }

  handleGroupClick = (groupIndex) => {
    if (this.state.answered || this.state.selectedElementIndex === -1) return;

    this.handleAddToGroup(groupIndex, this.state.selectedElementIndex);
    this.handleRemoveElement(this.state.selectedElementIndex);
    this.setState({ selectedElementIndex: -1 });
    const groupHtmlElements = document.querySelectorAll(`div.${GROUP_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...groupHtmlElements].forEach((element) => {
        element.classList.remove('active');
      });
  }

  handleElementClick = (event) => {
    if (this.state.answered || this.state.selectedElementIndex !== -1) return;
    
    const index = parseInt(event.target.dataset.index, 10);
    const groupIndex = parseInt(event.target.dataset.groupindex, 10);

    if (groupIndex === -1) {
      const groupHtmlElements = document.querySelectorAll(`div.${GROUP_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...groupHtmlElements].forEach((element) => {
        element.classList.add('active');
      });

      this.setState({ selectedElementIndex: index });
    } else {
      this.handleRemoveFromGroup(groupIndex, index);
    }
  }

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>
              <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
            </div>
          </div>
          <InteractiveContainer
            blockId={this.props.block._id}
            wasAnswered={this.state.answered}
            selectedElementIndex={this.state.selectedElementIndex}
            groups={this.state.groups}
            elements={this.state.elements}
            userAnswers={this.state.userAnswers}
            onElementClick={this.handleElementClick}
            onGroupClick={this.handleGroupClick}
            props={this.props}
          />
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion3));
