// modules
import React, { Component, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
// components
import { Button, Container, Header, Icon, Image, Modal } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { SketchPicker } from 'react-color';
// assets
import { adminRoutes } from 'assets/routes';

const StyledStyleContainer = styled(Container)`
  margin-bottom: 16px;
`;

const StyledDropzone = styled.div`
  padding: 8px;
  width: fit-content;
  border: 1px solid #000;
  border-radius: 4px;
  cursor: pointer;

  p {
    text-decoration: underline;
  }
`;

const StyledImage = styled(Image)`
  margin-bottom: 4px;
`;

const ColorPicker = ({ initialColor, onContentBackgroundColorChangeComplete }) => {
  const [color, setColor] = useState(null);

  const onChange = (color, _) => setColor(color.hex);
  const onChangeComplete = (color, _) => onContentBackgroundColorChangeComplete(color.hex);

  return (
    <SketchPicker
      color={color || initialColor}
      onChange={onChange}
      onChangeComplete={onChangeComplete}
    />
  );
};

class CourseStyle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      courseStyle: {
        leftPanelBackground: {
          file: null,
          image: ''
        },
        rightPanelBackground: {
          file: null,
          image: ''
        },
        sidebarBackground: {
          file: null,
          image: ''
        },
        contentBackgroundColor: '#ffffff'
      },
      temporaryColor: '#ffffff'
    };
  }

  componentDidMount() {
    const { courseStyle = {} } = this.props;
    const {
      leftPanelBackground = '',
      rightPanelBackground = '',
      sidebarBackground = '',
      contentBackgroundColor = '#ffffff'
    } = courseStyle;

    this.setState({
      courseStyle: {
        leftPanelBackground: {
          file: null,
          image: leftPanelBackground
        },
        rightPanelBackground: {
          file: null,
          image: rightPanelBackground
        },
        sidebarBackground: {
          file: null,
          image: sidebarBackground
        },
        contentBackgroundColor: contentBackgroundColor
      }
    });
  }

  onContentBackgroundColorChangeComplete = color => {
    this.setState(prevState => ({
      courseStyle: {
        ...prevState.courseStyle,
        contentBackgroundColor: color
      }
    }));
  };

  handleImageSelect = (type, files) => {
    this.setState(prevState => ({
      courseStyle: {
        ...prevState.courseStyle,
        [type]: {
          file: files[0],
          image: URL.createObjectURL(files[0])
        }
      }
    }));
  };

  uploadImagePromise = (file, type) =>
    new Promise((resolve, reject) => {
      // create a new form data
      const data = new FormData();
      // append file
      data.append('file', file);
      // get file extension
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      // append filename
      data.append('filename', `${type}.${extension}`);

      // axios call
      axios
        .post(adminRoutes.upload.uploadCourseStyle(this.props.courseId), data)
        .then(response => {
          this.setState(
            prevState => ({
              courseStyle: {
                ...prevState.courseStyle,
                [type]: {
                  file: null,
                  image: response.data.message.link
                }
              }
            }),
            () => {
              resolve('Uploaded image:', type);
            }
          );
        })
        .catch(error => {
          this.setState({ uploading: false });
          console.error('Error uploading image:', error);
          reject('Error uploading image:', type);
        });
    });

  saveCourseStyle = () => {
    // validate if course ID is passed
    if (!this.props.courseId) {
      console.error('No course ID provided');
      alert('Произошла ошибка, попробуйте еще раз');
      return;
    }

    // extract course style object
    const { courseStyle } = this.state;
    // see which images were added and add to promises array
    const uploadPromises = [];
    Object.keys(courseStyle).forEach(key => {
      if (courseStyle[key].file) {
        uploadPromises.push({
          type: key,
          promise: () => this.uploadImagePromise(courseStyle[key].file, key)
        });
      }
    });

    if (uploadPromises.length > 0) {
      // set uploading to true if something needs to be uploaded
      this.setState({ uploading: true });
    }

    // wait until all background images are uploaded
    Promise.all(uploadPromises.map(item => item.promise()))
      .then(() => {
        // set uploading state to false
        this.setState({ uploading: false });
        // create style object
        const { courseStyle } = this.state;
        const styleObject = {
          leftPanelBackground: courseStyle.leftPanelBackground.image,
          rightPanelBackground: courseStyle.rightPanelBackground.image,
          sidebarBackground: courseStyle.sidebarBackground.image,
          contentBackgroundColor: courseStyle.contentBackgroundColor
        };
        // send request to edit course style
        axios.post(adminRoutes.courses.editCourseStyle(this.props.courseId), { courseStyle: styleObject }).then(() => {
          this.props.onClose();
          this.props.fetchCourse(this.props.courseId);
        });
      })
      .catch(err => {
        console.error('Error saving course style:', err);
      });
  };

  render() {
    const { courseStyle, uploading } = this.state;
    const { onClose } = this.props;

    return (
      <Modal closeIcon closeOnDimmerClick open onClose={onClose} size="large">
        <Header icon="pencil alternate" content="Изменение стиля учебника" />

        <Modal.Content>
          <StyledStyleContainer>
            <Header size="medium">Фон для левой панели</Header>
            <Dropzone multiple={false} onDrop={acceptedFiles => this.handleImageSelect('leftPanelBackground', acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <StyledDropzone>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <StyledImage src={courseStyle.leftPanelBackground.image || ''} size="medium" />
                    <p>Перенесите изображение либо нажмите сюда</p>
                  </div>
                </StyledDropzone>
              )}
            </Dropzone>
          </StyledStyleContainer>

          <StyledStyleContainer>
            <Header size="medium">Фон для правой панели</Header>
            <Dropzone multiple={false} onDrop={acceptedFiles => this.handleImageSelect('rightPanelBackground', acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <StyledDropzone>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <StyledImage src={courseStyle.rightPanelBackground.image || ''} size="medium" />
                    <p>Перенесите изображение либо нажмите сюда</p>
                  </div>
                </StyledDropzone>
              )}
            </Dropzone>
          </StyledStyleContainer>

          <StyledStyleContainer>
            <Header size="medium">Фон для бокового меню</Header>
            <Dropzone multiple={false} onDrop={acceptedFiles => this.handleImageSelect('sidebarBackground', acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <StyledDropzone>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <StyledImage src={courseStyle.sidebarBackground.image || ''} size="medium" />
                    <p>Перенесите изображение либо нажмите сюда</p>
                  </div>
                </StyledDropzone>
              )}
            </Dropzone>
          </StyledStyleContainer>

          <StyledStyleContainer>
            <Header size="medium">Фоновый цвет основного контента</Header>
            <ColorPicker 
              initialColor={courseStyle.contentBackgroundColor || '#ffffff'}
              onContentBackgroundColorChangeComplete={this.onContentBackgroundColorChangeComplete}
            />
          </StyledStyleContainer>
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={onClose}>
            <Icon name="remove" /> Отменить
          </Button>
          <Button positive loading={uploading} onClick={this.saveCourseStyle}>
            <Icon name="checkmark" /> Сохранить
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CourseStyle;
