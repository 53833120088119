// modules
import React, { Component } from 'react';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Checkbox, Form, TextArea } from 'semantic-ui-react';
// components
import AlternativeEditor from 'components/AlternativeEditor';
// redux

class InteractiveBlock extends Component {
  render() {
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold('Текст в блоке:')}
          <AlternativeEditor editorType="full" data={this.props.block.text || ''} onChange={this.props.onTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}
          <Checkbox
            label="Подсветить блок?"
            toggle
            name="highlighted"
            checked={this.props.block.highlighted || false}
            onChange={this.props.onChange}
            style={{ fontWeight: 400 }}
          />
        </div>

        <div>
          {bold('Тексты в всплывающих блоках:')}
          <Form>
            <TextArea
              placeholder="Текст первого пункта"
              name="0"
              value={this.props.block.popupTextList[0]}
              onChange={this.props.onPopupTextChange}
              style={{ marginBottom: '16px', minHeight: 100 }}
            />
            <TextArea
              placeholder="Текст второго пункта"
              name="1"
              value={this.props.block.popupTextList[1]}
              onChange={this.props.onPopupTextChange}
              style={{ marginBottom: '16px', minHeight: 100 }}
            />
            <TextArea
              placeholder="Текст третьего пункта"
              name="2"
              value={this.props.block.popupTextList[2]}
              onChange={this.props.onPopupTextChange}
              style={{ marginBottom: '16px', minHeight: 100 }}
            />
            <TextArea
              placeholder="Текст четвертого пункта"
              name="3"
              value={this.props.block.popupTextList[3]}
              onChange={this.props.onPopupTextChange}
              style={{ marginBottom: '16px', minHeight: 100 }}
            />
            <TextArea
              placeholder="Текст пятого пункта"
              name="4"
              value={this.props.block.popupTextList[4]}
              onChange={this.props.onPopupTextChange}
              style={{ marginBottom: '16px', minHeight: 100 }}
            />
          </Form>
        </div>
      </div>
    );
  }
}

export default InteractiveBlock;
