// modules
import React, {useState,useEffect,useRef} from 'react';
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import './Instructions.css';
import {useColor} from '../Utils/ColorContext';

function Instructions({t}) {
    const {color} = useColor();
    const titleStyle = {
        color: color === '#3C3C56' ? 'white' : '#7B6CEA',
    }
    return (
        <div className="instructions">
            <h1 style={titleStyle}>{t('landing.header.manual')}</h1>
            {ReactHtmlParser(t('landing.manual.content'))}
        </div>
    );
}
export default withTranslation()(Instructions);