// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { adminRoutes } from 'assets/routes';
import { compareUsers } from 'assets/utils';
import { redirect } from 'utils';
// styles
import { Container, Header, List, Loader, Button } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from 'store/User';

class Teachers extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.fetchTeachers();
  }

  fetchTeachers = () => {
    this.setState({ loading: true });

    axios
      .get(adminRoutes.teachers.getTeachers())
      .then(response => {
        this.setState({ loading: false, teachers: response.data.message });
      })
      .catch(error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  render() {
    let teachers = [];
    let teacherItems = [];
    let teachersNumber = 0;

    if (this.state.teachers && this.state.teachers.length !== 0) {
      // copy teachers
      teachers = [...this.state.teachers];
      // count the number of teachers
      teachersNumber = teachers.length;
      // sort teachers
      teachers.sort(compareUsers);

      // add teachers to list
      for (let i = 0; i < teachers.length; i++) {
        const teacher = teachers[i];

        teacherItems.push(
          <List.Item key={'admin-teachers-' + i}>
            <List.Icon name="user" />
            <List.Content>
              <List.Header
                style={{
                  color: '#3697dd',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px'
                }}
                onClick={() => redirect(this, `/admin/teachers/${teacher.email}`)}
              >
                {`${teacher.lastName} ${teacher.firstName}`}
              </List.Header>
              <List.Description>{teacher.email}</List.Description>
            </List.Content>
          </List.Item>
        );
      }
    }

    return (
      <DocumentTitle title="Управление учителями">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              Управление учителями
            </Header>

            <Button className="admin-teachers-new-teacher" onClick={() => redirect(this, '/admin/teachers/new')}>
              Создать нового учителя
            </Button>

            <div className="admin-teachers-subheader">Учителя: {teachersNumber}</div>
            <List divided relaxed="very">
              {teacherItems}
            </List>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
