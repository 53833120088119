// modules
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
// import robot from "assets/images/robot.jpg";
// styles
// components
import Xarrow from 'react-xarrows';
// redux
import { getUser } from 'store/User';

const StyledCenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  text-align: right;

  .text { 
    margin-bottom: 16px;
  }

  .link {
    margin-bottom: 24px;
    cursor: pointer;
    color: rgb(40, 130, 210);
  }

  .link:hover {
    text-decoration: underline;
  }

  .img {
    width: 320px;
  }

  ${media.lessThan('small')`
    font-size: 16px;
  `}

  ${media.between('small', 'medium')`
    font-size: 18px;
  `}

  ${media.between('medium', 'large')`
    font-size: 22px;
  `}

  ${media.greaterThan('large')`
    font-size: 24px;
  `}
`;

const StyledHintBox = styled.div`
  background-color: rgb(163, 215, 248);
  border: 1px solid black;
  border-radius: 12px;
  line-height: 1.2;
  font-weight: 500;

  ${media.lessThan('medium')`
    display: none;
  `}

  ${media.between('medium', 'large')`
    padding: 12px;
    font-size: 16px;
  `}

  ${media.greaterThan('large')`
    padding: 12px 16px;
    font-size: 18px;
  `}
`;

const StyledArrowWrapper = styled.div`
  ${media.lessThan('medium')`
    display: none;
  `}
`;

class Visualization extends Component {
  render() {
    const { currentCourse, location, t, user } = this.props;

    let lastLessonId = '';
    let link = '';

    if (this.props.user) {
      // search for course last lesson
      const userCourse = this.props.user.courses.find(x => x._id === this.props.courseId);

      if (userCourse) {
        lastLessonId = userCourse.lastLesson._id;
      } else {
        let lesson = this.props.lessons.find(x => !x.hidden);
        lastLessonId = lesson ? lesson._id : '';
      }

      const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
      link = `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${this.props.courseId}/lesson/${lastLessonId}`;
    }

    // if (this.props.currentCourse) {
    //   const formulas = currentCourse
    // }

    return (
      <DocumentTitle title={t('dashboard.course.visualization.header')}>
        <div>
          <StyledCenteredContainer>
            <div className="text">{t('dashboard.course.visualization.text') + this.props.user.firstName + '!'}</div>
            <div className="link" onClick={() => (window.location = link)}>
              {t('dashboard.course.visualization.continue')}
            </div>
            {user.scope === 'expert' && (
              <div
                className="link"
                onClick={() => {
                  const { location } = this.props;
                  const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
                  window.location = `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${this.props.courseId}/settings`;
                }}
                >
                {t('dashboard.course.visualization.settings')}
              </div>
            )}
          </StyledCenteredContainer>

          <StyledHintBox id="instruction-square" style={{ position: 'fixed', left: '128px', top: '128px' }}>
            {t('landing.header.manual')}
          </StyledHintBox>

          {currentCourse && (
          <Fragment>
            {currentCourse.formulas ? (
              <Fragment>
                <StyledHintBox id="formulas-square" style={{ position: 'fixed', left: '128px', top: '208px' }}>
                  {t('dashboard.course.visualization.hint.formulas')}
                </StyledHintBox>
                {currentCourse.extraMaterial ? (
                  <Fragment>
                    <StyledHintBox id="extraMaterial-square" style={{ position: 'fixed', left: '128px', top: '288px' }}>
                      {t('dashboard.course.visualization.hint.extraMaterial')}
                    </StyledHintBox>
                    <StyledHintBox id="print-square" style={{ position: 'fixed', left: '128px', top: '368px' }}>
                      {t('dashboard.course.visualization.hint.print')}
                    </StyledHintBox>
                    <StyledHintBox id="palette-square" style={{ position: 'fixed', left: '128px', top: '448px' }}>
                      {t('dashboard.course.visualization.hint.palette')}
                    </StyledHintBox>
                  </Fragment>
                ) : (
                  <Fragment>
                    <StyledHintBox id="print-square" style={{ position: 'fixed', left: '128px', top: '288px' }}>
                      {t('dashboard.course.visualization.hint.print')}
                    </StyledHintBox>
                    <StyledHintBox id="palette-square" style={{ position: 'fixed', left: '128px', top: '368px' }}>
                      {t('dashboard.course.visualization.hint.palette')}
                    </StyledHintBox>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {currentCourse.extraMaterial ? (
                  <Fragment>
                    <StyledHintBox id="extraMaterial-square" style={{ position: 'fixed', left: '128px', top: '208px' }}>
                      {t('dashboard.course.visualization.hint.extraMaterial')}
                    </StyledHintBox>
                    <StyledHintBox id="print-square" style={{ position: 'fixed', left: '128px', top: '288px' }}>
                      {t('dashboard.course.visualization.hint.print')}
                    </StyledHintBox>
                    <StyledHintBox id="palette-square" style={{ position: 'fixed', left: '128px', top: '368px' }}>
                      {t('dashboard.course.visualization.hint.palette')}
                    </StyledHintBox>
                  </Fragment>
                ) : (
                  <Fragment>
                    <StyledHintBox id="print-square" style={{ position: 'fixed', left: '128px', top: '208px' }}>
                      {t('dashboard.course.visualization.hint.print')}
                    </StyledHintBox>
                    <StyledHintBox id="palette-square" style={{ position: 'fixed', left: '128px', top: '288px' }}>
                      {t('dashboard.course.visualization.hint.palette')}
                    </StyledHintBox>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
          )}


          {/* <StyledHintBox id="search-square" style={{ position: 'fixed', left: '128px', top: '288px' }}>
            {t('dashboard.course.visualization.hint.search')}
          </StyledHintBox> */}
          {/* <StyledHintBox id="timer-square" style={{ position: 'fixed', left: '128px', top: '368px' }}>
            {t('dashboard.course.visualization.hint.timer')}
          </StyledHintBox> */}

          <StyledHintBox id="top-square" style={{ position: 'fixed', left: '128px', bottom: '112px' }}>
            {t('dashboard.course.visualization.hint.top')}
          </StyledHintBox>
          <StyledHintBox id="bottom-square" style={{ position: 'fixed', left: '128px', bottom: '32px' }}>
            {t('dashboard.course.visualization.hint.bottom')}
          </StyledHintBox>
          <StyledHintBox id="menu-square" style={{ position: 'fixed', right: '120px', top: '128px' }}>
            {t('dashboard.course.visualization.hint.menu')}
          </StyledHintBox>

          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="formulas-square" end="formulas-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="extraMaterial-square" end="extraMaterial-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="instruction-square" end="instruction-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="search-square" end="search-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          {/* <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="timer-square" end="timer-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper> */}
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="print-square" end="print-button"  style={{ position: 'fixed '}}/>
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="palette-square" end="palette-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="top-square" end="top-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="bottom-square" end="bottom-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="menu-square" end="menu-navigation-button" style={{ position: 'fixed '}} />
          </StyledArrowWrapper>

        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  currentCourse: state.ui.currentCourse,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUser())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Visualization)));
