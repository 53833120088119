// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep"
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import WordSearch from '@blex41/word-search';
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Icon, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
  padding-bottom: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  padding: 12px;
  width: 100%;
  background-color: #cf8d15;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledHeaderTime = styled.div`
  font-weight: bold;
`;

const StyledHeaderTries = styled.div`
  display: flex;
  align-items: center;

  & > span:last-child {
    font-size: 20px;
  }
`;

const StyledStatus = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.titleStatus === 'default' ? '#000' : props.titleStatus === 'correct' ? 'green' : 'red'};
`;

const StyledMatrix = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
`;

const StyledMatrixRow = styled.div`
  display: flex;
  column-gap: 4px;
`;

const StyledMatrixCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${props => props.isCorrect === true ? '#2b88db' : 'rgba(100, 100, 100)'};
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  visibility: ${props => props.isCorrect === false ? 'hidden' : 'visible'};
`;

const ALPHABET = 'АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҰҮФХҺЦЧШЩЪЫІЬЭЮЯ';
const TITLE_STATUSES_MAP = {
  default: 'dashboard.course.lesson.question.findHiddenWord',
  correct: 'dashboard.course.lesson.question.correct',
  incorrect: 'dashboard.course.lesson.question.incorrect',
};

class InteractiveQuestion8 extends Component {
  constructor(props) {
    super(props);
    const { block } = this.props;

    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const words = interactive.words ? interactive.words.map(({ word }) => word.toUpperCase()) : [];
    const totalTime = interactive.time || 300;
    const totalTries = interactive.tries || 5;
    const userAnswers = block.answer ? JSON.parse(block.answer) : {
      usedTries: 0,
      correctGuesses: 0,
      timeLeft: totalTime,
      words: words.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}),
    };
    const titleStatus = !!block.answer ? block.correct ? 'correct' : 'incorrect' : 'default';

    const maxWordLength = Math.max(...words.map((word) => word.length));
    const options = {
        cols: maxWordLength,
        rows: maxWordLength,
        disabledDirections: ['NE', 'NW', 'SE', 'SW'],
        dictionary: words.map((word) => word),
        maxWords: 20,
        backwardsProbability: 0,
        diacritics: true,
    };
    const wordSearch = new WordSearch(options);
    const taskGrid = cloneDeep(wordSearch.grid);
    const taskWords = cloneDeep(wordSearch.words.map((word) => ({ ...word, guessed: false })));
    const correctLetterCoordinates = {};
    // generate correct coordinates
    for (let i = 0; i < taskWords.length; i++) {
      for (let j = 0; j < taskWords[i].path.length; j++) {
        const key = `x:${taskWords[i].path[j].x};y:${taskWords[i].path[j].y}`;
        correctLetterCoordinates[key] = i;
      }
    }
    // generate grid with replacing placeholder latin letters with random letters from alphabet
    for (let i = 0; i < maxWordLength; i++) {
        for (let j = 0; j < maxWordLength; j++) {
            const currentCoordinate = `x:${j};y:${i}`;
            const index = Math.floor(Math.random() * ALPHABET.length) % ALPHABET.length;
            const isCorrectLetter = currentCoordinate in correctLetterCoordinates;
            const value = isCorrectLetter ? taskGrid[i][j] : ALPHABET[index];
            taskGrid[i][j] = { letter: value, correct: !!block.answer && isCorrectLetter ? true : undefined };
        }
    }

    this.state = {
      answered: !!block.answer,
      correct: block.correct,
      elapsed: block.time || 0,
      words,
      userAnswers,
      totalTime,
      totalTries,
      taskGrid,
      taskWords,
      correctLetterCoordinates,
      titleStatus,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
    if (this.backwardsTimer) clearInterval(this.backwardsTimer);
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  startBackwardsTimer = () => {
    this.start = new Date();
    this.backwardsTimer = setInterval(this.backwardsTick, 1000);
  }

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
      if (this.backwardsTimer) clearInterval(this.backwardsTimer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();

      if (this.backwardsTimer) clearInterval(this.backwardsTimer);
      this.startBackwardsTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  backwardsTick = () => {
    this.setState(prevState => ({
      userAnswers: {
        ...prevState.userAnswers,
        timeLeft: prevState.userAnswers.timeLeft - 1,
      },
    }), () => {
      if (this.state.userAnswers.timeLeft === 0) this.handleAnswer(false);
    });
  }

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = (isCorrect = true) => {
    const { elapsed, userAnswers } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    if (isCorrect && !Object.values(userAnswers.words).every((value) => value)) {
      alert('Найдите все слова!');
      return;
    }

    // stop timers
    if (this.timer) clearInterval(this.timer);
    if (this.backwardsTimer) clearInterval(this.backwardsTimer);
    if (this.titleStatusTimeout) clearTimeout(this.titleStatusTimeout);

    // convert user answers array into a string
    const stringifiedAnswer = JSON.stringify(userAnswers);

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    //   titleStatus: isCorrect ? 'correct' : 'incorrect',
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
        titleStatus: isCorrect ? 'correct' : 'incorrect',
      });
    });
  };

  onReanswerClick = () => {
    const updatedTaskGrid = cloneDeep(this.state.taskGrid);
    const size = updatedTaskGrid.length;
    // re-generate grid
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
          const currentCoordinate = `x:${j};y:${i}`;
          const index = Math.floor(Math.random() * ALPHABET.length) % ALPHABET.length;
          const value = currentCoordinate in this.state.correctLetterCoordinates ? updatedTaskGrid[i][j].letter : ALPHABET[index];
          updatedTaskGrid[i][j] = { letter: value, correct: undefined };
      }
    }

    this.setState(prevState => ({
      answered: false,
      userAnswers: {
        usedTries: 0,
        correctGuesses: 0,
        timeLeft: prevState.totalTime,
        words: prevState.taskWords.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}),
      },
      taskGrid: updatedTaskGrid,
      titleStatus: 'default',
      correct: undefined,
      elapsed: 0,
      hasReanswered: true,
    }), () => {
      // start the timers again
      this.startTimer();
      this.startBackwardsTimer();
    });
  };

  handleClickLetter = (x, y) => {
    if (this.state.answered || this.state.taskGrid[y][x].correct !== undefined) {
      return;
    }

    const coordinates = `x:${x};y:${y}`;
    if (coordinates in this.state.correctLetterCoordinates) {
      const wordIndex = this.state.correctLetterCoordinates[coordinates];
      const word = this.state.taskWords[wordIndex];
      const updatedTaskGrid = [...this.state.taskGrid];
      for (let i = 0; i < word.path.length; i++) {
        updatedTaskGrid[word.path[i].y][word.path[i].x].correct = true;
      }
      this.setState(prevState => ({
        userAnswers: {
          ...prevState.userAnswers,
          correctGuesses: prevState.userAnswers.correctGuesses + 1,
          words: {
            ...prevState.userAnswers.words,
            [wordIndex]: true,
          },
        },
        taskGrid: updatedTaskGrid,
        titleStatus: 'correct',
      }), () => {
        if (this.state.userAnswers.correctGuesses === this.state.taskWords.length) {
          this.handleAnswer();
        }
      });
    } else {
      const updatedTaskGrid = [...this.state.taskGrid];
      updatedTaskGrid[y][x].correct = false;
      this.setState(prevState => ({
        userAnswers: {
          ...prevState.userAnswers,
          usedTries: prevState.userAnswers.usedTries + 1,
        },
        taskGrid: updatedTaskGrid,
        titleStatus: 'incorrect',
      }), () => {
        if (this.state.userAnswers.usedTries === this.state.totalTries) {
          this.handleAnswer(false);
        }
      });
    }

    if (this.titleStatusTimeout) clearTimeout(this.titleStatusTimeout);
    this.titleStatusTimeout = setTimeout(() => {
      this.setState({
        titleStatus: 'default',
      });
    }, 3000);
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    const minutesLeft = Math.floor(this.state.userAnswers.timeLeft / 60);
    const secondsLeft = `${this.state.userAnswers.timeLeft % 60}`.padStart(2, '0');
    const timeLeft = `${minutesLeft}:${secondsLeft}`;

    const correctGuesses = this.state.userAnswers.correctGuesses;
    const totalQuestions = this.state.words.length;
    const title = this.state.titleStatus === 'default' ? `${t(TITLE_STATUSES_MAP[this.state.titleStatus])} (${correctGuesses} / ${totalQuestions})` : t(TITLE_STATUSES_MAP[this.state.titleStatus])

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>{ReactHtmlParser(this.props.block.text)}</div>
          </div>
          <StyledContainer>
            <StyledHeader>
              <StyledHeaderTime>{timeLeft}</StyledHeaderTime>
              <StyledHeaderTries>
                {Array(this.state.totalTries - this.state.userAnswers.usedTries).fill().map(() => <Icon name='heart' />)}
              </StyledHeaderTries>
            </StyledHeader>
            <StyledStatus titleStatus={this.state.titleStatus}>{title}</StyledStatus>
            <StyledMatrix>
              {this.state.taskGrid.map((row, rowIndex) => (
                  <StyledMatrixRow key={`matrix-row-${rowIndex}`}>
                      {row.map((col, colIndex) => (
                          <StyledMatrixCell
                            key={`matrix-cell-${rowIndex}-${colIndex}`}
                            isCorrect={col.correct}
                            onClick={() => this.handleClickLetter(colIndex, rowIndex)}
                          >
                              {col.letter}
                          </StyledMatrixCell>
                      ))}
                  </StyledMatrixRow>
              ))}
            </StyledMatrix>
          </StyledContainer>
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion8));
