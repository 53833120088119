// modules
import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactSortable } from 'react-sortablejs';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox, Container, Input, Message, TextArea } from 'semantic-ui-react';
// components
import ReactAudioPlayer from 'react-audio-player';
import AlternativeEditor from 'components/AlternativeEditor';
import SingleQuestion from './SingleQuestion';
import { AddSlotButton  } from '../InteractiveQuestionsCommon';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

const QuestionsSection = styled.div`
  margin-bottom: 2em;
  padding: 16px;
  background-color: rgba(250, 250, 250);
  border-radius: 4px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
`;

const AddQuestionButton = styled(AddSlotButton)`
  width: fit-content;
  margin-top: 2em;
  border: 1px solid #1075cc;
  color: #1075cc;
`;

class InteractiveOrderWords extends Component {
  constructor(props) {
    super(props);

    const { new: isNew, block } = this.props;
    const isExistingBlock = !isNew && block;
    const interactive = isExistingBlock ? JSON.parse(block.interactive || '""') : {};

    this.state = {
      block: isExistingBlock ? block : {
        text: '',
        audio: '',
        hint: '',
        recTime: '',
        correctText: '',
        wrongText: '',
        hintingText: '',
        testQuestion: false,
        highlighted: false,
      },
      interactive: {
        time: 300,
        tries: 5,
        questions: interactive.questions || [{ question: '' }],
      },
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.block && this.props.block.audio && prevProps.block && this.props.block.audio !== prevProps.block.audio) {
      this.setState(prevState => ({
        block: {
          ...prevState.block,
          audio: this.props.block.audio,
        },
      }));
    }
  }

  handleDeleteAudio = () => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'highlighted' || data.name === 'testQuestion') {
      result = data.checked;
    }

    this.setState(prevState => ({
      block: {
        ...prevState.block,
        [data.name]: result,
      }
    }));
  };

  handleQuestionsOrderChange = (newQuestions) => {
    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        questions: newQuestions,
      },
    }));
  };

  handleChangeQuestion = (e, data) => {
    const updatedQuestions = [...this.state.interactive.questions];
    const updatedQuestion = updatedQuestions[data.index];
    updatedQuestion[data.name] = data.value;

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        questions: updatedQuestions,
      },
    }));
  }

  handleDuplicateQuestion = (index) => {
    const updatedQuestions = [...this.state.interactive.questions];
    const question = updatedQuestions[index];
    updatedQuestions.splice(index, 0, question);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        questions: updatedQuestions,
      },
    }));
  }

  handleDeleteQuestion = (index) => {
    const updatedQuestions = [...this.state.interactive.questions];
    updatedQuestions.splice(index, 1);
    if (updatedQuestions.length === 0) {
      updatedQuestions.push({ question: '' });
    }

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        questions: updatedQuestions,
      },
    }));
  }

  handleAddNewQuestion = () => {
    const updatedQuestions = [...this.state.interactive.questions];
    updatedQuestions.push({ question: '' });

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        questions: updatedQuestions,
      },
    }));
  }

  handleAddBlock = () => {
    this.props.handleAdd(this.state.block, this.state.interactive);
  }

  handleEditBlock = () => {
    this.props.handleEdit(this.state.block, this.state.interactive);
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold('Здесь можете вписать текст для отображения перед самим заданием')}
          <AlternativeEditor editorType="full" data={this.state.block.text} onChange={this.handleTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Аудио-вопрос')}
          {this.state.block.audio ? (
            <div style={{ marginBottom: '2em' }}>
              <ReactAudioPlayer src={this.state.block.audio} controls />
              <br />
              <br />
              <Button negative onClick={this.handleDeleteAudio}>
                Удалить аудио вопрос
              </Button>
            </div>
          ) : null}
          <input type="file" onChange={this.props.handleAudioSelect} />
        </div>

        <QuestionsSection>
          <ReactSortable
            list={this.state.interactive.questions || []}
            setList={this.handleQuestionsOrderChange}
            animation={200}
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            {this.state.interactive.questions.map((question, index) => (
              <SingleQuestion
                key={`interactive-guess-question-item-${index}`}
                question={question}
                index={index}
                handleChange={this.handleChangeQuestion}
                handleDuplicate={this.handleDuplicateQuestion}
                handleDelete={this.handleDeleteQuestion}
              />
            ))}
          </ReactSortable>

          <AddQuestionButton onClick={this.handleAddNewQuestion}>
            Добавить новое предложение
          </AddQuestionButton>
        </QuestionsSection>

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}

          <Container>
            <Checkbox
              label="Подсветить блок?"
              toggle
              name="highlighted"
              checked={this.state.block.highlighted}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          <Container>
            <Checkbox
              label="Тестовый вопрос?"
              toggle
              name="testQuestion"
              checked={this.state.block.testQuestion}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
        </div>

        {bold('Рекоммендуемое время:')}
        <Input fluid name="recTime" type="number" value={this.state.block.recTime || ''} onChange={this.handleChange} />
        <Message
          info
          icon="stopwatch"
          header="Время решения вопроса"
          content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя"
        />

        <div>
          {bold('Подсказка:')}
          <TextArea
            autoHeight
            name="hint"
            value={this.state.block.hint}
            onChange={this.handleChange}
            style={{ width: '100%', minHeight: 100 }}
          />

          {bold('Текст при правильном ответе:')}
          <TextArea
            autoHeight
            name="correctText"
            value={this.state.block.correctText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />

          {this.state.block.testQuestion ? null : (
            <div>
              {bold('Текст-подсказка при первом неправильном ответе:')}
              <TextArea
                autoHeight
                name="hintingText"
                value={this.state.block.hintingText}
                onChange={this.handleChange}
                style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
              />
            </div>
          )}

          {bold('Текст при неправильном ответе:')}
          <TextArea
            autoHeight
            name="wrongText"
            value={this.state.block.wrongText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>

        {this.props.new ? (
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={this.handleAddBlock}
            style={addStyle}
          >
            Добавить блок
          </Button>
        ) : (
          <>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.props.editing}
              onClick={this.handleEditBlock}
              style={editStyle}
            >
              Редактировать блок
            </Button>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.props.deleting}
              onClick={this.props.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default InteractiveOrderWords;
