// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// import axios from "axios";
// assets
import { formatSeconds } from "assets/formatUtils";
import { dashboardRoutes } from "assets/routes";
import { scrollToElement } from "utils/scroll";
// styles
import { Loader, Modal, Table } from "semantic-ui-react";
import "./index.css";
// components
// redux

class LessonStatsModal extends Component {
    state = {
        loading: false,
        // betterThan: "",
        // loadingBetterThan: false,
    };

    componentDidMount() {
        // this.fetchBetterThan(this.props.courseId, this.props.lessonId);
        // this.fetchQuestionBlocks(this.props.courseId, this.props.lessonId);

        this.handleQuestionBlocks(this.props.blocks);
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.lessonId !== this.props.lessonId) {
        //     this.fetchBetterThan(this.props.courseId, this.props.lessonId);
        //     this.fetchQuestionBlocks(this.props.courseId, this.props.lessonId);
        // }

        if (prevProps.lessonId !== this.props.lessonId || prevProps.answerCount !== this.props.answerCount) {
            this.handleQuestionBlocks(this.props.blocks);
        }
    }

    handleQuestionBlocks = (blocks) => {
        const questionBlocks = [];

        if (Array.isArray(blocks) && blocks.length !== 0) {
            for (let i = 0; i < blocks.length; i++) {
                const block = blocks[i];

                if (!block.blockType.startsWith('question') && block.blockType !== 'indefiniteQuestion') {
                    continue;
                }

                if (block.blockType === 'indefiniteQuestion') {
                    block.correct = undefined;
                }

                questionBlocks.push({ ...block });
            }
        }

        this.setState((prevState) => ({ ...prevState, loading: false, questionBlocks }));
    }

    // fetchQuestionBlocks = async (courseId, lessonId) => {
    //     this.setState({ loading: true });
    //     await axios
    //         .get(dashboardRoutes.fetchLesson(courseId, lessonId))
    //         .then((response) => {
    //             const blocks = response.data.message.lesson.blocks || [];
    //             const userAnswers = response.data.message.userLesson.answers || [];
    //             const questionBlocks = [];
    //
    //             for (let i = 0; i < blocks.length; i++) {
    //                 const block = blocks[i];
    //
    //                 const foundUserAnswer = userAnswers.find((answer) => answer._id === block._id);
    //                 if (!!foundUserAnswer && block.blockType === "indefiniteQuestion") {
    //                     foundUserAnswer.correct = undefined;
    //                 }
    //                 if (!!foundUserAnswer) {
    //                     foundUserAnswer.testQuestion = !!block.testQuestion;
    //                 }
    //
    //                 questionBlocks.push({ ...foundUserAnswer });
    //             }
    //
    //             // set lesson
    //             this.setState((prevState) => ({ ...prevState, loading: false, questionBlocks }));
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    // fetchBetterThan = async (courseId, lessonId) => {
    //     this.setState({ loadingBetterThan: true });
    //     let response = await axios.get(dashboardRoutes.fetchBetterThan(courseId, lessonId)).catch((error) => {
    //         console.error("Error fetching results\n", error);
    //         this.setState({ loadingBetterThan: false });
    //     });
    //     if (response) {
    //         let betterThan = 0;
    //         let results = response.data.message.filter((x) => x.userId !== this.props.userId);
    //         let answers = results.length;
    //         if (answers > 0) {
    //             let moreThan = results.filter((x) => this.props.correct >= x.correct).length;
    //             betterThan = Math.floor((moreThan * 100) / answers);
    //         } else {
    //             betterThan = 100;
    //         }
    //
    //         // set "better than" results
    //         this.setState({ loadingBetterThan: false, betterThan: betterThan });
    //     }
    // };

    secondsToHms(d) {
        const { t } = this.props;

        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? h + (h == 1 ? t("dashboard.course.lesson.footerstats.hour") : t("dashboard.course.lesson.footerstats.hour")) : "";
        var mDisplay = m > 0 ? m + (m == 1 ? "м " : "м ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? "с" : "с") : "";
        return hDisplay + mDisplay + sDisplay; 
    }

    render() {
        const { open, t } = this.props;
        let smallTable = this.props.innerWidth < 800;
        let totalTime = 0;
        let tableRows = [];
        // sum up total time and get individual questions
        if (this.state.questionBlocks && this.state.questionBlocks.length !== 0) {
            for (let i = 0; i < this.state.questionBlocks.length; i++) {
                let question = this.state.questionBlocks[i];

                // skip indefinite answer questions
                if (question.correct === undefined) {
                    continue;
                }

                if (question.time) {
                    totalTime += question.time;
                }
                let questionResult;
                if (question.correct === undefined) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-indefinite">
                            {t("dashboard.course.lesson.footerstats.indefinite")}
                        </b>
                    );
                } else if (question.correct === true) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-correct">
                            {t("dashboard.course.lesson.footerstats.correct")}
                        </b>
                    );
                } else if (question.correct === false) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-wrong">
                            {t("dashboard.course.lesson.footerstats.wrong")}
                        </b>
                    );
                }
                let section = this.props.section;
                if (this.props.demo) {
                    section = "0";
                }
                tableRows.push(
                    <Table.Row key={`dashboard-lesson-footer-question-${i}`}>
                        <Table.Cell>
                            <b>{`${
                                smallTable ? t("dashboard.course.lesson.footerstats.indefinite") + ": " : ""
                            }${i + 1}`}</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b
                                onClick={() =>
                                    scrollToElement(`lesson-${this.props.lessonId}-question-${question._id}`, "linear")
                                }
                                style={{ cursor: "pointer", color: "#3c80c0" }}
                            >
                                <i>{t("dashboard.course.lesson.footerstats.task")}</i>
                            </b>
                        </Table.Cell>
                        <Table.Cell>
                            {smallTable ? t("dashboard.course.lesson.footerstats.result") + ": " : ""}
                            {questionResult}
                        </Table.Cell>
                        <Table.Cell>{`${
                            smallTable ? t("dashboard.course.lesson.footerstats.indefinite") + ": " : ""
                        }${formatSeconds(question.time)}`}</Table.Cell>
                    </Table.Row>
                );
            }
        }

        return (
            <Modal closeIcon open={open} onClose={this.props.onClose}>
                <div className="dashboard-lesson-footer-wrapper">
                    <div className="dashboard-lesson-footer-header" style={{ marginBottom: "2em", fontWeight: 500 }}>
                        {this.props.lessonName}
                    </div>

                    {/*{this.state.loadingBetterThan ? (*/}
                    {/*    <Loader active inline="centered" size="large" style={{ marginBottom: "24px" }} />*/}
                    {/*) : (*/}
                    {/*    <div className="dashboard-lesson-footer-header">*/}
                    {/*        {t("dashboard.course.lesson.footerstats.better")}: {this.state.betterThan}%*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="dashboard-lesson-footer-stats">
                        <p className="dashboard-lesson-footer-stats-right-text">
                            {t("dashboard.course.lesson.footerstats.correctAnswers")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-total-text">
                            {t("dashboard.course.lesson.footerstats.questions")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-time-text">
                            {t("dashboard.course.lesson.footerstats.totalTime")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-right-number">{this.props.correct}</p>
                        <p className="dashboard-lesson-footer-stats-total-number">{this.props.questions}</p>
                        <p className="dashboard-lesson-footer-stats-time-number">
                            {this.secondsToHms(totalTime)} 
                            {/* {t("dashboard.course.lesson.footerstats.seconds")} */}
                        </p>
                    </div>

                    {this.state.loading ? (
                        <Loader active inline="centered" size="large" style={{ marginBottom: "24px" }} />
                    ) : smallTable ? (
                        <div className="dashboard-lesson-footer-questions">
                            <Table basic="very" size="small">
                                <Table.Body>{tableRows}</Table.Body>
                            </Table>
                        </div>
                    ) : (
                        <Table basic="very" size="small">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>№</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.question")}</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.result")}</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.time")}</Table.Cell>
                                </Table.Row>
                                {tableRows}
                            </Table.Body>
                        </Table>
                    )}
                    {/* <div className="dashboard-lesson-footer-extra">
                        <div className="dashboard-lesson-footer-extra-header">
                            {t("dashboard.course.lesson.footerstats.extra")}:
                        </div>
                        {this.props.extraMaterial ? ReactHtmlParser(this.props.extraMaterial) : null}
                    </div> */}
                </div>
            </Modal>
        );
    }
}

export default connect(
    (store) => ({
        userId: store.user.user._id,
    }),
    {}
)(withTranslation()(LessonStatsModal));
