import './ToggleButton.css';
import React, {useState} from 'react';
import {useColor} from '../Utils/ColorContext';
import sunwithe from '../../../assets/images/landing/sunwithe.svg'
import sunblack from '../../../assets/images/landing/sunblack.svg'
import moon from '../../../assets/images/landing/moon.svg'

function ToggleButton() {
    const {color, setColor} = useColor();
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        setColor(toggle ? '#3C3C56' : '#e6f5ff');

    };
    return (
        <div
            className={`wrg-toggle ${toggle ? 'wrg-toggle--checked' : ''}`}

            onClick={handleToggle}
        >
            <div className="wrg-toggle-container">
                <div className="wrg-toggle-uncheck">
                    <span><img className='imagesTogg' src={color === '#3C3C56' ? sunblack : sunwithe}
                               alt="Logo"/></span>
                </div>
                <div className="wrg-toggle-check">
                    <span><img className='imagesTogg' src={moon} alt="Logo"/></span>
                </div>
                <div className="wrg-toggle-circle"></div>
            </div>

        </div>
    );
}

export default ToggleButton;
