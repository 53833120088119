import React from 'react';
import './Modal.css';
import {useColor} from '../Utils/ColorContext';
import close from '../../../assets/images/landing/close.svg';

function Modal(props) {
    const { isOpen, onClose, children } = props;

    const { color } = useColor();

    const headerStyle = {
        backgroundColor: color === '#3C3C56' ? color : 'white',
        color: color === '#3C3C56' ? 'white' : 'black',
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose(); // Закрыть модальное окно при клике на внешний контейнер
        }
    };

    return (
        <div>
            {isOpen && (
                <div className="custom-modal" onClick={handleOverlayClick}>
                    <div className="modal-content" style={headerStyle}>
                        <span className="close" onClick={onClose}><img src={close} alt="close" /></span>
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal;