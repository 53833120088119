// modules
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import { dashboardRoutes } from 'assets/routes';
// components
import { Loader } from 'semantic-ui-react';
import CourseCard from 'components/CourseCard';
// redux
import { setCurrentCourse } from 'store/UI';
import { setLocale } from 'store/Config';
import { logout } from 'store/User';
// styles
import "./index.css";
import { Input } from 'semantic-ui-react';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;

  ${media.lessThan('600px')`
		gap: 8px;
	`}

  ${media.greaterThan('600px')`
		gap: 16px;
	`}
`;

class CoursesList extends Component {
  state = {
    courses: [],
    loading: false,
    language: '',
    classroom: '',
    subject: '',
    input: ''
  };

  componentDidMount() {
    this.props.setCurrentCourse(null);
    this.getCourses();
  }

  getCourses = async () => {
    this.setState({ loading: true });

    axios
      .get(dashboardRoutes.fetchCourses())
      .then(response => {
        this.setState({
          loading: false,
          courses: response.data.message
        });
      })
      .catch(err => {
        console.error('Error fetching courses:', err);
        this.setState({ loading: false });

        if (err.response.status === 401) {
          this.props.logout();
        }
      });
  };

  changeLocaleAndRedirectToCourse = async (id, locale) => {
    const { hasAccessToCourses, location } = this.props;

    // change interface language to course language in case the user has paid for the course
    if (hasAccessToCourses) {
      await this.props.setLocale(locale);
    }
    // redirect
    const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
    window.location = `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${id}`;
  };

  handleChange = (e, data) => {
    let label = document.createElement('label'),
        label_wrapper = document.querySelector('.tags_wrapper');
    label.htmlFor = e.target.id
    label.innerText = e.target.nextSibling.textContent

    if (e.target.id) {
      if (label_wrapper.querySelector(`label[for=${e.target.id}]`)) label_wrapper.querySelector(`label[for=${e.target.id}]`).remove()
      else label_wrapper.appendChild(label)
    }

    if (e.target.name === 'language') {
      const inputs = e.target.closest('.option_wrapper').querySelectorAll('[name="language"]')
      if (inputs) {
        let arr = []
        Array.prototype.slice.call(inputs).filter(input => input.checked).map(input => arr.push(input.value))
        this.setState({ language: arr.join(",")});
      }
    }
    if (e.target.name === 'classroom') {
      const inputs = e.target.closest('.option_wrapper').querySelectorAll('[name="classroom"]')
      if (inputs) {
        let arr = []
        Array.prototype.slice.call(inputs).filter(input => input.checked).map(input => arr.push(input.value))
        this.setState({ classroom: arr.join(",")});
      }
    }
    if (e.target.name === 'subject') {
      const inputs = e.target.closest('.option_wrapper').querySelectorAll('[name="subject"]')
      if (inputs) {
        let arr = []
        Array.prototype.slice.call(inputs).filter(input => input.checked).map(input => arr.push(input.value))
        this.setState({ subject: arr.join(",")});
      }
    }
    if (e.target.name === 'searchString') {
      this.setState({
        input: e.target.value,
      })
    }
  };

  fetchCards = () => { 
    let courseCards = [],
        filtered = {
          language: [],
          classroom: [],
          subject: [],
          input: ''
        },
        courses = this.state.courses;

      if (courses && courses.length !== 0) {
        const filterData = {
          language: this.state.language.split(','), 
          classroom: this.state.classroom.split(','), 
          subject: this.state.subject.split(','),
          input: this.state.input
        }

        function filterLanguage() {
          if (filterData.language[0] != '') {
            courses.forEach(course => {
              filterData.language.forEach(language => {
                if (course.language == language) filtered.language.push(course)
              })
            })
          } else filtered.language = courses
          filterClassroom()
        }
        filterLanguage()

        function filterClassroom() {
          if (filterData.classroom[0] != '') {
            filtered.language.forEach(course => {
              filterData.classroom.forEach(classroom => {
                if (course.classroom == classroom) filtered.classroom.push(course)
              })
            })
          } else filtered.classroom = filtered.language
          filterSubject()
        }

        function filterSubject() {
          if (filterData.subject[0] != '') {
            filtered.classroom.forEach(course => {
              filterData.subject.forEach(subject => {
                if (course.subject == subject) filtered.subject.push(course)
              })
            })
          } else filtered.subject = filtered.classroom
        }

        function filterInput() {
          if (filterData.input != '') {
            filtered.subject = filtered.subject.filter(course => filterData.input === '' || course.name.toLowerCase().includes(filterData.input.toLowerCase()))
          }
        }
        filterInput()

        filtered.subject.map((course, index) => {
          courseCards.push(
            <CourseCard
              key={`dashboard-courses-card-${index}`}
              imgSrc={`http://ekitap.kz${course.cover}`}
              header={course.name}
              onClick={() => this.changeLocaleAndRedirectToCourse(course._id, course.language)}
            />
          );
        });
      }
    return courseCards
  }

  selectToggle = () => {
    window.addEventListener('click', (e) => {
      if (!e.target.closest('.select_wrapper')) document.querySelectorAll('input.hidden').forEach(input => input.checked = false)
    })
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;

    return (
      <DocumentTitle title={t('dashboard.courses.header')}>
        <div>
          <h1>{t('dashboard.courses.header')}</h1>
          <div className='filter_wrapper'>

            <div className='search_wrapper'>
              <Input fluid icon="search" placeholder={t("dashboard.courses.filter.placeholderInput")} name="searchString" value={this.state.input} onChange={this.handleChange}/>
            </div>

            <label onChange={this.selectToggle} className='select_wrapper'>
              <input type="checkbox" className='hidden peer'/>
              <span className='select_title'>
                {t("dashboard.courses.filter.language")}
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path></svg>
              </span>
              <div className='option_wrapper'>
                <label>
                  <input id='kk' type="checkbox" onChange={this.handleChange} name="language" value="kk"/>
                  <span>{t("kk")}</span>
                </label>
                <label>
                  <input id='ru' type="checkbox" onChange={this.handleChange} name="language" value="ru"/>
                  <span>{t("ru")}</span>
                </label>
                <label>
                  <input id='rn' type="checkbox" onChange={this.handleChange} name="language" value="en"/>
                  <span>{t("en")}</span>
                </label>
                <label>
                  <input id='ug' type="checkbox" onChange={this.handleChange} name="language" value="ug"/>
                  <span>{t("ug")}</span>
                </label>
              </div>
            </label>

            <label onChange={this.selectToggle} className='select_wrapper'>
              <input type="checkbox" className='hidden peer'/>
              <span className='select_title'>
                {t("dashboard.courses.filter.classroom")}
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path></svg>
              </span>
              <div className='option_wrapper'>
                <label>
                  <input id='preschool' type="checkbox" onChange={this.handleChange} name="classroom" value="0"/>
                  <span>{t("dashboard.courses.filter.preschool")}</span>
                </label>
                <label>
                  <input id='a_class' type="checkbox" onChange={this.handleChange} name="classroom" value="1"/>
                  <span>1{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='b_class' type="checkbox" onChange={this.handleChange} name="classroom" value="2"/>
                  <span>2{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='c_class' type="checkbox" onChange={this.handleChange} name="classroom" value="3"/>
                  <span>3{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='d_class' type="checkbox" onChange={this.handleChange} name="classroom" value="4"/>
                  <span>4{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='e_class' type="checkbox" onChange={this.handleChange} name="classroom" value="5"/>
                  <span>5{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='f_class' type="checkbox" onChange={this.handleChange} name="classroom" value="6"/>
                  <span>6{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='g_class' type="checkbox" onChange={this.handleChange} name="classroom" value="7"/>
                  <span>7{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='h_class' type="checkbox" onChange={this.handleChange} name="classroom" value="8"/>
                  <span>8{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='i_class' type="checkbox" onChange={this.handleChange} name="classroom" value="9"/>
                  <span>9{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='j_class' type="checkbox" onChange={this.handleChange} name="classroom" value="10"/>
                  <span>10{t("dashboard.courses.filter.class")}</span>
                </label>
                <label>
                  <input id='k_class' type="checkbox" onChange={this.handleChange} name="classroom" value="11"/>
                  <span>11{t("dashboard.courses.filter.class")}</span>
                </label>
              </div>
            </label>

            <label onChange={this.selectToggle} className='select_wrapper'>
              <input type="checkbox" className='hidden peer'/>
              <span className='select_title'>
                {t("dashboard.courses.filter.subject")}
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path></svg>
              </span>
              <div className='option_wrapper'>
                <label>
                  <input id='preschool' type="checkbox" onChange={this.handleChange} name="subject" value="Предшкола"/>
                  <span title={t("dashboard.courses.filter.preschool")}>{t("dashboard.courses.filter.preschool")}</span>
                </label>
                <label>
                  <input id='history' type="checkbox" onChange={this.handleChange} name="subject" value="История"/>
                  <span title={t("dashboard.courses.filter.history")}>{t("dashboard.courses.filter.history")}</span>
                </label>
                <label>
                  <input id='kazakh' type="checkbox" onChange={this.handleChange} name="subject" value="Казахский язык"/>
                  <span title={t("dashboard.courses.filter.kazakh")}>{t("dashboard.courses.filter.kazakh")}</span>
                </label>
                <label>
                  <input id='russian' type="checkbox" onChange={this.handleChange} name="subject" value="Русский язык"/>
                  <span title={t("dashboard.courses.filter.russian")}>{t("dashboard.courses.filter.russian")}</span>
                </label>
                <label>
                  <input id='literature' type="checkbox" onChange={this.handleChange} name="subject" value="Литература"/>
                  <span title={t("dashboard.courses.filter.literature")}>{t("dashboard.courses.filter.literature")}</span>
                </label>
                <label>
                  <input id='mathematics' type="checkbox" onChange={this.handleChange} name="subject" value="Математика"/>
                  <span title={t("dashboard.courses.filter.mathematics")}>{t("dashboard.courses.filter.mathematics")}</span>
                </label>
                <label>
                  <input id='geography' type="checkbox" onChange={this.handleChange} name="subject" value="География"/>
                  <span title={t("dashboard.courses.filter.geography")}>{t("dashboard.courses.filter.geography")}</span>
                </label>
                <label>
                  <input id='computerscience' type="checkbox" onChange={this.handleChange} name="subject" value="Информатика"/>
                  <span title={t("dashboard.courses.filter.computerscience")}>{t("dashboard.courses.filter.computerscience")}</span>
                </label>
                <label>
                  <input id='physics' type="checkbox" onChange={this.handleChange} name="subject" value="Физика"/>
                  <span title={t("dashboard.courses.filter.physics")}>{t("dashboard.courses.filter.physics")}</span>
                </label>
                <label>
                  <input id='biology' type="checkbox" onChange={this.handleChange} name="subject" value="Биология"/>
                  <span title={t("dashboard.courses.filter.biology")}>{t("dashboard.courses.filter.biology")}</span>
                </label>
                <label>
                  <input id='foreignlanguage' type="checkbox" onChange={this.handleChange} name="subject" value="Иностранный язык"/>
                  <span title={t("dashboard.courses.filter.foreignlanguage")}>{t("dashboard.courses.filter.foreignlanguage")}</span>
                </label>
                <label>
                  <input id='music' type="checkbox" onChange={this.handleChange} name="subject" value="Музыка"/>
                  <span title='Музыка'>Музыка</span>
                </label>
                <label>
                  <input id='naturalscience' type="checkbox" onChange={this.handleChange} name="subject" value="Естествознание"/>
                  <span title={t("dashboard.courses.filter.naturalscience")}>{t("dashboard.courses.filter.naturalscience")}</span>
                </label>
                <label>
                  <input id='feb' type="checkbox" onChange={this.handleChange} name="subject" value="Основы предпринимательства и бизнеса"/>
                  <span title={t("dashboard.courses.filter.feb")}>{t("dashboard.courses.filter.feb")}</span>
                </label>
                <label>
                  <input id='reader' type="checkbox" onChange={this.handleChange} name="subject" value="Хрестоматия"/>
                  <span title={t("dashboard.courses.filter.reader")}>{t("dashboard.courses.filter.reader")}</span>
                </label>
                <label>
                  <input id='optional' type="checkbox" onChange={this.handleChange} name="subject" value="Факультативы"/>
                  <span title='Факультативы'>Факультативы</span>
                </label>
                <label>
                  <input id='workbook' type="checkbox" onChange={this.handleChange} name="subject" value="Рабочая тетрадь"/>
                  <span title={t("dashboard.courses.filter.workbook")}>{t("dashboard.courses.filter.workbook")}</span>
                </label>
                <label>
                  <input id='methodologicalguide' type="checkbox" onChange={this.handleChange} name="subject" value="Методическое руководство"/>
                  <span title={t("dashboard.courses.filter.methodologicalguide")}>{t("dashboard.courses.filter.methodologicalguide")}</span>
                </label>
              </div>
            </label>

            <div className='tags_wrapper'>
            </div>
          </div>

          {loading ? <Loader active size="large" /> : <CardsContainer>{this.fetchCards()}</CardsContainer>}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  hasAccessToCourses: state.user.user.hasAccessToCourses,
});

const mapDispatchToProps = dispatch => ({
  setCurrentCourse: course => dispatch(setCurrentCourse(course)),
  setLocale: locale =>
    new Promise((resolve, _) => {
      resolve(dispatch(setLocale(locale)));
    }),
  logout: () => dispatch(logout())
});

export default withRouter(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withTranslation()
  )(CoursesList)
);
