// modules
import React, { Component } from 'react';
import axios from 'axios';
// assets
import { bold, header } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Checkbox, Container, Input, Message } from 'semantic-ui-react';
// components
// redux

const redirect = (context, path) => {
  context.props.history.push(path);
};

class NewLesson extends Component {
  state = {
    id: '',
    lesson: {
      name: '',
      displayName: '',
      hidden: false,
      section: '',
      length: 0,
      questions: 0,
      blocks: []
    }
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({
      id: id
    });
  }

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'hidden') {
      result = data.checked;
    }
    this.setState(prevState => ({
      ...prevState,
      lesson: {
        ...prevState.lesson,
        [data.name]: result
      }
    }));
  };

  handleAddLesson = () => {
    // show alert if course ID was not loaded
    if (!this.state.id) {
      alert('Произошла ошибка');
      return;
    }

    // show alert if name was not entered
    if (!this.state.lesson.name) {
      alert('Название урока не было введено');
      return;
    }

    const r = window.confirm('Добавить новый урок в этот курс?');
    if (r) {
      // add lesson
      axios.post(adminRoutes.courses.addLesson(this.state.id), { lesson: { ...this.state.lesson } }).then(() => {
        redirect(this, `/admin/courses/${this.state.id}`);

        // reset state
        this.setState({
          name: '',
          displayName: '',
          hidden: false,
          section: '',
          length: 0,
          questions: 0,
          blocks: []
        });
      });
    }
  };

  render() {
    return (
      <Container>
        {header('Добавить новый урок', '24px', 600)}
        <Message
          info
          icon="hand pointer outline"
          header="Блоки урока"
          content="Добавлять блоки в этот урок можно будет после создания, выбрав соответствующий урок из списка"
        />
        <div style={{ marginBottom: '1em' }}>
          {bold('Название урока:')}
          <Input fluid required name="name" placeholder="Название урока" value={this.state.name} onChange={this.handleChange} />
        </div>
        <div style={{ marginBottom: '1em' }}>
          {bold('Краткое название урока:')}
          <Input
            fluid
            required
            name="displayName"
            placeholder="Краткое название урока"
            value={this.state.displayName}
            onChange={this.handleChange}
          />
        </div>
        <div style={{ marginBottom: '1em' }}>
          {bold('Скрыть урок?')}
          <Checkbox checked={this.state.hidden} name="hidden" toggle onChange={this.handleChange} />
        </div>
        {/* <div style={{ marginBottom: "1em" }}>
                    {bold("Секция:")}
                    <Input
                        fluid
                        name="section"
                        placeholder="Секция"
                        value={this.state.section}
                        onChange={this.handleChange}
                    />
                </div> */}
        <Button
          type="button"
          onClick={this.handleAddLesson}
          style={{
            backgroundColor: '#0e79b2',
            color: '#fff',
            borderRadius: '0px',
            boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
            marginBottom: '1em'
          }}
        >
          Добавить урок
        </Button>
      </Container>
    );
  }
}

export default NewLesson;
