// modules
import React, {useState,useEffect,useRef} from 'react';
import { withTranslation } from "react-i18next";

import './Header.css';
import logo from '../../../assets/images/landing/logo.svg'

import Modal from './Modal'; // Путь к компоненту Modal
import Instructions from './Instructions';
import Contacts from './Contacts';
import Conditions from './Сonditions'
import {useColor} from '../Utils/ColorContext';
import ToggleButton from "./ToggleButton";
import LanguageSelect from "components/LanguageSelect";

function Header ({t}) {
    const [isContactsModalOpen, setContactsModalOpen] = useState(false);
    const [isInstructionsModalOpen, setInstructionsModalOpen] = useState(false);
    const [isConditionsModalOpen, setConditionsModalOpen] = useState(false);

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef();

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const openContactsModal = () => {
        setContactsModalOpen(true);
    };

    const closeContactsModal = () => {
        setContactsModalOpen(false);
    };

    const openInstructionsModal = () => {
        setInstructionsModalOpen(true);
    };

    const closeInstructionsModal = () => {
        setInstructionsModalOpen(false);
    };

    const openConditionsModal = () => {
        setConditionsModalOpen(true);
    };

    const closeConditionsModal = () => {
        setConditionsModalOpen(false);
    };

    const { color } = useColor();

    useEffect(() => {
        // Обработчик клика на внешний контейнер для закрытия меню
        const handleOutsideClick = (event) => {
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
                setMobileMenuOpen(false);
            }
        };

        if (isMobileMenuOpen) {
            // Добавляем обработчик событий при открытом меню
            document.addEventListener('click', handleOutsideClick);
        } else {
            // Удаляем обработчик событий при закрытом меню
            document.removeEventListener('click', handleOutsideClick);
        }

        // Убеждаемся, что обработчик события удаляется при размонтировании компонента
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    const headerStyle = {
        backgroundColor: color === '#3C3C56' ? color : '#00B9F8',
        color: color === '#3C3C56' ? 'white' : 'white',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        transition: 'background-color 1s, color 1s, box-shadow 1s',
    };
    const headerButStyle = {
        backgroundColor: color === '#3C3C56' ? '#00B9F8' : 'white',
        color: color === '#3C3C56' ? 'white' : '#00B9F8',
        transition: 'background-color 1s, color 1s, box-shadow 1s',
    };

    return (
        <>
            <div style={headerStyle}>
                <div style={headerStyle} className="Header">
                    <div className="logoimg">
                        <a href="/">
                            <img src={color === '#3C3C56' ? logo : logo} alt="ekitap.kz" />
                        </a>
                    </div>

                    <div className="mobile-menu-button" onClick={toggleMobileMenu}>
                        &#9776;
                    </div>
                    <div className="togl-mob">
                        <ToggleButton />
                    </div>

                    {isMobileMenuOpen && (
                        <div className="mobile-menu" ref={mobileMenuRef}>
                            <div className="menu-block" style={headerStyle}>
                                <LanguageSelect />
                            </div>

                            <nav className="top-nav-mob">
                                <div className="desktop-menu-mob">
                                    <ul className="navigcia-mob">
                                        <li className="navigcia-first-mob" onClick={openContactsModal}>{t("landing.header.about")}</li>
                                        <li className="navigcia-centr-mob" onClick={openInstructionsModal}>{t("landing.header.manual")}</li>
                                        <li className="navigcia-last-mob" onClick={openConditionsModal}>{t("landing.header.terms")}</li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    )}

                    <div className="nav-and-lang">
                        <nav className="top-nav">
                            <div className="desktop-menu">
                                <ul className="navigcia">
                                    <li className="navigcia-first" onClick={openContactsModal}>
                                        <h3>{t("landing.header.about")}</h3>
                                    </li>
                                    <li className="navigcia-centr" onClick={openInstructionsModal}>
                                        <h3>{t("landing.header.manual")}</h3>
                                    </li>
                                    <li className="navigcia-last" onClick={openConditionsModal}>
                                        <h3>{t("landing.header.terms")}</h3>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <LanguageSelect />
                    </div>
                </div>
                <Modal isOpen={isContactsModalOpen} onClose={closeContactsModal}>
                    <Contacts />
                </Modal>
                <Modal isOpen={isInstructionsModalOpen} onClose={closeInstructionsModal}>
                    <Instructions />
                </Modal>
                <Modal isOpen={isConditionsModalOpen} onClose={closeConditionsModal}>
                    <Conditions />
                </Modal>
            </div>
        </>
    )
}

export default withTranslation()(Header);