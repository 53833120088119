// modules
import React, { Component } from "react";
import sortBy from "lodash/sortBy";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
// assets
// styles
import { Container, Table } from "semantic-ui-react";
// components
// redux

const StyledInteractivePairContainer = styled.div`
  display: inline-block;
  max-height: 160px;
`;

const StyledContainerWithWordBreak = styled(Table.Cell)`
  word-break: break-word;
`;

class LessonAnalytics extends Component {
  state = {
    data: [],
    column: null,
    direction: null,
  };

  componentDidMount() {
    const data = this.getParsedUserAnswers();
    this.setState({ data });
  }

  getParsedUserAnswers = () => {
    const questionAnswers = this.props.answers.map((userAnswer, index) => {
      const item = {};
      const block = this.props.blocks.find((x) => x._id === userAnswer._id);
      const { t } = this.props;

      item.question = index + 1;
      item.correctAnswer = "---";
      item.answer = userAnswer.answer;
      item.correct =
        userAnswer.correct === false
          ? t("admin.analytics.no")
          : userAnswer.correct === true
          ? t("admin.analytics.yes")
          : "---";
      item.hasReanswered = userAnswer.hasReanswered === true ? t("admin.analytics.yes") : t("admin.analytics.no");
      item.time = userAnswer.time;

      if (block) {
        item.blockType = block.blockType;
        item.correctAnswer = block.correctAnswer
          ? block.correctAnswer
          : t("admin.analytics.noRightAnswer");

        if (
          item.blockType &&
          item.blockType.startsWith("question-interactive")
        ) {
          const parsedAnswer = JSON.parse(item.answer);
          const parsedInteractive = JSON.parse(block.interactive);
          const slots = (parsedInteractive && parsedInteractive.slots) || [];
          const pairs = (parsedInteractive && parsedInteractive.pairs) || [];
          const groups = (parsedInteractive && parsedInteractive.groups) || [];
          const words = (parsedInteractive && parsedInteractive.words) || [];
          const questions = (parsedInteractive && parsedInteractive.questions) || [];

          switch (item.blockType) {
            case "question-interactive-fill-slots":
            case "question-interactive-fill-from-list":
            case "question-interactive-find-matches":
              item.answer = <div>{parsedAnswer.join(", ")}</div>;
              item.correctAnswer = <div>{slots.join(", ")}</div>;
              break;
            case "question-interactive-select-words":
              item.answer = <div>{parsedAnswer.join(", ")}</div>;
              item.correctAnswer = <div>{slots.join(", ")}</div>;
              break;
            case "question-interactive-classification":
              item.answer = (
                <div>
                  {parsedAnswer.map((groupItem, index) => (
                    <div>
                      <b>
                        {groups[index].name} (t("admin.analytics.group") {index + 1}):
                      </b>{" "}
                      {groupItem.join(", ")}
                    </div>
                  ))}
                </div>
              );
              item.correctAnswer = (
                <div>
                  {groups.map((groupItem, index) => (
                    <div>
                      <b>
                        {groupItem.name} (t("admin.analytics.group") {index + 1}):
                      </b>{" "}
                      {groupItem.slots.join(", ")}
                    </div>
                  ))}
                </div>
              );
              break;
            case "question-interactive-find-pairs":
              item.answer = (
                <div>
                  {parsedAnswer.map((pair, index) => (
                    <div>
                      <b>{t("admin.analytics.pair")} {index + 1}:</b>
                      <StyledInteractivePairContainer>
                        {ReactHtmlParser(pair.first)}
                      </StyledInteractivePairContainer>{" "}
                      -{" "}
                      <StyledInteractivePairContainer>
                        {ReactHtmlParser(pair.second)}
                      </StyledInteractivePairContainer>
                    </div>
                  ))}
                </div>
              );
              item.correctAnswer = (
                <div>
                  {pairs.map((pair, index) => (
                    <div>
                      <b>{t("admin.analytics.pair")} {index + 1}:</b>
                      <StyledInteractivePairContainer>
                        {ReactHtmlParser(pair.first)}
                      </StyledInteractivePairContainer>{" "}
                      -{" "}
                      <StyledInteractivePairContainer>
                        {ReactHtmlParser(pair.second)}
                      </StyledInteractivePairContainer>
                    </div>
                  ))}
                </div>
              );
              break;
            case "question-interactive-guess-word":
              item.answer = (
                <div>
                  {parsedAnswer.map((answer, index) => (
                    <div><b>{t("admin.analytics.word")} {index + 1}:</b> {answer}</div>
                  ))}
                </div>
              );
              item.correctAnswer = (
                <div>
                  {words.map(({ word, hint }, index) => (
                    <div><b>{t("admin.analytics.word")} {index + 1}:</b> {word}</div>
                  ))}
                </div>
              );
              break;
            case "question-interactive-word-search":
              item.answer = (
                <div>
                  {words
                    .map(({ word }) => word)
                    .filter((_, index) => parsedAnswer.words[index])
                    .join(', ')}
                </div>
              );
              item.correctAnswer = (
                <div>
                  {words.map(({ word }) => word).join(', ')}
                </div>
              );
              break;
            case "question-interactive-order-words":
              item.answer = (
                <div>
                  {parsedAnswer.map((answer, index) => (
                    <div><b>{t("admin.analytics.exercise")} {index + 1}:</b> {answer}</div>
                  ))}
                </div>
              );
              item.correctAnswer = (
                <div>
                  {questions.map(({ question }, index) => (
                    <div><b>{t("admin.analytics.exercise")} {index + 1}:</b> {question}</div>
                  ))}
                </div>
              );
              break;
            default:
              break;
          }
        }
      }

      return item;
    });

    const voiceRecordings = this.props.blocks.filter((block) => block.blockType === 'text' && block.hasVoiceRecorder).map((block, index) => {
      const item = {};

      // build audio URL
      const baseUrl = "https://ekitap.kz";
      // const baseUrl = "http://localhost:8080";
      const voiceUrl = `${baseUrl}/static/media/voice/user-${this.props.userId}-course-${this.props.courseId}-lesson-${this.props.lessonId}-block-${block._id}.wav`;

      item.question = questionAnswers.length + index + 1;
      item.correctAnswer = "---";
      item.answer = (
        <audio controls>
          <source src={voiceUrl} type="audio/wav" />
          Your browser does not support the audio tag.
        </audio>
      );
      item.correct = "---";
      item.hasReanswered = "---";
      item.time = "---";

      return item;
    });

    return [...questionAnswers, ...voiceRecordings];
  };

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortBy(data, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const { column, data, direction } = this.state;
    const { t } = this.props;

    return (
      <Container>
        <Table celled sortable size="small" fixed={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                width={1}
                sorted={column === "number" ? direction : null}
                onClick={this.handleSort("number")}
              >
                {t("admin.analytics.jobNumber")}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                sorted={column === "correctAnswer" ? direction : null}
                onClick={this.handleSort("correctAnswer")}
              >
                {t("admin.analytics.correctAnswer")}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={6}
                sorted={column === "answer" ? direction : null}
                onClick={this.handleSort("answer")}
              >
                {t("admin.analytics.userAnswer")}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                sorted={column === "correct" ? direction : null}
                onClick={this.handleSort("correct")}
              >
                {t("admin.analytics.isTheAnswerCorrect")}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                sorted={column === "hasReanswered" ? direction : null}
                onClick={this.handleSort("hasReanswered")}
              >
                {t("admin.analytics.useSecondTry")}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                sorted={column === "time" ? direction : null}
                onClick={this.handleSort("time")}
              >
                {t("admin.analytics.responseTime")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((item, index) => (
              <Table.Row
                key={`admin-analytics-user-course-${this.props.courseId}-lesson-${this.props.lessonId}-detailed-table-row-${index}`}
              >
                <Table.Cell>{item.question}</Table.Cell>
                <StyledContainerWithWordBreak>
                  {item.correctAnswer}
                </StyledContainerWithWordBreak>
                <StyledContainerWithWordBreak>
                  {item.answer}
                </StyledContainerWithWordBreak>
                <Table.Cell>{item.correct}</Table.Cell>
                <Table.Cell>{item.hasReanswered}</Table.Cell>
                <Table.Cell>{item.time}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default compose(
  withTranslation()
)(LessonAnalytics);
