// modules
import React from 'react';
import styled from 'styled-components';
// assets
// styles
import { Button, Icon, Input } from 'semantic-ui-react';
// components
import { AddSlotButton, InteractiveSingleSlot } from '../InteractiveQuestionsCommon';
// redux

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div:last-child {
    margin-left: 48px;
  }
`;

const GroupNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const GroupNameInput = styled(Input)`
  width: 100%;
`;

const GroupSlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AddGroupSlotButton = styled(AddSlotButton)`
  width: fit-content;
`;

export default function ({ group = {}, index = 0, handleChange, handleDelete, handleChangeSlot, handleAddSlot, handleDeleteSlot, handleChoseImage }) {
  return (
    <GroupContainer>
      <GroupNameContainer>
        <GroupNameInput
          placeholder='Введите текст для группы'
          label='Текст группы'
          value={group.name || ''}
          index={index}
          onChange={handleChange}
        />
        <Button negative icon labelPosition='right' onClick={() => handleDelete(index)}>
          <Icon name='remove circle' />
          Удалить группу
        </Button>
      </GroupNameContainer>
      
      <GroupSlotsContainer>
        {(group.slots || []).map((slot, slotIndex) => {
          return (
            <InteractiveSingleSlot
              key={`group-${index}-slot-${slotIndex}`}
              value={slot}
              index={slotIndex}
              groupIndex={index}
              handleChange={handleChangeSlot}
              handleDelete={handleDeleteSlot}
              handleChoseImage={handleChoseImage}
            />
          )
        })}

        <AddGroupSlotButton onClick={() => handleAddSlot(index)}>
          Добавить новый пропуск
        </AddGroupSlotButton>
      </GroupSlotsContainer>
    </GroupContainer>
  );
}

