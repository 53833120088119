// modules
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Form, Statistic } from "semantic-ui-react";
import "../Question/index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const SLOT_CLASSNAME = 'dashboard-lesson-block-question-slot';

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

class IndefiniteQuestion extends Component {
    constructor(props) {
        super(props);

        const { block } = props;
        const { text = '' } = block;

        const matchedBlanks = text.match(/-[0-9]+-/g) || [];
        const answer = block.answer ? JSON.parse(block.answer) : (matchedBlanks.length === 0 ? '' : []);

        this.state = {
            answer,
            slots: matchedBlanks,
            answered: !!block.answer,
            elapsed: block.time || 0,
            showHint: false,
            testQuestion: block.testQuestion,
            hasReanswered: block.hasReanswered,
        };

        const replacedText = text.replace(/-[0-9]+-/g, this.replaceSlotsInText);
        this.renderedElements = ReactHtmlParser(replacedText);
    }

    componentDidMount() {
        if (this.props.preview) return;
        if (!this.state.answered) {
            // set answering to false (for parent Lesson component)
            this.props.handleAnswering(true);
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    replaceSlotsInText = (match) => {
        const { answered } = this.state;
        const { block } = this.props;
        const { _id: blockId } = block;

        const matchWithRemovedDashed = match.replaceAll('-', '');
        const index = parseInt(matchWithRemovedDashed, 10) - 1;
        const value = this.state.answered ? this.state.answer[index] : '';

        const isEditable = answered ? 'false' : 'true';

        return `<div data-blockid='${blockId}' class=${SLOT_CLASSNAME} contenteditable=${isEditable}>${value}</div>`;
    };

    startTimer = () => {
        this.start = new Date();
        this.timer = setInterval(this.tick, 1000);
    };

    handleQuestionVisibility = (isVisible) => {
        if (!isVisible) {
            if (this.timer) clearInterval(this.timer);
        }

        if (isVisible && !this.state.answered) {
            if (this.timer) clearInterval(this.timer);
            this.startTimer();
        }
    };

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000),
        });
    };

    getHeader = () => {
        const { section, questionNumber, t } = this.props;

        if (!questionNumber) {
            return t("dashboard.course.lesson.question.task");
        }

        if (section) {
            return `${t("dashboard.course.lesson.question.task")} ${section}.${questionNumber}`;
        }
        return `${t("dashboard.course.lesson.question.task")} ${questionNumber}`;
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        // stop timer
        if (this.timer) clearInterval(this.timer);
        
        const { elapsed } = this.state;
        const { block, courseId, lessonId, index } = this.props;
        const { _id: blockId } = block;
        const correct = undefined;

        let userAnswer = this.state.answer;
        if (this.state.slots.length !== 0) {
            // get user answers
            const slotHtmlElements = document.querySelectorAll(`div.${SLOT_CLASSNAME}[data-blockid='${blockId}']`) || [];
            userAnswer = [...slotHtmlElements].map((element) => element.textContent);
            [...slotHtmlElements].forEach((element) => {
                element.setAttribute('contenteditable', 'false');
            });
        }

        // convert user answer into a string
        const stringifiedAnswer = JSON.stringify(userAnswer);

        // set answering to false (for parent Lesson component)
        this.props.handleAnswering(false);
        this.props.handleAnswer(index, stringifiedAnswer, correct, elapsed);

        // answer payload
        const payload = {
            answer: stringifiedAnswer,
            correct,
            time: elapsed,
        };

        // axios call
        const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
        axios.post(answerRoute, payload).then(() => {
            this.setState({
                answered: true,
                correct,
                answer: userAnswer,
            });
        });
    };

    onReanswerClick = () => {
        const resetAnswer = this.state.slots.length === 0 ? '' : [];
        const slotHtmlElements = document.querySelectorAll(`div.${SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
        [...slotHtmlElements].forEach((element) => {
            element.setAttribute('contenteditable', 'true');
        });

        this.setState({
            answer: resetAnswer,
            answered: false,
            correct: undefined,
            elapsed: 0,
            hasReanswered: true
        }, () => {
            // start the timer again
            this.startTimer();
        });
    };

    render() {
        const { preview, t } = this.props;

        let answerButton = (
            <Button
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                            ? "medium"
                            : this.props.innerWidth > 500
                                ? "tiny"
                                : "mini"
                }
                onClick={this.handleAnswer}
            >
                {t("dashboard.course.lesson.question.answer")}
            </Button>
        );

        let reanswerButton = (
            <Button
                primary
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                onClick={this.onReanswerClick}
            >
                {t("dashboard.course.lesson.question.reanswer")}
            </Button>
        )

        if (preview) {
            answerButton = null;
            reanswerButton = null;
        }
        if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
            reanswerButton = null;
        }

        let hint;
        let explanation;
        let controls;
        let question;

        // display hint
        if (this.props.block.hint) {
            hint = (
                <div className="dashboard-lesson-block-question-hint">
                    <Button
                        className="dashboard-lesson-block-question-hint-button"
                        onClick={this.handleHint}
                        size={
                            this.props.innerWidth > 700
                                ? "large"
                                : this.props.innerWidth > 600
                                    ? "medium"
                                    : this.props.innerWidth > 500
                                        ? "tiny"
                                        : "mini"
                        }
                    >
                        {this.state.showHint
                            ? t("dashboard.course.lesson.question.hideHint")
                            : t("dashboard.course.lesson.question.showHint")}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-lesson-block-question-hint-text">
                            <div className="ck-content" style={{ width: '100%', }}>
                                {ReactHtmlParser(this.props.block.hint)}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }

        // display explanataion for answered question
        if (this.state.answered) { // && !this.state.testQuestion
            // explanation text
            let explanationText = "";

            // replace name
            if (this.props.block.explanationText) {
                explanationText = this.props.block.explanationText;
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (this.props.block.time) {
                explanationText = explanationText.replace("$$time$$", this.props.block.time);
            }
            // replace rec time
            if (this.props.block.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
            }

            if (explanationText) {
                explanation = (
                    <div
                        className="dashboard-lesson-block-question-explanation-indefinite"
                        id={`lesson-${this.props.id}-question-${this.props.block._id}-explanation`}
                    >
                        <div className="ck-content" style={{ width: '100%', }}>
                            {ReactHtmlParser(explanationText)}
                        </div>
                    </div>
                );
            }
        }

        controls = (
            <>
                {this.state.answered ? (
                    <div>{reanswerButton}</div>
                ) : (
                    <div>{answerButton}</div>
                )}

                <StyledTimerContainer>
                    <Statistic className="dashboard-lesson-block-question-form-time" floated="right" size="tiny">
                        <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                        <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
                    </Statistic>
                </StyledTimerContainer>
            </>
        );

        // question form
        question = this.state.slots.length === 0 && (
            <Form autoComplete="off" className="dashboard-lesson-block-question-form">
                <div className="dashboard-lesson-block-question-form-instruction">
                    {t("dashboard.course.lesson.question.instruction")}
                </div>
                <Form.TextArea
                    className="dashboard-lesson-block-question-form-input"
                    name="answer"
                    required
                    placeholder={t("dashboard.course.lesson.question.placeholder")}
                    readOnly={this.state.answered}
                    rows={5}
                    size="large"
                    value={this.state.answer}
                    onChange={this.handleChange}
                />
            </Form>
        );

        return (
            <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
                <div
                    className={`dashboard-lesson-block-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
                    id={`lesson-${this.props.id}-question-${this.props.block._id}`}
                >
                    {/* <div className="dashboard-lesson-block-question-header">{this.getHeader()}</div> */}
                    <div className="dashboard-lesson-block-question-text">
                        <div className="ck-content" style={{ width: '100%', }}>
                            <MathjaxWrapper text={this.renderedElements} />
                        </div>
                    </div>
                    {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
                    {hint}
                    {question}
                    {controls}
                    {explanation}
                </div>
            </VisibilitySensor>
        );
    }
}

export default withTranslation()(IndefiniteQuestion);
