import styled from 'styled-components';

export default styled.button`
  padding: 12px;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.75;
  color: #000000;
  font-size: 15px;
  border: 1px solid #000000;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 1;
  }
`;