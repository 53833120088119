// modules
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
// styles
import { Divider, Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';
import kundelikLogo from 'assets/images/kundelik-logo.png';
// components
// redux
import { forgotPassword, login } from 'store/User';
// utils
import kundelikIntegration from '../../../services/kundelik-integration';
import { userRoles } from '../../../assets/utils';

const LoginModal = ({
  forgotPasswordDone,
  forgotPasswordError,
  history,
  loginDone,
  loginStatus,
  makeForgotPassword,
  makeLogin,
  role,
  onClose,
  t
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, togglePasswordVisibility] = useState(false);
  const [isForgotPasswordShown, toggleForgotPassword] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [forgotPasswordMsg, setForgotPasswordMsg] = useState('');

  useEffect(() => {
    if (loginDone && role) {
      const redirectUrl = userRoles.includes(role) ? '/dashboard/courses' : '/admin';
      history.push(redirectUrl);
    }
  }, [loginDone, role]);

  useEffect(() => {
    if (loginStatus) {
      switch (loginStatus) {
        case 401:
          setLoginErrorMsg(t('errors.401'));
          break;
        case 403:
          setLoginErrorMsg(t('errors.403'));
          break;
        case 404:
          setLoginErrorMsg(t('errors.404'));
          break;
        case 503:
          setLoginErrorMsg(t('errors.503'));
          break;
        default:
          setLoginErrorMsg(t('errors.default'));
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (forgotPasswordDone) {
      setForgotPasswordMsg(<div className="auth-modal-success-msg">{t('landing.forgotmodal.msg.success')}</div>);
    }
  }, [forgotPasswordDone]);

  useEffect(() => {
    if (forgotPasswordError) {
      setForgotPasswordMsg(<div className="auth-modal-error-msg">{t('landing.forgotmodal.msg.error')}</div>);
    }
  }, [forgotPasswordError]);

  const handleForgot = event => {
    event.preventDefault();
    makeForgotPassword(email).catch(err => console.error(err));
  };

  const handleLogin = event => {
    event.preventDefault();
    makeLogin(email, password).catch(err => console.error(err));
  };

  const handleKundelikLogin = () => {
    kundelikIntegration.authenticate();
  }

  return (
    <Modal size="small" open closeOnDimmerClick={false} onClose={onClose}>
      <div className="landing-modal">
        <div className="landing-modal-cross" onClick={onClose}>
          <img src={cross} alt="Cross icon" />
        </div>
        
        {isForgotPasswordShown ? (
          <>
            <div className="landing-modal-header">
              <span>{t('landing.forgotmodal.header')}</span>
            </div>

            <form className="landing-modal-form" onSubmit={handleForgot}>
              <div className="landing-modal-form-note">{t('landing.forgotmodal.text1')}</div>
              <div className="landing-modal-form-note">{t('landing.forgotmodal.text2')}</div>

              {forgotPasswordMsg && <div className="landing-modal-message error">{forgotPasswordMsg}</div>}

              <input
                className="landing-modal-form-input"
                name="email"
                value={email}
                required
                placeholder="Email"
                onChange={event => setEmail(event.target.value)}
              />

              <div className="landing-modal-form-toggle-login" onClick={() => toggleForgotPassword(false)}>
                <span>{t('landing.forgotmodal.login')}</span>
              </div>

              <div className="landing-modal-form-controls">
                <input type="submit" value={t('send')} />
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="landing-modal-header">
              <span>{t('landing.loginmodal.header')}</span>
            </div>

            <form className="landing-modal-form" onSubmit={handleLogin}>
              {loginErrorMsg && <div className="landing-modal-message error">{loginErrorMsg}</div>}

              <input
                className="landing-modal-form-input"
                name="email"
                value={email}
                required
                placeholder="Логин"
                onChange={event => setEmail(event.target.value)}
              />

              <input
                className="landing-modal-form-input"
                name="password"
                value={password}
                type={isPasswordVisible ? 'text' : 'password'}
                required
                placeholder={t('password')}
                onChange={event => setPassword(event.target.value)}
              />

              <div className="landing-modal-form-row">
                <div className="landing-modal-form-toggle-login" onClick={() => toggleForgotPassword(true)}>
                  <span>{t('landing.loginmodal.forgot')}</span>
                </div>
                <div className="landing-modal-form-checkbox">
                  <label>
                    <input
                      name="isPasswordVisible"
                      value={isPasswordVisible}
                      type="checkbox"
                      onChange={event => togglePasswordVisibility(event.target.checked)}
                    />
                    {t('landing.registermodal.showpassword')}
                  </label>
                </div>
              </div>

              <div className="landing-modal-form-controls">
                <input type="submit" value={t('landing.login')} />
              </div>
              <Divider />
              <div className="landing-modal-login-kundelik" onClick={handleKundelikLogin}>
                <Trans
                  i18nKey='landing.loginWith'
                  components={[<img src={kundelikLogo} alt='' />]}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  role: state.user.user.scope,
  forgotPasswordDone: state.user.forgotPassword.done,
  forgotPasswordError: state.user.forgotPassword.fail,
  loginDone: state.user.login.done,
  loginError: state.user.login.fail,
  loginStatus: state.user.login.status
});

const mapDispatchToProps = dispatch => ({
  makeForgotPassword: email => dispatch(forgotPassword(email)),
  makeLogin: (email, password) => dispatch(login(email, password))
});

export const WrappedLoginModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(withRouter(LoginModal));
