const TYPE = type => 'ekitap/Config/' + type;

// action types
const SET_LOCALE = TYPE('SET_LOCALE');

// initial state
const initialState = {
  locale: 'ru'
};

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, locale: action.payload };
    default:
      return state;
  }
}

// action creators
export const setLocale = locale => dispatch => {
  // save locale code to local storage
  localStorage.setItem('ekitap-locale', locale);

  dispatch({
    type: SET_LOCALE,
    payload: locale
  });
};
