// modules
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';
// components
// redux
import { register } from 'store/User';

const RegisterModal = ({ done, error, kundelikId = '', makeRegister, onClose, toggleLoginModalShown, t }) => {
  const [hasAggreed, setAggreed] = useState(false);
  const [isPasswordVisible, togglePasswordVisibility] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = event => {
    event.preventDefault();

    makeRegister(firstName, lastName, email, password, kundelikId).then(() => {
      setTimeout(() => {
        toggleLoginModalShown(true);
        onClose();
      }, 3000);
    });
  };

  const isRegisterDisabled = !hasAggreed;

  return (
    <Modal open closeOnDimmerClick={false} onClose={onClose} size="small">
      <div className="landing-modal" >
        <div className="landing-modal-cross" onClick={onClose}>
          <img src={cross} alt="Cross icon" />
        </div>

        <div className="landing-modal-header">
          <span>{t('landing.registermodal.header')}</span>
        </div>
        
        <form className="landing-modal-form" onSubmit={handleRegister}>
          {done && <div className="landing-modal-form-message success">{t('landing.registermodal.msg.success')}</div>}
          {error && <div className="landing-modal-form-message error">{t('landing.registermodal.msg.error')}</div>}

          <input
            className="landing-modal-form-input"
            name="lastName"
            value={lastName}
            required
            placeholder={t('lastName') + '*'}
            onChange={event => setLastName(event.target.value)}
          />
          <input
            className="landing-modal-form-input"
            name="firstName"
            value={firstName}
            required
            placeholder={t('firstName') + '*'}
            onChange={event => setFirstName(event.target.value)}
          />
          <input
            className="landing-modal-form-input"
            name="email"
            value={email}
            required
            placeholder="Email*"
            onChange={event => setEmail(event.target.value)}
          />
          <input
            className="landing-modal-form-input"
            name="password"
            value={password}
            required
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={t('password') + '*'}
            onChange={event => setPassword(event.target.value)}
          />
          {kundelikId && (
            <input
              className="landing-modal-form-input"
              disabled
              type="text"
              value={`Kundelik ID: ${kundelikId}`}
            />  
          )}

          <div className="landing-modal-form-row">
            <div className="landing-modal-form-note">{t('landing.registermodal.note')}</div>

            <div className="landing-modal-form-checkbox">
              <label>
                <input
                  name="isPasswordVisible"
                  value={isPasswordVisible}
                  type="checkbox"
                  onChange={event => togglePasswordVisibility(event.target.checked)}
                />
                {t('landing.registermodal.showpassword')}
              </label>
            </div>
          </div>
          
          <div className="landing-modal-form-checkbox">
            <label>
              <input
                name="isAgreed"
                value={hasAggreed}
                type="checkbox"
                onChange={event => setAggreed(event.target.checked)}
              />
              {t('landing.registermodal.agreement')}
            </label>
          </div>

          <div className="landing-modal-form-controls">
            <input disabled={isRegisterDisabled} type="submit" value={t('register')} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  done: state.user.register.done,
  error: state.user.register.fail
});

const mapDispatchToProps = dispatch => ({
  makeRegister: (firstName, lastName, email, phone, password, kundelikId) => dispatch(register(firstName, lastName, email, phone, password, kundelikId))
});

export const WrappedRegisterModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(RegisterModal);
