// modules
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// slice reducers
import Config from './Config';
import UI from './UI';
import User from './User';
// middleware
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
// action creators
import { setLocale } from './Config';
import { set } from './User';

// merge slice reducers
const reducer = combineReducers({
  config: Config,
  ui: UI,
  user: User
});
// enable Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// initialize middlewares and redux store
const middleware = applyMiddleware(thunk, promise());
const store = createStore(reducer, composeEnhancers(middleware));

// restore locale string from local storage
const localeString = localStorage.getItem('ekitap-locale');
if (localeString) {
  // set locale
  store.dispatch(setLocale(localeString));
}

// restore user string from local storage
const userString = localStorage.getItem('ekitap-user');
if (userString) {
  // parse JSON-encoded string
  const user = JSON.parse(userString);
  // set user
  store.dispatch(set(user));
}

export default store;
