// modules
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import axios from "axios";
// components
import { Loader, Modal } from "semantic-ui-react";
import Slider from "react-rangeslider";
// styles
import "react-rangeslider/lib/index.css";
// assets
import cross from "assets/images/cross.svg";

const StyledModalHeader = styled.h2`
  margin: 0 0 16px 0;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 15px;
`;

const StyledModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const StyledMonthsAndPriceRow = styled(StyledModalRow)`
  & > div:first-child {
    color: #6e6e6e;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:last-child {
    font-size: 20px;
    font-weight: 800;
  }
`;

const DiscountPercentage = styled.div`
  padding: 8px 12px;
  border-radius: 16px;
  background-color: #f6d3d3;
  color: #e06666;
  font-weight: 600;
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  & > .rangeslider__fill {
    width: 100% !important;
    background-color: #e9e9e9;
    box-shadow: none;
  }

  & > .rangeslider__handle {
    background-color: #4682e0;
    box-shadow: none;
  }

  & > .rangeslider__handle:after {
    content: none;
  }
`;

const StyledPredefinedPeriodOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 96px;
  height: 96px;
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;

  & > div:first-child {
    font-size: 20px;
    font-weight: 700;
  }

  &:hover {
    background-color: #0f81d7;
    color: white;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 48px;
  width: 100%;

  & > button {
    padding: 12px;
    width: 100%;
    background-color: #009b72 !important;
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    user-select: none;
  }

  & > div {
    text-align: center;
  }
`;

const StyledPaymentInProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px 0;

  & > .ui.loader {
    align-self: center;
    margin-bottom: 40px;

    &::after {
      border-color: #888888 transparent transparent !important;
    }
  }
`;

const StyledPaymentInProgressLink = styled.div`
  width: fit-content;
  padding-bottom: 1px;
  border-bottom: 1px solid black;
  font-size: 16px;
  cursor: pointer;
`;

const languageDict = {
  ru: "ru",
  kk: "kz",
};

const priceDict = {
  1: 1200,
  2: 2400,
  3: 3300,
  4: 4400,
  5: 5500,
  6: 6000,
  7: 7000,
  8: 8000,
  9: 9000,
  10: 9500,
  11: 10200,
  12: 11000,
};

const calculateDiscount = (price, months) => {
  const monthPrice = priceDict[1];
  const withoutDiscount = monthPrice * months;
  const discount = Math.round(
    ((withoutDiscount - price) / withoutDiscount) * 100
  );

  return discount;
};

const PaymentModal = ({ t }) => {
  const [isPaymentInProgress, setPaymentInProgress] = useState(false);

  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [totalPrice, setTotalPrice] = useState(priceDict[numberOfMonths]);

  const history = useHistory();
  const interfaceLanguage = useSelector((state) => state.config.locale);

  const discount = calculateDiscount(totalPrice, numberOfMonths);

  const onCloseClick = () => {
    history.push("/dashboard/courses");
  };

  const onPaymentClick = () => {
    axios
      .post("/api/payment/start", {
        paymentAmount: totalPrice,
        paymentPeriod: numberOfMonths,
        language: languageDict[interfaceLanguage],
      })
      .then((response) => {
        setPaymentInProgress(true);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Modal size="tiny" open closeOnDimmerClick={false}>
      <div className="landing-modal">
        <div className="landing-modal-cross" onClick={onCloseClick}>
          <img src={cross} alt="Cross icon" />
        </div>

        <StyledModalHeader>
          {isPaymentInProgress
            ? t("dashboard.payment.inprogress")
            : t("dashboard.payment.title")}
        </StyledModalHeader>

        {isPaymentInProgress ? (
          <StyledPaymentInProgressContainer>
            <Loader active inline size="massive" />
            <StyledPaymentInProgressLink
              onClick={() => window.location.reload()}
            >
              {t("dashboard.payment.refresh")}
            </StyledPaymentInProgressLink>
            <StyledPaymentInProgressLink
              onClick={() => setPaymentInProgress(false)}
            >
              {t("dashboard.payment.retry")}
            </StyledPaymentInProgressLink>
          </StyledPaymentInProgressContainer>
        ) : (
          <StyledModalContent>
            <StyledModalRow>
              <div style={{ color: "#6e6e6e" }}>
                {t("dashboard.payment.period")}
              </div>
              <DiscountPercentage>{`${t(
                "dashboard.payment.discount"
              )} ${discount}%`}</DiscountPercentage>
            </StyledModalRow>

            <StyledMonthsAndPriceRow>
              <div>{`${numberOfMonths} ${t("month")}`}</div>
              <div>{`${Math.round(totalPrice / numberOfMonths)} ₸/${t(
                "month"
              )}`}</div>
            </StyledMonthsAndPriceRow>

            <StyledModalRow>
              <StyledSlider
                min={1}
                max={12}
                step={1}
                value={numberOfMonths}
                onChange={(value) => {
                  setNumberOfMonths(value);
                  setTotalPrice(priceDict[value]);
                }}
              />
            </StyledModalRow>

            <StyledModalRow>
              <StyledPredefinedPeriodOption
                onClick={() => {
                  setNumberOfMonths(3);
                  setTotalPrice(priceDict[3]);
                }}
              >
                <div>3</div>
                <div>{t("month")}</div>
              </StyledPredefinedPeriodOption>
              <StyledPredefinedPeriodOption
                onClick={() => {
                  setNumberOfMonths(6);
                  setTotalPrice(priceDict[6]);
                }}
              >
                <div>6</div>
                <div>{t("month")}</div>
              </StyledPredefinedPeriodOption>
              <StyledPredefinedPeriodOption
                onClick={() => {
                  setNumberOfMonths(9);
                  setTotalPrice(priceDict[9]);
                }}
              >
                <div>9</div>
                <div>{t("month")}</div>
              </StyledPredefinedPeriodOption>
              <StyledPredefinedPeriodOption
                onClick={() => {
                  setNumberOfMonths(12);
                  setTotalPrice(priceDict[12]);
                }}
              >
                <div>12</div>
                <div>{t("month")}</div>
              </StyledPredefinedPeriodOption>
            </StyledModalRow>

            <StyledModalRow>
              <StyledButtonContainer>
                <button onClick={onPaymentClick}>
                  {`${t("dashboard.payment.total")}: ${totalPrice} ₸`}
                </button>
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send/?phone=77475002292&text=Хочу+купить+доступ+к+платформе&type=phone_number&app_absent=0"
                  >
                    {t("dashboard.payment.consultation")}
                  </a>
                </div>
              </StyledButtonContainer>
            </StyledModalRow>
          </StyledModalContent>
        )}
      </div>
    </Modal>
  );
};

export const WrappedPaymentModal = withTranslation()(PaymentModal);
