// modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import ReactHtmlParser from 'react-html-parser';
// assets
import { bold, header, property } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
import { blockTypes } from 'assets/strings';
// styles
import {
  Accordion,
  Button,
  Checkbox,
  Container,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  List,
  Loader,
  Message
} from 'semantic-ui-react';
// components
import Block from '../Block';
import ExtraMaterial from '../ExtraMaterial';
// redux
import { logout } from 'store/User';

const loader = (
  <Loader active size="large">
    Загрузка урока
  </Loader>
);

const redirect = (context, path) => {
  context.props.history.push(path);
};

class Lesson extends Component {
  state = {
    adding: false,
    deleting: false,
    editing: false,
    loading: false,
    activeIndex: -1,
    position: 0,
    practicePosition: 0,
    lessonsOptions: [],
    newKeyword: ''
  };

  componentDidMount() {
    const { courseId, lessonId } = this.props.match.params;

    this.setState(
      {
        courseId: courseId,
        lessonId: lessonId
      },
      () => {
        // get lesson
        this.fetchLesson(lessonId);
      }
    );
  }

  fetchCourse = courseId => {
    this.setState({ loading: true });
    axios.get(adminRoutes.courses.getCourse(courseId)).then(
      response => {
        let lessons = response.data.message.lessons.filter(lesson => lesson._id !== this.state.lessonId);
        let lessonsOptions = lessons.map((option, index) => ({
          text: option.name,
          value: index
        }));
        lessonsOptions.splice(0, 0, {
          text: 'Нет',
          value: -1
        });

        this.setState({
          loading: false,
          lessons: lessons,
          lessonsOptions: lessonsOptions
        });
      },
      error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      }
    );
  };

  fetchLesson = lessonId => {
    this.setState({ loading: true });
    axios.get(adminRoutes.lessons.getLesson(lessonId)).then(
      response => {
        const lesson = omit(response.data.message, ['blocks']);
        const blocks = pick(response.data.message, ['blocks']).blocks;

        // build block position options
        this.positions = [{ text: 'В начало', value: 0 }];
        if (blocks.length > 0) {
          this.positions.push({ text: 'В конец', value: blocks.length });
        }
        for (let i = 0; i < blocks.length - 1; i++) {
          this.positions.push({
            text: `После ${i + 1} блока`,
            value: i + 1
          });
        }

        // set state
        this.setState({
          loading: false,
          lesson: lesson,
          blocks: blocks,
          position: blocks.length
        });

        // fetch course lessons
        this.fetchCourse(this.state.courseId);
      },
      error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      }
    );
  };

  editLesson = () => {
    const r = window.confirm('Редактировать описание этого урока?');
    if (r) {
      this.setState({ editing: true });
      const lesson = pick(this.state.lesson, [
        'name',
        'displayName',
        'type',
        'hidden',
        'section',
        'nextLesson',
        'prevLesson',
        'keywords'
      ]);
      axios.post(adminRoutes.lessons.editLesson(this.state.lesson._id), { lesson: lesson }).then(
        () => {
          this.setState({ editing: false });
          this.fetchLesson(this.state.lesson._id);
        },
        () => {
          this.setState({ editing: false });
          alert('Ошибка редактирования урока');
        }
      );
    }
  };

  deleteLesson = () => {
    const r = window.confirm('Удалить этот урок?');
    if (r) {
      this.setState({ deleting: true });
      axios.delete(adminRoutes.lessons.deleteLesson(this.state.lesson._id)).then(
        () => {
          this.setState({ deleting: false });
          this.props.history.push('/admin/lessons');
        },
        () => {
          this.setState({ deleting: false });
          alert('Ошибка удаления урока');
        }
      );
    }
  };

  addBlock = block => {
    const r = window.confirm('Добавить новый блок?');

    if (r) {
      this.setState({ adding: true });
      const payload = {
        block: block,
        position: this.state.position
      };
      // axios call
      axios.post(adminRoutes.lessons.addBlock(this.state.lesson._id), payload).then(
        () => {
          this.fetchLesson(this.state.lesson._id);
          this.setState({ adding: false });
        },
        () => {
          this.setState({ adding: false });
        }
      );
    }
  };

  addKeyword = () => {
    if (
      this.state.lesson &&
      this.state.lesson.keywords &&
      this.state.lesson.keywords.length !== 0 &&
      this.state.lesson.keywords.includes(this.state.newKeyword)
    ) {
      return;
    }

    const keywords =
      this.state.lesson && this.state.lesson.keywords ? [...this.state.lesson.keywords, this.state.newKeyword] : [];

    this.setState(prevState => ({
      lesson: {
        ...prevState.lesson,
        keywords: keywords
      },
      newKeyword: ''
    }));
  };

  deleteKeyword = value => {
    const keywords = this.state.lesson && this.state.lesson.keywords ? this.state.lesson.keywords.filter(x => x !== value) : [];

    this.setState(prevState => ({
      lesson: {
        ...prevState.lesson,
        keywords: keywords
      }
    }));
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
  };

  handleChangeLesson = (e, data) => {
    let result = data.value;
    if (data.name === 'hidden') {
      result = data.checked;
    }
    if (data.name === 'nextLesson' || data.name === 'prevLesson') {
      if (data.value === -1) {
        result = {
          _id: '',
          id: null,
          name: ''
        };
      } else {
        let lesson = this.state.lessons[data.value];
        result = {
          _id: lesson._id,
          id: lesson.id,
          name: lesson.name
        };
      }
    }
    this.setState(prevState => ({
      lesson: {
        ...prevState.lesson,
        [data.name]: result
      }
    }));
  };

  handleNewBlock = block => {
    this.setState(
      {
        block: block
      },
      () => {
        this.addBlock(block);
      }
    );
  };

  navigateToLesson = lessonId => {
    if (!this.state.courseId) return;
    window.location = `/admin/courses/${this.state.courseId}/lessons/${lessonId}`;
  };

  render() {
    let keywords = [];
    let blocks = [];
    let lesson;
    let name;

    if (this.state.lesson) {
      name = {
        section: this.state.lesson.section,
        name: this.state.lesson.name
      };

      // lesson keywords
      if (this.state.lesson.keywords && this.state.lesson.keywords.length !== 0) {
        for (let i = 0; i < this.state.lesson.keywords.length; i++) {
          const keyword = this.state.lesson.keywords[i];

          keywords.push(
            <List.Item key={`admin-lesson-keywords-${i}`}>
              <List.Content style={{ display: 'flex', flexDirection: 'row' }}>
                <span>{keyword}</span>
                <Icon
                  name="remove"
                  onClick={() => this.deleteKeyword(keyword)}
                  style={{ cursor: 'pointer', marginLeft: '8px' }}
                />
              </List.Content>
            </List.Item>
          );
        }
      }

      // lesson blocks
      if (this.state.blocks && this.state.blocks.length !== 0) {
        let questionNumber = 0;
        for (let i = 0; i < this.state.blocks.length; i++) {
          const block = this.state.blocks[i];
          if (block.blockType === 'question' || block.blockType === 'indefiniteQuestion') {
            questionNumber++;
          }

          blocks.push(
            <Fragment key={`admin-lesson-block-${i}`}>
              <Accordion.Title active={this.state.activeIndex === i} index={i} onClick={this.handleAccordionClick}>
                <Icon name='dropdown' />
                {`Блок ${i + 1} (${blockTypes[block.blockType]})`}
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex === i}>
                {this.state.activeIndex === i && (
                  <Block
                    block={block}
                    index={i}
                    questionNumber={questionNumber}
                    courseId={this.state.courseId}
                    lessonId={this.state.lesson._id}
                    section={this.state.lesson.section}
                    fetchLesson={this.fetchLesson}
                  />
                )}
              </Accordion.Content>
            </Fragment>
          );
        }
      }

      lesson = (
        <div style={{ fontSize: '15px' }}>
          <Button basic color="blue" onClick={() => redirect(this, `/admin/courses/${this.state.courseId}`)}>
            Вернуться к курсу
          </Button>

          <div style={{ marginTop: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              disabled={!this.state.lesson.prevLesson._id}
              color="teal"
              icon
              labelPosition="left"
              onClick={() => this.navigateToLesson(this.state.lesson.prevLesson._id)}
            >
              <Icon name="arrow left" />
              Предыдущий урок
            </Button>
            <Button
              disabled={!this.state.lesson.nextLesson._id}
              color="teal"
              icon
              labelPosition="right"
              onClick={() => this.navigateToLesson(this.state.lesson.nextLesson._id)}
            >
              Следующий урок
              <Icon name="arrow right" />
            </Button>
          </div>

          <Header size="large" textAlign="center">
            {ReactHtmlParser(name.name)}
          </Header>

          {bold('Название урока')}
          <Input
            fluid
            name="name"
            placeholder="Название урока"
            value={this.state.lesson.name}
            onChange={this.handleChangeLesson}
            style={{ marginBottom: '1em' }}
          />

          {bold('Краткое название урока')}
          <Input
            fluid
            name="displayName"
            placeholder="Краткое название урока"
            value={this.state.lesson.displayName}
            onChange={this.handleChangeLesson}
            style={{ marginBottom: '1em' }}
          />

          {/* {bold("Секция")}
          <Input
              fluid
              name="section"
              placeholder="Секция"
              value={this.state.lesson.section}
              onChange={this.handleChangeLesson}
              style={{ marginBottom: "1em" }}
          /> */}

          {bold('Ключевые слова')}
          <div style={{ marginBottom: '2em' }}>
            <List divided ordered style={{ marginBottom: '1em' }}>
              {keywords}
            </List>

            <Form onSubmit={this.addKeyword}>
              <Form.Input
                fluid
                name="newKeyword"
                placeholder="Введите новое ключевое слово"
                value={this.state.newKeyword}
                onChange={this.handleChange}
                style={{ marginBottom: '1em' }}
              />
              <Form.Button>Добавить</Form.Button>
            </Form>
          </div>

          {bold('Скрыть урок?')}
          <Checkbox
            checked={this.state.lesson.hidden || false}
            name="hidden"
            toggle
            onChange={this.handleChangeLesson}
            style={{ marginBottom: '1em' }}
          />

          {property('Количество блоков', this.state.lesson.length)}
          {property('Количество вопросов', this.state.lesson.questions)}

          {bold('Следующий урок')}
          {this.state.lessons && this.state.lessons.length !== 0 ? (
            <Dropdown
              fluid
              selection
              name="nextLesson"
              value={
                this.state.lesson.nextLesson._id
                  ? this.state.lessons.findIndex(x => x._id === this.state.lesson.nextLesson._id)
                  : -1
              }
              options={this.state.lessonsOptions}
              onChange={this.handleChangeLesson}
              style={{ marginBottom: '1em' }}
            />
          ) : null}

          {bold('Предыдущий урок')}
          {this.state.lessons && this.state.lessons.length !== 0 ? (
            <Dropdown
              fluid
              selection
              name="prevLesson"
              value={
                this.state.lesson.prevLesson._id
                  ? this.state.lessons.findIndex(x => x._id === this.state.lesson.prevLesson._id)
                  : -1
              }
              options={this.state.lessonsOptions}
              onChange={this.handleChangeLesson}
              style={{ marginBottom: '1em' }}
            />
          ) : null}

          <Button
            loading={this.state.editing}
            onClick={this.editLesson}
            style={{
              backgroundColor: '#32936f',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '8px'
            }}
          >
            Редактировать урок
          </Button>

          <Button
            loading={this.state.deleting}
            onClick={this.deleteLesson}
            style={{
              backgroundColor: '#cc515d',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '2em'
            }}
          >
            Удалить урок
          </Button>

          {header('Дополнительные материалы', '20px', 400)}
          <ExtraMaterial
            extraMaterial={this.state.lesson.extraMaterial}
            id={this.state.lesson._id}
            fetchLesson={this.fetchLesson}
          />

          {header('Блоки урока', '20px', 400)}
          <Accordion fluid styled style={{ marginBottom: '2em', padding: '1em' }}>
            {blocks}
          </Accordion>

          {header('Добавить новый блок', '20px', 400)}
          <Message
            info
            icon="hand pointer outline"
            header="Новый блок"
            content="Выбери тип нового блока, затем появятся нужные поля"
          />
          {bold('Куда добавить блок?')}
          <Dropdown
            fluid
            selection
            name="position"
            value={this.state.position}
            options={this.positions}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          <Block lessonId={this.state.lesson._id} new handleNewBlock={this.handleNewBlock} />
        </div>
      );
    }

    return <Container>{this.state.loading ? loader : lesson}</Container>;
  }
}

export default connect(store => ({}), {
  logout
})(Lesson);
