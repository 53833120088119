// modules
import React, { Component } from "react";
import axios from "axios";
// assets
import { formatSeconds } from "assets/formatUtils";
import { practicesRoutes } from "assets/routes";
// styles
import { Loader, Table } from "semantic-ui-react";
import "./index.css";
// components
// redux

class FooterStats extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        this.fetchQuestionBlocks(this.props.id);
    }

    fetchQuestionBlocks = async (id) => {
        this.setState({ id: id, loading: true });
        await axios
            .get(practicesRoutes.getPractice(id))
            .then((response) => {
                let questions = response.data.message.practice.questions || [];
                let userQuestions = response.data.message.userPractice.answers || [];

                for (let i = 0; i < userQuestions.length; i++) {
                    let found = questions.find((x) => x._id === userQuestions[i]._id);
                    if (found) {
                        let foundQuestion = JSON.parse(found.question);
                        if (foundQuestion.indefinite) {
                            userQuestions[i].correct = undefined;
                        }
                        userQuestions[i].testQuestion = !!foundQuestion.testQuestion;
                    }
                }

                // set lesson
                this.setState({ loading: false, userQuestions: userQuestions });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    scrollToRef = (id) => {
        let offset = document.getElementById(id).offsetTop - 40;
        window.scrollTo({
            top: offset,
            behavior: "smooth",
        });
    };

    render() {
        let smallTable = this.props.innerWidth < 800;
        let totalTime = 0;
        let tableRows = [];
        // sum up total time and get individual questions
        if (this.state.userQuestions && this.state.userQuestions.length !== 0) {
            for (let i = 0; i < this.state.userQuestions.length; i++) {
                let question = this.state.userQuestions[i];

                if (question.time) {
                    totalTime += question.time;
                }
                let questionResult;
                if (question.correct === undefined) {
                    questionResult = (
                        <b className="dashboard-practice-footer-questions-indefinite">Без верного ответа</b>
                    );
                } else if (question.correct === true) {
                    questionResult = <b className="dashboard-practice-footer-questions-correct">Верно</b>;
                } else if (question.correct === false) {
                    questionResult = <b className="dashboard-practice-footer-questions-wrong">Неверно</b>;
                }
                tableRows.push(
                    <Table.Row key={`dashboard-practice-footer-question-${i}`}>
                        <Table.Cell>
                            <b>{i + 1}</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b
                                onClick={() => this.scrollToRef(`practice-${this.props.id}-question-${question._id}`)}
                                style={{ cursor: "pointer", color: "#3c80c0" }}
                            >
                                <i>Вопрос</i>
                            </b>
                        </Table.Cell>
                        <Table.Cell>{questionResult}</Table.Cell>
                        <Table.Cell>{formatSeconds(question.time)}</Table.Cell>
                    </Table.Row>
                );
            }
        }

        return (
            <div className="dashboard-practice-footer-wrapper">
                <div className="dashboard-practice-footer-header">Результаты</div>
                <div className="dashboard-practice-footer-stats">
                    <p className="dashboard-practice-footer-stats-right-text">Правильные ответы</p>
                    <p className="dashboard-practice-footer-stats-total-text">Кол-во вопросов</p>
                    <p className="dashboard-practice-footer-stats-time-text">Общее время</p>
                    <p className="dashboard-practice-footer-stats-right-number">{this.props.correct}</p>
                    <p className="dashboard-practice-footer-stats-total-number">{this.props.questions}</p>
                    <p className="dashboard-practice-footer-stats-time-number">{totalTime} секунд</p>
                </div>
                {this.state.loading ? (
                    <Loader active inline="centered" size="large" style={{ marginBottom: "24px" }} />
                ) : smallTable ? (
                    <div className="dashboard-lesson-footer-questions">
                        <Table basic="very" size="small">
                            <Table.Body>{tableRows}</Table.Body>
                        </Table>
                    </div>
                ) : (
                    // <div className="dashboard-practice-footer-questions">
                    <Table basic="very" size="small">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Вопрос:</Table.Cell>
                                <Table.Cell>Ссылка:</Table.Cell>
                                <Table.Cell>Результат:</Table.Cell>
                                <Table.Cell>Время:</Table.Cell>
                            </Table.Row>
                            {tableRows}
                        </Table.Body>
                    </Table>
                    // </div>
                )}
            </div>
        );
    }
}

export default FooterStats;
