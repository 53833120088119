import React from 'react';
import './Conditions.css';
import {useColor} from '../Utils/ColorContext';
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";

function Conditions({t}) {
    const {color} = useColor();

    const titleStyle = {
        color: color === '#3C3C56' ? 'white' : '#7B6CEA',
    }

    return (
        <div className="conditions">
            <div className='instr'>
                <h1 style={titleStyle}>{t("landing.header.terms")}</h1>
            </div>

            {ReactHtmlParser(t('landing.terms.content'))}
        </div>
    );
}
export default withTranslation()(Conditions);
