// modules
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import media from "styled-media-query";
import { Button, Loader, Popup } from "semantic-ui-react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import "react-quill/dist/quill.snow.css";
import "./index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux
// utils
import { transformTextToSpeechNodes } from "../../../../../components/TextToSpeech";

const StyledTextBlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`;

const StyledPersonContainer = styled.div`
    ${media.lessThan("400px")`
        margin-right: 8px;
    `}

    ${media.between("400px", "600px")`
        margin-right: 12px;
    `}

    ${media.between("600px", "800px")`
        margin-right: 16px;
    `}

    ${media.greaterThan("800px")`
        margin-right: 20px;
    `}
`;

const StyledPersonImage = styled.img`
    border-radius: 50%;

    ${media.lessThan("400px")`
        width: 32px;
        height: 32px;
    `}

    ${media.between("400px", "600px")`
        width: 40px;
        height: 40px;
    `}

    ${media.between("600px", "800px")`
        width: 48px;
        height: 48px;
    `}

    ${media.greaterThan("800px")`
        width: 56px;
        height: 56px;
    `}
`;

const StyledVoiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    margin-top: 16px;
    padding: 8px;
    width: 100%;
    min-height: 160px;
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
`;

const StyledVoiceRecorderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
`;

const StyledVoiceAudioContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
`;


const TextBlock = ({
    block: { _id: blockId, audio, hasVoiceRecorder, highlighted, person },
    courseId,
    lessonId,
    t,
    text,
    userId,
    preview = false,
}) => {
    const audioRef = useRef(null);

    const [isUploadingVoice, setIsUploadingVoice] = useState(false);
    const [voiceUrl, setVoiceUrl] = useState("");
    const [recordState, setRecordState] = useState(RecordState.NONE);

    const checkMicPermissions = async () => {
        if (navigator && navigator.mediaDevices) {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        }
    }

    const handleRecordingStart = async () => {
        try {
            await checkMicPermissions()
            setRecordState(RecordState.START);
        } catch (error) {
            alert('Необходимы разрешения для использования микрофона в браузере');
            console.error(error);
        }
    };

    const handleRecordingStop = () => {
        try {
            setRecordState(RecordState.STOP);
        } catch (error) {
            console.error(error);
        }
    };

    const handleRecordingStopped = async (data) => {
        if (preview) {
            return;
        }

        // create file
        const file = new File([data.blob], 'voice.wav');
        // create a new form data
        const formData = new FormData();
        // append voice file to form data
        formData.append("file", file);
        // upload voice recording to server
        setIsUploadingVoice(true);
        const response = await axios
            .post(dashboardRoutes.saveVoiceRecording(courseId, lessonId, blockId), formData)
            .finally(() => {
                setIsUploadingVoice(false);
            });
        // set voice URL (use timestamp request parameter to update audio when it is overwritten)
        const url = `${response.data.message.link}?timestamp=${new Date().getTime()}`;
        setVoiceUrl(url);
        audioRef.current.load();
    };

    const handleAudioRemove = async () => {
        if (preview) {
            return;
        }

        axios
            .delete(dashboardRoutes.deleteVoiceRecording(courseId, lessonId, blockId))
            .then(() => {
                setVoiceUrl('');
            });
    };

    useEffect(() => {
        if (hasVoiceRecorder) {
            const baseUrl = "https://ekitap.kz";
            // Uncomment when testing on local server
            // const baseUrl = "http://localhost:8080";
            const url = `${baseUrl}/static/media/voice/user-${userId}-course-${courseId}-lesson-${lessonId}-block-${blockId}.wav`;
            const audio = new Audio(url);
    
            audio.onerror = () => {};
            audio.onloadeddata = () => {
                setVoiceUrl(url);
            };
        }
    }, [hasVoiceRecorder]);

    const replacedText = text.replace(/###([^#]*)###/ug, "<text-to-speech text='$1'></text-to-speech>");
    
    return (
        <StyledTextBlockContainer className={highlighted ? "highlighted" : ""}>
            <StyledPersonContainer>
                {person && <StyledPersonImage src={person } alt="" />}
            </StyledPersonContainer>
            <div className="ck-content" style={{ width: "100%",}}>
                <MathjaxWrapper text={ReactHtmlParser(replacedText, { transform: transformTextToSpeechNodes })} />
                {audio && <CustomAudioPlayer url={audio} />}
                {hasVoiceRecorder && (
                    <StyledVoiceContainer>
                        {isUploadingVoice ? (
                            <Loader active inline size="large" />
                        ) : (
                            <StyledVoiceRecorderContainer>
                                <Popup
                                    content={t('dashboard.course.lesson.block.recordVoice')}
                                    trigger={
                                        <Button
                                            circular
                                            icon={recordState === RecordState.START ? "stop" : "circle"}
                                            size="huge"
                                            onClick={recordState === RecordState.START ? handleRecordingStop : handleRecordingStart}
                                        />
                                    }
                                />
                                <AudioReactRecorder
                                    state={recordState}
                                    backgroundColor="rgb(250,250,250)"
                                    canvasHeight={160}
                                    onStop={handleRecordingStopped}
                                />
                            </StyledVoiceRecorderContainer>
                        )}
                        {voiceUrl && (
                            <StyledVoiceAudioContainer>
                                <audio ref={audioRef} controls>
                                    <source src={voiceUrl} type="audio/wav" />
                                    Your browser does not support the audio tag.
                                </audio>
                                <Button
                                    circular
                                    icon={"trash alternate"}
                                    size="huge"
                                    onClick={handleAudioRemove}
                                />
                            </StyledVoiceAudioContainer>
                        )}
                    </StyledVoiceContainer>
                )}
            </div>
        </StyledTextBlockContainer>
    );
};

export default withTranslation()(TextBlock);
