// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import shuffle from "lodash/shuffle"
import styled from "styled-components";
import { ReactSortable } from 'react-sortablejs';
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
  padding-bottom: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  background-color: rgba(240, 240, 240);
`;

const StyledQuestionContainer = styled.div`
  & > div {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
    padding: 8px;
    border-top: 2px solid rgba(120, 120, 120);
    border-bottom: 2px solid rgba(120, 120, 120);
    color: ${props => props.isCorrect === undefined ? 'rgba(120, 120, 120)' : props.isCorrect === true ? 'green' : 'red'};
    font-size: 40px;
    line-height: 100%;
    text-align: center;

    & > * {
      cursor: pointer;
    }
  }
`;

const StyledQuestionControls = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

class InteractiveQuestion9 extends Component {
  constructor(props) {
    super(props);

    const { block } = this.props;

    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const questions = (interactive.questions || []).map(({ question }) => question);
    const shuffledQuestions = questions.map((question) => shuffle(question.split(' ')).join(' '));
    const rawUserAnswers = block.answer ? JSON.parse(block.answer) : [...shuffledQuestions];
    const userAnswers = rawUserAnswers.map((answer) => answer.split(' ').map((word) => ({ word, id: word })));
    const currentQuestionIndex = 0;
    const currentQuestion = userAnswers[currentQuestionIndex] || [];
    const visitedQuestionsMap = Array(questions.length).fill('').reduce((acc, _, index) => ({ ...acc, [index]: index === 0 ? true: false }), {});

    this.state = {
      answered: !!block.answer,
      correct: block.correct,
      elapsed: block.time || 0,
      questions,
      shuffledQuestions,
      userAnswers,
      currentQuestionIndex,
      currentQuestion,
      visitedQuestionsMap,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { elapsed, userAnswers, questions } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    if (!Object.values(this.state.visitedQuestionsMap).every((value) => Boolean(value))) {
      alert('Пройдите все вопросы!');
      return;
    };

    // stop timer
    if (this.timer) clearInterval(this.timer);

    let isCorrect = true;
    // compare user answers with correct answers
    for (let i = 0; i < userAnswers.length; i++) {
      const answer = userAnswers[i].map(({ word }) => word).join(' ') ;
      const question = questions[i];
      if (answer !== question) {
        isCorrect = false;
        break;
      }
    }

    // convert user answers array into a string
    const transformedUserAnswers = userAnswers.map((item) => item.map(({ word }) => word).join(' '));
    const stringifiedAnswer = JSON.stringify(transformedUserAnswers);

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
      });
    });
  };

  onReanswerClick = () => {
    const rawUserAnswers = [...this.state.shuffledQuestions];
    const userAnswers = rawUserAnswers.map((answer) => answer.split(' ').map((word) => ({ word, id: word })));
    const currentQuestionIndex = 0;
    const currentQuestion = userAnswers[currentQuestionIndex];
    const visitedQuestionsMap = Array(this.state.questions.length).fill('').reduce((acc, _, index) => ({ ...acc, [index]: index === 0 ? true: false }), {});

    this.setState({
        answered: false,
        userAnswers,
        currentQuestionIndex,
        currentQuestion,
        visitedQuestionsMap,
        correct: undefined,
        elapsed: 0,
        hasReanswered: true
      }, () => {
        // start the timer again
        this.startTimer();
      });
  };

  handleMoveList = (newList) => {
    const updatedUserAnswers = [...this.state.userAnswers];
    updatedUserAnswers[this.state.currentQuestionIndex] = newList;
    this.setState({
      userAnswers: updatedUserAnswers,
      currentQuestion: updatedUserAnswers[this.state.currentQuestionIndex],
    });
  };

  handlePrevQuestion = () => {
    if (this.state.currentQuestionIndex === 0 || this.props.preview) {
      return;
    }

    this.setState(prevState => ({
      currentQuestionIndex: prevState.currentQuestionIndex - 1,
      currentQuestion: prevState.userAnswers[prevState.currentQuestionIndex - 1],
    }));
  };

  handleNextQuestion = () => {
    if (this.state.currentQuestionIndex === this.state.questions.length - 1 || this.props.preview) {
      return;
    }

    this.setState(prevState => ({
      currentQuestionIndex: prevState.currentQuestionIndex + 1,
      currentQuestion: prevState.userAnswers[prevState.currentQuestionIndex + 1],
      visitedQuestionsMap: {
        ...prevState.visitedQuestionsMap,
        [prevState.currentQuestionIndex + 1]: true,
      }
    }));
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    const currentQuestionStr = `${this.state.currentQuestionIndex + (this.state.questions.length && 1)}/${this.state.questions.length}`;
    const isCorrectAnswer = this.state.answered ? this.state.currentQuestion.map(({ word }) => word).join(' ') === this.state.questions[this.state.currentQuestionIndex] : undefined;

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>{ReactHtmlParser(this.props.block.text)}</div>
          </div>
          <StyledContainer>
            <StyledQuestionContainer isCorrect={isCorrectAnswer}>
              <ReactSortable
                list={this.state.currentQuestion}
                setList={this.handleMoveList}
                animation={200}
              >
                {this.state.currentQuestion.map(({ word }, index) => (
                  <span key={`shuffled-question-word-${index}`}>{word}</span>
                ))}
              </ReactSortable>
            </StyledQuestionContainer>
            <StyledQuestionControls>
              <Button
                disabled={this.state.currentQuestionIndex === 0}
                icon='arrow left'
                onClick={this.handlePrevQuestion}
              />
              <span>{currentQuestionStr}</span>
              <Button
                disabled={this.state.currentQuestionIndex === this.state.questions.length - 1}
                icon='arrow right'
                onClick={this.handleNextQuestion}
              />
            </StyledQuestionControls>
          </StyledContainer>
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion9));
