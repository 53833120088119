// modules
import React from "react";
import MathJax from "react-mathjax2";
// assets
// styles
// components
// redux

const MathjaxWrapper = (props) => (
    <MathJax.Context
        input="ascii"
        onError={(MathJax, error) => {
            console.warn(error);
            console.error("Encountered a MathJax error, re-attempting a typeset!");
        }}
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS-MML_HTMLorMML"
        options={{
            asciimath2jax: {
                useMathMLspacing: true,
                delimiters: [["$", "$"], ["$$", "$$"]],
                preview: "none",
            },
        }}
    >
        <MathJax.Text text={props.text} />
    </MathJax.Context>
);

export default MathjaxWrapper;
