// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
import { PAIR_FIRST_ELEMENT_CLASSNAME, PAIR_ELEMENT_CLASSNAME } from "assets/interactiveQuestions";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

const StyledInteractiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 16px;

  & > div {
    width: 33.3%;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    max-height: 120px;
    padding: .25rem;
    border-radius: 4px;
  }
  & > div > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
  }
`;

const StyledElementContainer = styled.div`
  visibility: ${props => props.isAnswered ? 'hidden' : 'visible'};
  background-color: ${props => props.isSelected ? '#0073e6' : 'rgb(250, 250, 250)'};
  border: 2px solid #eaeaea;
  color: ${props => props.isSelected ? '#ffffff' : '#000000'};
  cursor: pointer;
`;

const StyledPlaceholderContainer = styled.div`
  background-color: #ffffff;
  border: 2px dashed #eaeaea;
  cursor: pointer;

  &.active {
    border-color: #0073e6;
  }

  &.correct {
    background-color: #338e6b;
    border-style: solid;
    color: #fff;
  }

  &.incorrect {
    background-color: #ea4643;
    border-style: solid;
    color: #fff;
  }
`;

const Element = ({ isSelected, element, index, onClick }) => {
  return (
    <StyledElementContainer
      className={PAIR_ELEMENT_CLASSNAME}
      isAnswered={!element}
      isSelected={isSelected}
      onClick={() => onClick(index)}
    >
      {element && ReactHtmlParser(element)}
    </StyledElementContainer>
  )
};

const Placeholder = ({ blockId, element, index, onClick, props }) => {
  const { t } = props;
  return (
    <StyledPlaceholderContainer
      data-blockid={blockId}
      className={PAIR_FIRST_ELEMENT_CLASSNAME}
      isAnswered={!!element}
      onClick={() => onClick(element, index)}
    >
      {element ? ReactHtmlParser(element) : t("dashboard.interactive.pressHere")}
    </StyledPlaceholderContainer>
  )
};

const InteractiveContainer = React.memo(({ blockId, wasAnswered, selectedElementIndex, firstColumn, middleColumn, secondColumn, onElementClick, onPlaceholderClick, props }) => {
  return (
    <StyledInteractiveContainer>
      <StyledColumn>
        {firstColumn.map((item, index) => (
          <Element 
            isSelected={selectedElementIndex === index}
            element={item}
            index={index}
            onClick={onElementClick}
          />
        ))}
      </StyledColumn>
      <StyledColumn>
        {middleColumn.map((item, index) => (
          <Placeholder
            blockId={blockId}
            element={item}
            index={index}
            onClick={onPlaceholderClick}
            props={props}
          />
        ))}
      </StyledColumn>
      <StyledColumn>{secondColumn.map((item) => (
        <div>{ReactHtmlParser(item)}</div>
      ))}</StyledColumn>  
    </StyledInteractiveContainer>
  );
});

class InteractiveQuestion3 extends Component {
  constructor(props) {
    super(props);
    const { block } = props;

    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const pairs = interactive.pairs || [];
    const correctAnswersMap = pairs.reduce((accumulator, current) => ({
      ...accumulator,
      [current.second]: current.first,
    }), {});

    let firstColumn = [];
    let middleColumn = [];
    let secondColumn = [];

    if (block.answer) {
      const userAnswers = JSON.parse(block.answer);
      firstColumn = Array(userAnswers.length).fill('');
      middleColumn = userAnswers.map(({ first }) => first);
      secondColumn = userAnswers.map(({ second }) => second);
    } else {
      firstColumn = pairs.map((pair) => pair.first).sort(() => Math.random() - 0.5);
      middleColumn = Array(pairs.length).fill('');
      secondColumn = pairs.map((pair) => pair.second).sort(() => Math.random() - 0.5);
    }

    this.state = {
      pairs,
      correctAnswersMap,
      firstColumn,
      middleColumn,
      secondColumn,
      answered: !!block.answer,
      selectedElementIndex: -1,
      correct: block.correct,
      elapsed: block.time || 0,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    } else {
      // color user answers
      const htmlPairFirstElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      const pairFirstElements = [...htmlPairFirstElements];

      for (let i = 0; i < pairFirstElements.length; i++) {
        const element = pairFirstElements[i];
        const answer = element.textContent;
        const matchingElement = this.state.secondColumn[i];
        const correctAnswer = this.state.correctAnswersMap[matchingElement];
        const isElementCorrect = answer === correctAnswer;

        element.classList.add(isElementCorrect ? "correct" : "incorrect");
      }
    }

    // add reset click listener
    document.addEventListener('click', this.handleEmptyPlaceClick);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);

    // remove reset click listener
    document.removeEventListener('click', this.handleEmptyPlaceClick);
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { pairs, firstColumn, middleColumn, secondColumn, elapsed } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    if (middleColumn.some((item) => !item)) {
      alert('Соедините все пары');
      return;
    }

    // convert user answers array into a string
    const userAnswers = middleColumn.map((item, index) => ({
      first: item,
      second: secondColumn[index],
    }));
    const stringifiedAnswer = JSON.stringify(userAnswers);

    // stop timer
    if (this.timer) clearInterval(this.timer);

    let isCorrect = true;
    // handle coloring of user answers
    const htmlPairFirstElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    const pairFirstElements = [...htmlPairFirstElements];

    for (let i = 0; i < pairFirstElements.length; i++) {
      const element = pairFirstElements[i];
      const answer = element.innerHTML;
      const matchingElement = this.state.secondColumn[i];
      const correctAnswer = this.state.correctAnswersMap[matchingElement];

      const isElementCorrect = answer === correctAnswer;
      element.classList.add(isElementCorrect ? "correct" : "incorrect");
      if (!isElementCorrect) isCorrect = false;

      console.log(answer + " | " + correctAnswer)
    }

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    //   userAnswers,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
        userAnswers,
      });
    });
  };

  onReanswerClick = () => {
    // handle de-coloring of user answers (middle column elements)
    const htmlPairFirstElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    const pairFirstElements = [...htmlPairFirstElements];

    pairFirstElements.forEach((element) => {
      element.classList.remove("correct");
      element.classList.remove("incorrect");
    });

    this.setState(prevState => ({
      answered: false,
      correct: undefined,
      elapsed: 0,
      hasReanswered: true,
      firstColumn: prevState.pairs.map((pair) => pair.first).sort(() => Math.random() - 0.5),
      middleColumn: Array(prevState.pairs.length).fill(''),
    }), () => {
      // start the timer again
      this.startTimer();
    });
  }

  handleEmptyPlaceClick = (event) => {
    if (!event.target.classList.contains(PAIR_FIRST_ELEMENT_CLASSNAME) && !event.target.classList.contains(PAIR_ELEMENT_CLASSNAME) && this.state.selectedElementIndex !== -1) {
      const pairHtmlElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...pairHtmlElements].forEach((element) => {
        element.classList.remove('active');
      });

      this.setState({ selectedElementIndex: -1 });
    }
  };

  handleElementClick = (index) => {
    if (this.state.answered || this.state.selectedElementIndex !== -1) return;

    const pairHtmlElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...pairHtmlElements].forEach((element) => {
        element.classList.add('active');
      });

    this.setState({ selectedElementIndex: index });
  };

  handlePlaceholderClick = (value, index) => {
    if (this.state.answered || (!value && this.state.selectedElementIndex === -1)) return;

    const updatedFirstColumn = [...this.state.firstColumn];
    const updatedMiddleColumn = [...this.state.middleColumn];

    const newValue = updatedFirstColumn[this.state.selectedElementIndex];
    updatedFirstColumn[this.state.selectedElementIndex] = '';
    updatedMiddleColumn[index] = newValue;

    if (value) {
      const targetIndex = updatedFirstColumn.findIndex((item) => !item);
      if (targetIndex !== -1) {
        updatedFirstColumn[targetIndex] = value;
      }
    }

    const pairHtmlElements = document.querySelectorAll(`div.${PAIR_FIRST_ELEMENT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...pairHtmlElements].forEach((element) => {
        element.classList.remove('active');
      });

    this.setState({
      firstColumn: updatedFirstColumn,
      middleColumn: updatedMiddleColumn,
      selectedElementIndex: -1,
    });
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>
              <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
            </div>
          </div>
          <InteractiveContainer
            blockId={this.props.block._id}
            wasAnswered={this.state.answered}
            selectedElementIndex={this.state.selectedElementIndex}
            firstColumn={this.state.firstColumn}
            middleColumn={this.state.middleColumn}
            secondColumn={this.state.secondColumn}
            onElementClick={this.handleElementClick}
            onPlaceholderClick={this.handlePlaceholderClick}
            props={this.props}
          />
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion3));
