// modules
import React from "react";
// assets
// styles
import "./index.css";
// components
// redux

const HeaderStats = props => {
    let results;
    if (props.questions) {
        results = (
            <div className="dashboard-practice-header-stats-results">
                <p className="dashboard-practice-header-stats-results-right-text">Правильные ответы</p>
                <p className="dashboard-practice-header-stats-results-total-text">Кол-во вопросов</p>
                <p className="dashboard-practice-header-stats-results-right-number">{props.correct}</p>
                <p className="dashboard-practice-header-stats-results-total-number">{props.questions}</p>
            </div>
        );
    }
    return (
        <div className="dashboard-practice-header-stats">
            <p className="dashboard-practice-header-stats-header">Практика завершена</p>
            {results}
        </div>
    );
};

export default HeaderStats;
