// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { header, property } from '../../../../assets/formatUtils';
import { adminRoutes } from '../../../../assets/routes';
// styles
import { Button, Container, List, Loader } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from '../../../../store/User';

const button = (disabled, loading, backgroundColor, onClick, text) => (
  <Button
    disabled={disabled}
    loading={loading}
    style={{
      backgroundColor: backgroundColor,
      color: '#fff',
      borderRadius: '0px',
      marginBottom: '8px'
    }}
    onClick={onClick}
  >
    {text}
  </Button>
);

class Teacher extends Component {
  state = {
    loading: false,
    editing: false,
    deleting: false,
    teacher: {
      students: []
    }
  };

  componentDidMount() {
    const email = this.props.match.params.email;
    this.fetchTeacher(email);
  }

  fetchTeacher = email => {
    this.setState({ loading: true });
    axios.get(adminRoutes.teachers.getTeacher(email)).then(
      response => {
        this.setState({ loading: false, teacher: response.data.message });
      },
      error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      }
    );
  };

  handleChange = (e, data) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [data.name]: data.value
      }
    }));
  };

  handleEdit = () => {
    const r = window.confirm('Редактировать этого учителя?');
    if (r) {
      this.setState({ editing: true });
      axios.post(adminRoutes.users.editUser(this.state.teacher.email), {}).then(
        () => {
          this.setState({ editing: false });
          this.fetchTeacher(this.state.teacher.email);
        },
        error => {
          console.error('Error:', error);
          this.setState({ editing: false });
        }
      );
    }
  };

  handleDelete = () => {
    const r = window.confirm('Удалить этого учителя?');
    if (r) {
      this.setState({ deleting: true });
      axios.delete(adminRoutes.users.deleteUser(this.state.teacher.email)).then(
        () => {
          this.setState({ deleting: false });
          this.props.history.push('/admin/teachers');
        },
        error => {
          console.error('Error:', error);
          this.setState({ deleting: false });
        }
      );
    }
  };

  render() {
    const { lastName, firstName, email } = this.state.teacher;

    const name = `${lastName} ${firstName}`;
    let studentItems = [];

    studentItems = this.state.teacher.students.map((student, i) => (
      <List.Item key={`admin-teacher-students-${i}`}>
        <List.Icon name="user" />
        <List.Content>
          <List.Header
            style={{
              color: '#3697dd',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: '600',
              marginBottom: '8px'
            }}
          >{`${student.lastName} ${student.firstName}`}</List.Header>
          <List.Description>Логин: {student.email}</List.Description>
        </List.Content>
      </List.Item>
    ));

    return (
      <DocumentTitle title="Учитель">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container className="admin-teacher-container">
            {header(name, '24px', 600)}
            {property('Полное имя', name)}
            {property('Логин', email)}

            {header('Ученики', '20px', 600)}
            <List divided relaxed="very">
              {studentItems}
            </List>

            {button(false, this.state.editing, '#254441', this.handleEdit, 'Редактировать')}
            {button(false, this.state.deleting, '#cc515d', this.handleDelete, 'Удалить')}
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Teacher);
