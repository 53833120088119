// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Checkbox, Form, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledAnswerInput = styled(Form.Input)`
    input {
        border-color: ${props => props.wasanswered ? (props.iscorrect ? 'green' : 'red') : 'rgba(34, 36, 38, 0.15)'} !important;
    }
`;

const StyledAnswerCheckbox = styled(Checkbox)`
    input:checked~.box:before,
    input:checked~label:before {
        background-color: ${props => props.wasanswered ? (props.iscorrect ? 'green' : 'red') : '#fff'} !important;
    }

    input:checked~box:after,
    input:checked~label:after {
        color: ${props => props.wasanswered ? '#fff !important' : ''};
    }

    &.radio input:checked~box:after,
    &.radio input:checked~label:after {
        background-color: ${props => props.wasanswered ? '#fff !important' : ''};
    }
`;

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

class Question extends Component {
    state = {
        answer: this.props.block.answer ? JSON.parse(this.props.block.answer) : "",
        answered: !!this.props.block.answer,
        correct: this.props.block.correct,
        elapsed: this.props.block.time || 0,
        multiple: this.props.block.multiple ? [] : undefined,
        options: this.props.block.options ? JSON.parse(this.props.block.options) : [],
        showHint: false,
        testQuestion: this.props.block.testQuestion,
        hasReanswered: this.props.block.hasReanswered
    };

    componentDidMount() {
        if (this.props.preview) return;
        if (!this.state.answered) {
            // set answering to false (for parent Lesson component)
            this.props.handleAnswering(true);
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    startTimer = () => {
        this.start = new Date();
        this.timer = setInterval(this.tick, 1000);
    };

    handleQuestionVisibility = (isVisible) => {
        if (!isVisible) {
            if (this.timer) clearInterval(this.timer);
        }

        if (isVisible && !this.state.answered) {
            if (this.timer) clearInterval(this.timer);
            this.startTimer();
        }
    };

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000),
        });
    };

    handleChange = (e, data) => {
        if (data.name === "open" || data.name === "closed") {
            this.setState({
                answer: data.value,
            });
        } else if (data.name === "multiple") {
            if (data.checked) {
                const multiple = [...this.state.multiple];
                multiple.push(data.value);
                this.setState({ multiple: multiple });
            } else {
                const multiple = this.state.multiple.filter((x) => x !== data.value);
                this.setState({ multiple: multiple });
            }
        } else {
            this.setState({
                [data.name]: data.value,
            });
        }
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        // stop timer
        if (this.timer) clearInterval(this.timer);
        // pick necessary fields and correct answer
        let courseId = this.props.courseId;
        let lessonId = this.props.lessonId;
        let blockId = this.props.block._id;
        let correct = false;
        let { answer, elapsed, multiple } = this.state;
        let correctAnswer = JSON.parse(this.props.block.correctAnswer);
        // record answer
        if (this.props.block.openQuestion) {
            // lowercase answer
            answer = answer.toLowerCase();

            // commented this out as answers actually can contain whitespaces
            // statement below removes them
            // answer = answer.replace(/\s/g, "");

            if (isNaN(correctAnswer)) {
                // text answer
                if (answer === correctAnswer) {
                    correct = true;
                }
            } else {
                // number answer
                let corr = Number(correctAnswer);
                if (this.props.block.approximate) {
                    // approximate
                    let corrDown = corr - corr * 0.1;
                    let corrUp = corr + corr * 0.1;
                    answer = Number(answer);
                    if (answer >= corrDown && answer <= corrUp) {
                        correct = true;
                    }
                } else {
                    if (answer === correctAnswer) {
                        correct = true;
                    }
                }
            }
        } else {
            if (this.props.block.multiple) {
                let multipleCorrect = false;
                if (this.state.multiple.length === correctAnswer.length) {
                    multipleCorrect = true;
                    for (let i = 0; i < correctAnswer.length; i++) {
                        if (!this.state.multiple.includes(correctAnswer[i])) {
                            multipleCorrect = false;
                            break;
                        }
                    }
                }
                correct = multipleCorrect;
            } else {
                if (answer === correctAnswer) {
                    correct = true;
                }
            }
        }

        const recordedAnswer = this.props.block.multiple ? JSON.stringify(multiple) : JSON.stringify(answer);

        // set answering to false (for parent Lesson component)
        this.props.handleAnswering(false);
        this.props.handleAnswer(this.props.index, recordedAnswer, correct, elapsed);
        this.props.handleCorrect(correct);

        // answer payload
        const payload = {
            answer: recordedAnswer,
            correct: correct,
            time: elapsed,
        };
        // axios call
        let answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
        axios.post(answerRoute, payload).then(() => {
            this.setState({ answered: true, correct: correct });
            if (this.props.block.multiple) {
                this.setState({
                    answer: [...this.state.multiple],
                    multiple: [],
                });
            }
        });
    };

    onReanswerClick = () => {
        this.setState({
            answered: false,
            correct: undefined,
            elapsed: 0,
            hasReanswered: true
        }, () => {
            // start the timer again
            this.startTimer();
        });
    };

    getQuestionHeader = () => {
        const { section, questionNumber, locale, t } = this.props;

        let header = section
            ? `${t("dashboard.course.lesson.question.task")} ${section}.${questionNumber}`
            : `${t("dashboard.course.lesson.question.task")} ${questionNumber}`;

        if (locale === "kk") {
            header = `${questionNumber}-${t("dashboard.course.lesson.question.task")}`;
        }

        if (!questionNumber) header = t("dashboard.course.lesson.question.task");

        return header;
    };

    render() {
        const { preview, t } = this.props;

        let answerButton = (
            <Form.Button
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                type="submit"
            >
                {t("dashboard.course.lesson.question.answer")}
            </Form.Button>
        );
        let reanswerButton = (
            <Button
                primary
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                onClick={this.onReanswerClick}
            >
                {t("dashboard.course.lesson.question.reanswer")}
            </Button>
        )
        if (preview) {
            answerButton = null;
            reanswerButton = null;
        }
        if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
            reanswerButton = null;
        }

        let header = this.getQuestionHeader();
        let hint;
        let question;
        let explanation;
        // display hint
        if (this.props.block.hint) {
            hint = (
                <div className="dashboard-lesson-block-question-hint">
                    <Button
                        className="dashboard-lesson-block-question-hint-button"
                        onClick={this.handleHint}
                        size={
                            this.props.innerWidth > 700
                                ? "large"
                                : this.props.innerWidth > 600
                                ? "medium"
                                : this.props.innerWidth > 500
                                ? "tiny"
                                : "mini"
                        }
                    >
                        {this.state.showHint
                            ? t("dashboard.course.lesson.question.hideHint")
                            : t("dashboard.course.lesson.question.showHint")}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-lesson-block-question-hint-text">
                            <div className="ck-content" style={{ width: '100%',}}>
                                {ReactHtmlParser(this.props.block.hint)}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }
        // display explanation for answered question
        if (this.state.answered) { // && !this.state.testQuestion
            // extract captions for three scenarios
            const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
            // explanation text
            let explanationText = "";
            if (this.state.correct) {
                explanationText = correctText;
            } else {
                explanationText = this.state.hasReanswered ? wrongText : hintingText;
            }
            // replace name
            if (!!explanationText) {
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (this.props.block.time) {
                explanationText = explanationText.replace("$$time$$", this.props.block.time);
            }
            // replace rec time
            if (this.props.block.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
            }
            if (explanationText != "") {
            explanation = (
                    <div
                        className={
                            this.state.correct === true
                                ? "dashboard-lesson-block-question-explanation-correct"
                                : "dashboard-lesson-block-question-explanation-wrong"
                        }
                        id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
                    >
                        <div className="ck-content" style={{ width: '100%',}}>
                            {ReactHtmlParser(explanationText)}
                        </div>
                    </div>
                );
            }
        }

        // track reanswering states
        const wasAnswered = this.state.correct === true || this.state.correct === false;

        // question form
        if (this.props.block.openQuestion) {
            // open question form
            question = (
                <Form autoComplete="off" className="dashboard-lesson-block-question-form" onSubmit={this.handleAnswer}>
                    <div className="dashboard-lesson-block-question-form-instruction">
                        {t("dashboard.course.lesson.question.instruction")}
                    </div>
                    <StyledAnswerInput
                        className="dashboard-lesson-block-question-form-input"
                        fluid
                        name="open"
                        required
                        placeholder={
                            this.props.block.approximate
                                ? t("dashboard.course.lesson.question.placeholder")
                                : t("dashboard.course.lesson.question.placeholderApproximate")
                        }
                        readOnly={this.state.answered}
                        size="large"
                        value={this.state.answer}
                        onChange={this.handleChange}
                        iscorrect={this.state.correct}
                        wasanswered={wasAnswered}
                    />

                    {this.state.answered ? (
                        <div>{reanswerButton}</div>
                    ) : (
                        <div>{answerButton}</div>
                    )}

                    <StyledTimerContainer>
                        <Statistic
                            className="dashboard-lesson-block-question-form-time"
                            floated="right"
                            size="tiny"
                        >
                            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
                        </Statistic>
                    </StyledTimerContainer>
                </Form>
            );
        } else {
            // closed question form
            let options = this.props.block.options ? JSON.parse(this.props.block.options) : [];
            if (this.props.block.multiple) {
                // multiple choice (checkboxes)
                question = (
                    <Form
                        autoComplete="off"
                        className="dashboard-lesson-block-question-form"
                        onSubmit={this.handleAnswer}
                    >
                        <div className="dashboard-lesson-block-question-form-instruction">
                            {t("dashboard.course.lesson.question.instructionMultiple")}
                        </div>
                        {options.map((option, index) => {
                            const checked = this.state.answered ? this.state.answer.includes(option) : this.state.multiple.includes(option);

                            return (
                                <Form.Field
                                    className="dashboard-lesson-block-question-form-option"
                                    key={"dashboard-lesson-block-question-" + this.props.block._id + "-" + index}
                                >
                                    <StyledAnswerCheckbox
                                        className="dashboard-lesson-block-question-form-checkbox"
                                        checked={checked}
                                        iscorrect={this.state.correct}
                                        wasanswered={wasAnswered}
                                        name="multiple"
                                        readOnly={this.state.answered}
                                        value={option}
                                        onChange={this.handleChange}
                                    />
                                    <div
                                        className="ck-content"
                                        style={{ width: '100%', overflow: 'hidden' }}
                                    >
                                        {ReactHtmlParser(option)}
                                    </div>
                                </Form.Field>
                            );
                        })}

                        {this.state.answered ? (
                            <div>{reanswerButton}</div>
                        ) : (
                            <div>{answerButton}</div>
                        )}

                        <StyledTimerContainer>
                            <Statistic
                                className="dashboard-lesson-block-question-form-time"
                                floated="right"
                                size="tiny"
                            >
                                <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                                <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
                            </Statistic>
                        </StyledTimerContainer>
                    </Form>
                );
            } else {
                // one choice (radio buttons)
                question = (
                    <Form
                        autoComplete="off"
                        className="dashboard-lesson-block-question-form"
                        onSubmit={this.handleAnswer}
                    >
                        <div className="dashboard-lesson-block-question-form-instruction">
                            {t("dashboard.course.lesson.question.instructionMultipleOne")}
                        </div>
                        {options.map((option, index) => {
                            const checked = option === this.state.answer;

                            return (
                                <Form.Field
                                    className="dashboard-lesson-block-question-form-option"
                                    key={"dashboard-lesson-block-question-" + this.props.block._id + "-" + index}
                                >
                                    <StyledAnswerCheckbox
                                        className="dashboard-lesson-block-question-form-radio"
                                        checked={checked}
                                        iscorrect={this.state.correct}
                                        wasanswered={wasAnswered}
                                        name="closed"
                                        radio
                                        readOnly={this.state.answered}
                                        value={option}
                                        onChange={this.handleChange}
                                    />
                                    <div 
                                        className="ck-content" 
                                        style={{ width: '100%', overflow: 'hidden' }}
                                    >
                                        {ReactHtmlParser(option)}
                                    </div>
                                </Form.Field>
                            );
                        })}

                        {this.state.answered ? (
                            <div>{reanswerButton}</div>
                        ) : (
                            <div>{answerButton}</div>
                        )}

                        <StyledTimerContainer>
                            <Statistic
                                className="dashboard-lesson-block-question-form-time"
                                floated="right"
                                size="tiny"
                            >
                                <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                                <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
                            </Statistic>
                        </StyledTimerContainer>
                    </Form>
                );
            }
        }

        return (
            <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
                <div
                    className={`dashboard-lesson-block-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
                    id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
                >
                    {/* <div className="dashboard-lesson-block-question-header">{header}</div> */}
                    <div className="dashboard-lesson-block-question-text">
                        <div className="ck-content" style={{ width: '100%',}}>
                            <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
                        </div>
                    </div>
                    {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
                    {hint}
                    {question}
                    {explanation}
                </div>
            </VisibilitySensor>
        );
    }
}

const mapStateToProps = state => {
    return {
        locale: state.config.locale
    };
};

export default withTranslation()(connect(mapStateToProps, null)(Question));
