// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Card, Container, Header, Input, Image, Loader } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from 'store/User';
import { useState } from 'react';

const redirect = (context, path) => {
  context.props.history.push(path);
};

class Courses extends Component {
  state = {
    loading: false,
    input: ''
  };

  componentDidMount() {
    this.fetchCourses();
  }

  fetchCourses = () => {
    this.setState({ loading: true });
    axios.get(adminRoutes.courses.getCourses()).then(
      response => {
        this.setState({ loading: false, courses: response.data.message });
      },
      error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      }
    );
  };

  handleChange = (e, data) => {
    this.setState({
      input: e.target.value,
    })
    this.fetchCards()
  };
  
  fetchCards = () => {
    let courseCards = [],
        courses = this.state.courses;
        
        if (courses && courses.length !== 0) {
          courses.filter(course => this.state.input === '' || course.name.toLowerCase().includes(this.state.input.toLowerCase()))
                 .map((course, index) => {
                  courseCards.push(
                    <Card key={'admin-course-' + course._id} href={`/admin/courses/${course._id}`}>
                      <Image src={course.cover} alt="Course card" />
                      <Card.Content>
                        <Card.Header className="admin-courses-card-header">{course.name}</Card.Header>
                      </Card.Content>
                    </Card>
                  );
          });
        }
                    
    return courseCards
  }
                  

  render() {

    return (
      <DocumentTitle title="Учебники">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              Учебники
            </Header>

            <Button className="admin-courses-new-course" onClick={() => redirect(this, '/admin/courses/new')}>
              Создать новый учебник
            </Button>

            <div className="admin-courses-class-search">
              <Input fluid icon="search" placeholder="Поиск учебников по названию" name="searchString" value={this.state.input} onChange={this.handleChange}/>
            </div>

            <Card.Group>{this.fetchCards()}</Card.Group>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
