// modules
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// redux
import store from './store/Store';
// assets
import resources from './assets/resources.json';

i18n.use(initReactI18next).init({
  resources: resources,
  lng: store.getState().config.locale,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
